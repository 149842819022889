import { defineMessages } from 'react-intl';

export const CommonTranslations = defineMessages({
  continue: {
    id: 'CommonTranslations.continue',
    defaultMessage: 'Continuer',
  },
  reset: {
    id: 'CommonTranslations.reset',
    defaultMessage: 'Réinitialiser',
  },
  save: {
    id: 'CommonTranslations.save',
    defaultMessage: 'Sauvegarder',
  },
  confirm: {
    id: 'CommonTranslations.confirm',
    defaultMessage: 'Confirmer',
  },
  cancel: {
    id: 'CommonTranslations.cancel',
    defaultMessage: 'Annuler',
  },
  delete: {
    id: 'CommonTranslations.delete',
    defaultMessage: 'Supprimer',
  },
});
