import { ComponentType, useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import './MonthPicker.scss';
import classNames from 'classnames';
import CalendarIcon from 'assets/component-icons/CalendarIcon';
import { useIntl } from 'react-intl';
import { MonthPickerTranslations, getMonthTranslationIdFromEnumIndex } from './MonthPicker.translations';
import { IconProps } from 'assets/component-icons/Icon';

const YEAR_BOUNDS_OFFSET = 4;

type PopupPosition = 'top' | 'bottom';

type MonthPickerProps = {
  icon?: ComponentType<IconProps>;
  label: string;
  placeholder?: string;
  initMonth?: number;
  initYear?: number;
  minMonth?: number;
  minYear?: number;
  onMonthSelection: (date?: Date) => void;
  disabled?: boolean;
  error?: string;
  popupPosition?: PopupPosition;
  isUndefined?: boolean;
  dataCy?: string;
};

function getMonthsIndexes() {
  return Array.from({ length: 12 }, (_, index) => index);
}

function getYears(year: number) {
  const min = year - YEAR_BOUNDS_OFFSET;
  const max = year + YEAR_BOUNDS_OFFSET;
  return Array.from({ length: max - min + 1 }, (_, i) => i + min);
}

function getCurrentYear() {
  return new Date().getFullYear();
}

export default function MonthPicker({
  icon: Icon,
  label,
  placeholder,
  initMonth,
  initYear,
  onMonthSelection,
  disabled,
  error,
  popupPosition,
  minMonth,
  minYear,
  isUndefined,
  dataCy,
}: MonthPickerProps) {
  const intl = useIntl();

  const [month, setMonth] = useState<number | undefined>(initMonth);
  const [year, setYear] = useState<number>(initYear || getCurrentYear());
  const [yearMode, setYearMode] = useState(false);

  const [isPopupOpen, setPopupOpen] = useState(false);

  const inputId = useMemo(() => label.toLowerCase().replace(' ', '-'), [label]);
  const positionClassName = useMemo(() => `month-picker__popup--${popupPosition || 'bottom'}`, [popupPosition]);

  const nextYear = useCallback(() => {
    setYear((prev) => prev + (yearMode ? YEAR_BOUNDS_OFFSET * 2 + 1 : 1));
  }, [year, yearMode]);

  const previousYear = useCallback(() => {
    setYear((prev) => prev - (yearMode ? YEAR_BOUNDS_OFFSET * 2 + 1 : 1));
  }, [year, yearMode]);

  const selectMonth = useCallback(
    (m: number) => {
      setMonth(m);
      onMonthSelection(moment().month(m).year(year).toDate());
      setPopupOpen(false);
    },
    [month, year],
  );

  const selectYear = useCallback(
    (y: number) => {
      setYear(y);
      onMonthSelection(
        moment()
          .month(month || 0)
          .year(y)
          .toDate(),
      );
      setYearMode(false);
    },
    [month, year],
  );

  const handleClearButton = useCallback(() => {
    setMonth(undefined);
    onMonthSelection(undefined);
  }, [month, year]);

  return (
    <>
      {isPopupOpen && (
        <button
          type="button"
          className="month-picker__overlay"
          onClick={() => setPopupOpen(false)}
        />
      )}
      <div className="month-picker">
        <label
          className="month-picker__label"
          htmlFor={`month-picker-${inputId}`}
        >
          {label}
        </label>
        <button
          data-cy={dataCy}
          type="button"
          className={`month-picker__input ${disabled ? 'month-picker__input--disabled' : ''}`}
          onClick={() => setPopupOpen(true)}
          disabled={disabled}
        >
          {Icon ? <Icon /> : <CalendarIcon />}
          {isUndefined ? (
            <span>{intl.formatMessage(MonthPickerTranslations.undefined)}</span>
          ) : month != null ? (
            <div className="month-picker__input__content">
              <span>{intl.formatMessage(getMonthTranslationIdFromEnumIndex(month))}</span>
              <span>{year}</span>
            </div>
          ) : (
            <div className="month-picker__input__content month-picker__input__content--placeholder">
              <span>{placeholder}</span>
            </div>
          )}
        </button>
        {month != null && (
          <button
            type="button"
            className="month-picker__clear-button"
            onClick={handleClearButton}
          >
            {intl.formatMessage(MonthPickerTranslations.clear)}
          </button>
        )}
        {error && <p className="month-picker__error">{error}</p>}

        {isPopupOpen && (
          <div className={classNames('month-picker__popup', positionClassName)}>
            <div className="month-picker__popup__header">
              <button
                data-cy={dataCy && `${dataCy}__previous`}
                type="button"
                className="month-picker__popup__header__control"
                onClick={previousYear}
                disabled={minYear ? year - 1 < minYear : false}
              >
                {'<'}
              </button>
              {yearMode ? (
                intl.formatMessage(MonthPickerTranslations.year)
              ) : (
                <button
                  type="button"
                  className="month-picker__popup__header__year-button"
                  onClick={() => setYearMode(true)}
                >
                  {year}
                </button>
              )}
              <button
                type="button"
                className="month-picker__popup__header__control"
                onClick={nextYear}
              >
                {'>'}
              </button>
            </div>

            <div className="month-picker__popup__months">
              {yearMode
                ? getYears(year).map((y) => (
                    <button
                      key={y}
                      type="button"
                      className={classNames(
                        'month-picker__popup__months__month',
                        {
                          'month-picker__popup__months__month--active': y === year,
                        },
                        {
                          'month-picker__popup__months__month--disabled': minYear ? y < minYear : false,
                        },
                      )}
                      onClick={() => selectYear(y as number)}
                      disabled={minYear ? y < minYear : false}
                    >
                      {y}
                    </button>
                  ))
                : getMonthsIndexes().map((m) => (
                    <button
                      data-cy={dataCy && `${dataCy}__month`}
                      key={m}
                      type="button"
                      className={classNames(
                        'month-picker__popup__months__month',
                        {
                          'month-picker__popup__months__month--active': m === month,
                        },
                        {
                          'month-picker__popup__months__month--disabled':
                            minMonth && minYear ? m < minMonth && year <= minYear : false,
                        },
                      )}
                      onClick={() => selectMonth(m as number)}
                      disabled={minMonth && minYear ? m < minMonth && year <= minYear : false}
                    >
                      {intl.formatMessage(getMonthTranslationIdFromEnumIndex(m)).substring(0, 3)}
                    </button>
                  ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
