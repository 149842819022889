/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ApolloProvider } from '@apollo/client';
import { SnackbarProvider } from '@myjobglasses/account-ui';
import { Suspense, useCallback, useEffect, useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import { Navigate, Route, BrowserRouter as Router, Routes as RouterRoutes } from 'react-router-dom';
import Authentication from 'scenes/authentication/Authentication';
import FranceTravailOAuthCallback from 'scenes/authentication/oauth/FranceTravailOAuthCallback';
import LinkedInOAuthCallback from 'scenes/authentication/oauth/LinkedInOAuthCallback';
import ConfirmTutorEmail from 'scenes/email-confirmation/confirm-tutor-email/ConfirmTutorEmail';
import MustProvideTutorEmail from 'scenes/email-confirmation/must-provide-tutor-email/MustProvideTutorEmail';
import ProfileCompletion from 'scenes/profile-completion/ProfileCompletion';
import Signup from 'scenes/signup/Signup';
import ConfirmSignup from 'scenes/confirm-signup/ConfirmSignup';
import Routes from './Routes';
import client from './config/graphql';
import { useLanguage } from './hooks/use-languages/useLanguages';
import CompanyAlreadyCustomer from './scenes/company-already-customer/CompanyAlreadyCustomer';
import ConfirmEmail from './scenes/email-confirmation/confirm-email/ConfirmEmail';
import MustConfirmEmail from './scenes/email-confirmation/must-confirm-email/MustConfirmEmail';
import ConfirmNewPassword from './scenes/forgotten-password/pages/confirm-new-password/ConfirmNewPassword';
import NewPassword from './scenes/forgotten-password/pages/new-password/NewPassword';
import RequestNewPassword from './scenes/forgotten-password/pages/request-new-password/RequestNewPassword';
import Signout from './scenes/signout/Signout';

import './App.scss';

import '@myjobglasses/account-ui/dist/index.css';
import ModalContextProvider from 'components/modal/Modal.context';
import { SettingsContextProvider } from 'contexts/settings/SettingsContext';
import useMarketplaceRedirect from 'hooks/use-marketplace-redirect';
import Segmentation from 'scenes/authentication/segmentation/Segmentation';
import Profile from 'scenes/profile/Profile';
import useTrackerBreadcrumbs from './hooks/use-tracker-breadcrumbs';
import { ErrorBoundary } from './ErrorBoundary';
import { errorTracker } from './error-tracker/error-tracker';

function getVocationCodeFromAppFrontRedirectUri() {
  const baseSearchParams = new URL(window.location.href).searchParams;
  const redirectUri = baseSearchParams.get('redirect');
  if (redirectUri) {
    const redirectSearchParams = new URL(redirectUri).searchParams;
    return redirectSearchParams.get('code');
  }
  return undefined;
}

function getVocationProjectInvitationRedirectUri() {
  const baseSearchParams = new URL(window.location.href).searchParams;
  const redirectUri = baseSearchParams.get('redirect');
  if (redirectUri) {
    const vocationUrl = new URL(redirectUri, window.location.origin);
    return vocationUrl.href;
  }
  return undefined;
}

function handleSearchParamsPersistance() {
  const { searchParams } = new URL(window.location.href);

  const vocationCodeFromAppfrontRedirectUri = getVocationCodeFromAppFrontRedirectUri();

  const vocationProjectInvitationUrl = getVocationProjectInvitationRedirectUri();

  const programCode = searchParams.get('program_code');
  if (programCode) localStorage.setItem('program_code', programCode);
  else if (searchParams.has('redirect') && vocationCodeFromAppfrontRedirectUri)
    localStorage.setItem('program_code', vocationCodeFromAppfrontRedirectUri);
  else if (searchParams.has('redirect') && vocationProjectInvitationUrl)
    localStorage.setItem('vocation_url', vocationProjectInvitationUrl);

  const programInvitationId = searchParams.get('program_invitation_id');
  if (programInvitationId) localStorage.setItem('program_invitation_id', programInvitationId);

  const completionContext = searchParams.get('context');
  if (completionContext?.includes('contact')) localStorage.setItem('completion_context', 'contact');
}

function App() {
  useTrackerBreadcrumbs();

  const { setRedirectIfNeeded } = useMarketplaceRedirect();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const vocationCoachSignup = !!searchParams.get('vocation');

  const { language, translations } = useLanguage();

  useEffect(() => {
    setRedirectIfNeeded();
    handleSearchParamsPersistance();
  }, []);

  return (
    <ApolloProvider client={client}>
      <Suspense fallback={<div>Loading...</div>}>
        <SnackbarProvider>
          <IntlProvider
            messages={translations}
            locale={language}
            defaultLocale="fr"
          >
            <ErrorBoundary onErrorCatch={errorTracker.getBoundaryErrorHandler()}>
              <SettingsContextProvider language={language}>
                <ModalContextProvider>
                  <Router>
                    <RouterRoutes>
                      <Route
                        path={Routes.home}
                        element={<Authentication />}
                      />
                      <Route
                        path={Routes.segmentation}
                        element={<Segmentation />}
                      />
                      <Route
                        path={Routes.oAuthLinkedIn}
                        element={<LinkedInOAuthCallback />}
                      />
                      <Route
                        path={Routes.oAuthFranceTravail}
                        element={<FranceTravailOAuthCallback />}
                      />
                      <Route
                        path={Routes.signup}
                        element={<Signup />}
                      />
                      <Route
                        path={Routes.flowSignup}
                        element={<ConfirmSignup />}
                      />
                      <Route
                        path={Routes.confirmSignup}
                        element={<ConfirmSignup vocationCoachSignup={vocationCoachSignup} />}
                      />
                      <Route
                        path={`${Routes.profileCompletion}/*`}
                        element={<ProfileCompletion />}
                      />
                      <Route
                        path={`${Routes.profile}/*`}
                        element={<Profile />}
                      />
                      <Route
                        path={`${Routes.companyAdminProfile}/*`}
                        element={<Profile admin />}
                      />
                      <Route
                        path={Routes.signout}
                        element={<Signout />}
                      />
                      <Route
                        path={Routes.companyAlreadyCustomer}
                        element={<CompanyAlreadyCustomer />}
                      />
                      <Route
                        path={Routes.mustConfirmEmail}
                        element={<MustConfirmEmail />}
                      />
                      <Route
                        path={Routes.mustProvideTutorEmail}
                        element={<MustProvideTutorEmail />}
                      />
                      <Route
                        path={Routes.confirmEmail}
                        element={<ConfirmEmail />}
                      />
                      <Route
                        path={Routes.confirmTutorEmail}
                        element={<ConfirmTutorEmail />}
                      />
                      <Route
                        path={Routes.newPassword}
                        element={<NewPassword />}
                      />
                      <Route
                        path={Routes.requestNewPassword}
                        element={<RequestNewPassword />}
                      />
                      <Route
                        path={Routes.confirmNewPassword}
                        element={<ConfirmNewPassword />}
                      />
                      <Route
                        path="*"
                        element={<Navigate to={Routes.home} />}
                      />
                    </RouterRoutes>
                  </Router>
                </ModalContextProvider>
              </SettingsContextProvider>
            </ErrorBoundary>
          </IntlProvider>
        </SnackbarProvider>
      </Suspense>
    </ApolloProvider>
  );
}

export default App;
