import LockIcon from 'assets/component-icons/LockIcon';
import UserIcon from 'assets/component-icons/UserIcon';
import Layout, { LayoutHeader } from 'components/layout/Layout';
import SelectBlock from 'components/select-block/SelectBlock';
import { useIntl } from 'react-intl';
import { Location, useLocation } from 'react-router-dom';
import { goToHRAdmin, goToMarketplace, goToVocation } from 'utils/redirections';
import './Segmentation.scss';
import { SegmentationTranslations } from './Segmentation.translations';
import UsersIcon from 'assets/component-icons/UsersIcon';

interface LocationState {
  isCoach?: boolean;
  isCompanyHRAdmin?: boolean;
  isSchoolHRAdmin?: boolean;
}

export default function Segmentation() {
  const { formatMessage } = useIntl();
  const {
    state: { isCoach, isCompanyHRAdmin, isSchoolHRAdmin },
  }: Location<LocationState> = useLocation();
  return (
    <Layout withAsideImage>
      <LayoutHeader>
        <h1>{formatMessage(SegmentationTranslations.title)}</h1>
        <p>{formatMessage(SegmentationTranslations.subtitle)}</p>
      </LayoutHeader>

      <div className="segmentation-page">
        {isSchoolHRAdmin && (
          <SelectBlock
            id="hr-admin"
            icon={LockIcon}
            title={formatMessage(SegmentationTranslations.schoolHrAdminTitle)}
            description={formatMessage(SegmentationTranslations.schoolHrAdminDescription)}
            onClick={goToHRAdmin}
          />
        )}

        {isCompanyHRAdmin && (
          <SelectBlock
            id="hr-admin"
            icon={LockIcon}
            title={formatMessage(SegmentationTranslations.hrAdminTitle)}
            description={formatMessage(SegmentationTranslations.hrAdminDescription)}
            onClick={goToHRAdmin}
          />
        )}

        {isCoach && (
          <SelectBlock
            id="coach"
            icon={UsersIcon}
            title={formatMessage(SegmentationTranslations.coachTitle)}
            description={formatMessage(SegmentationTranslations.coachDescription)}
            onClick={goToVocation}
          />
        )}

        <SelectBlock
          id="marketplace"
          icon={UserIcon}
          title={formatMessage(SegmentationTranslations.mjgTitle)}
          description={formatMessage(SegmentationTranslations.mjgDescription)}
          onClick={goToMarketplace}
        />
      </div>
    </Layout>
  );
}
