import { defineMessages } from 'react-intl';

export const AuthenticationTranslations = defineMessages({
  title: {
    id: 'Authentication.title',
    defaultMessage: 'Se connecter',
  },
  subtitle: {
    id: 'Authentication.subtitle',
    defaultMessage:
      'Pour vous connecter, entrez votre adresse email et votre mot de passe, ou utilisez vos comptes LinkedIn ou France Travail pour continuer.',
  },
  email: {
    id: 'Authentication.email',
    defaultMessage: 'Email',
  },
  password: {
    id: 'Authentication.password',
    defaultMessage: 'Mot de passe',
  },
  signIn: {
    id: 'Authentication.signIn',
    defaultMessage: 'Continuer',
  },
  noAccount: {
    id: 'Authentication.noAccount',
    defaultMessage: 'Vous n’avez pas de compte ?',
  },
  signUp: {
    id: 'Authentication.signUp',
    defaultMessage: ' Inscrivez-vous',
  },
  or: {
    id: 'Authentication.orSeparator',
    defaultMessage: 'ou',
  },
  linkedIn: {
    id: 'Authentication.continueWithLinkedIn',
    defaultMessage: 'Continuer avec LinkedIn',
  },
  franceTravail: {
    id: 'Authentication.continueWithFranceTravail',
    defaultMessage: 'Continuer avec France Travail',
  },
  apple: {
    id: 'Authentication.continueWithApple',
    defaultMessage: 'Continuer avec Apple',
  },
});

export const AuthenticationErrorTranslations = defineMessages({
  emailRequired: {
    id: 'AuthenticationError.emailRequired',
    defaultMessage: 'Email requis',
  },
  putPassword: {
    id: 'AuthenticationError.putPassword',
    defaultMessage: 'Veuillez renseigner un mot de passe.',
  },
  emailInvalid: {
    id: 'AuthenticationError.emailInvalid',
    defaultMessage: 'Veuillez saisir une adresse e-mail valide.',
  },
  mustAcceptCGU: {
    id: 'AuthenticationError.mustAcceptCGU',
    defaultMessage: 'Vous devez accepter les termes et conditions pour créer votre compte.',
  },
  birthdateRequired: {
    id: 'AuthenticationError.birthdateRequired',
    defaultMessage: 'Date de naissance requise',
  },
  minimumAgeRequired: {
    id: 'AuthenticationError.minimumAgeRequired',
    defaultMessage: "Vous n'avez pas l'âge minimum requis pour vous inscrire.",
  },
  tutorEmailUnconfirmed: {
    id: 'AuthenticationError.tutorEmailUnconfirmed',
    defaultMessage: 'Email du tuteur non confirmé',
  },
  emailUnconfirmed: {
    id: 'AuthenticationError.emailUnconfirmed',
    defaultMessage: 'Email non confirmé',
  },
  emailForbiddenDomain: {
    id: 'AuthenticationError.EmailForbiddenDomain',
    defaultMessage: "Ce domaine email n'est pas accepté",
  },
  emailTaken: {
    id: 'AuthenticationError.emailTaken',
    defaultMessage: "L'adresse email ou le mot de passe renseigné est incorrect.",
  },
  defaultError: {
    id: 'AuthenticationError.defaultError',
    defaultMessage: 'Une erreur a eu lieu. Veuillez réessayer plus tard.',
  },
});
