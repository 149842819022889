import { gql } from '@apollo/client';

export const SIGN_IN_MUTATION = gql`
  mutation SIGN_IN($email: Email!, $password: String!) {
    signin(email: $email, password: $password) {
      email
      companyAdminProfiles {
        id
      }
      isCoach
    }
  }
`;
