import { BinaryResponse } from 'models/BinaryResponse.enum';
import { IntlShape } from 'react-intl';
import * as Yup from 'yup';
import YupPassword from 'yup-password';
import {
  DiplomaEnum,
  GenderEnum,
  GradeEnum,
  JobTitleKeyEnum,
  MeetingPreference,
  PrimarySituationEnum,
  SecondarySituationEnum,
  JobSeekerSupportProgramKeyEnum,
} from '../../../@types/graphql';
import { PanelErrorTranslations } from './panels.translations';

YupPassword(Yup);
const { array, boolean, date, mixed, number, object, ref, string } = Yup;

export const birthdateValidationSchema = (intl: IntlShape) =>
  object({
    birthdate: date()
      // eslint-disable-next-line max-len
      .required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
  });

export const passwordCreationValidationSchema = (intl: IntlShape) =>
  object({
    password: string()
      .required(intl.formatMessage(PanelErrorTranslations.inputEmpty))
      .min(8, intl.formatMessage(PanelErrorTranslations.inputTooShort))
      .minLowercase(1, intl.formatMessage(PanelErrorTranslations.passwordTooWeak))
      .minUppercase(1, intl.formatMessage(PanelErrorTranslations.passwordTooWeak))
      .minNumbers(1, intl.formatMessage(PanelErrorTranslations.passwordTooWeak))
      .minSymbols(1, intl.formatMessage(PanelErrorTranslations.passwordTooWeak)),
    confirmPassword: string()
      .required(intl.formatMessage(PanelErrorTranslations.inputEmpty))
      .test('passwords-match', intl.formatMessage(PanelErrorTranslations.passwordMismatch), function (value) {
        return this.parent.password === value;
      }),
  });

export const identityPanelValidationSchema = (intl: IntlShape) =>
  object({
    gender: mixed<GenderEnum>().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
    firstName: string()
      .required(intl.formatMessage(PanelErrorTranslations.inputEmpty))
      .min(2, intl.formatMessage(PanelErrorTranslations.inputTooShort)),
    lastName: string()
      .required(intl.formatMessage(PanelErrorTranslations.inputEmpty))
      .min(1, intl.formatMessage(PanelErrorTranslations.inputTooShort)),
  });

export const professionalSituationPanelValidationSchema = (intl: IntlShape) =>
  object({
    position: string().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
    companyName: string().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
    yearsOfExperience: number().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
  });

export const otherSituationPanelValidationSchema = (intl: IntlShape) =>
  object({
    neverWorked: boolean(),
    previousPosition: string().when('neverWorked', {
      is: false,
      then: (schema) => schema.required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
      otherwise: (schema) => schema,
    }),
    previousCompanyName: string().when('neverWorked', {
      is: false,
      then: (schema) => schema.required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
      otherwise: (schema) => schema,
    }),
    previousPositionYearsOfExperiences: number().when('neverWorked', {
      is: false,
      then: (schema) => schema.required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
      otherwise: (schema) => schema,
    }),
  });

export const schoolCoachSituationPanelValidationSchema = (intl: IntlShape, primarySituation?: PrimarySituationEnum) =>
  object({
    secondarySituation: mixed<SecondarySituationEnum>().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
    currentSchoolDepartment: string().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
    currentSchoolName: string().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
    position: string().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
    teachedSchoolSubjects: array().when('position', {
      is: JobTitleKeyEnum.Teacher,
      then: (schema) =>
        schema
          .of(string().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)))
          .min(1, intl.formatMessage(PanelErrorTranslations.inputEmpty))
          .required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
      otherwise: (schema) => schema,
    }),
    accompaniedStudyLevels: array()
      .of(string().oneOf(Object.values(GradeEnum), intl.formatMessage(PanelErrorTranslations.invalidGradeEnum)))
      .min(1, intl.formatMessage(PanelErrorTranslations.inputEmpty))
      .required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
    headTeacher: mixed<BinaryResponse>().when(['position', 'primarySituation'], {
      is: (position: JobTitleKeyEnum, primarySituation: PrimarySituationEnum) =>
        position === JobTitleKeyEnum.Teacher &&
        primarySituation === PrimarySituationEnum.ProfessionalAndSecondarySchoolStudentSupport,
      then: (schema) => schema.required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
      otherwise: (schema) => schema,
    }),
  });

export const franceTravailSupportSituationPanelValidationSchema = (intl: IntlShape) =>
  object({
    jobSeekerSupportOrganizationType: string().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
    position: string().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
    companyName: string().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
    jobSeekerSupportPrograms: array()
      .of(string())
      .min(1, intl.formatMessage(PanelErrorTranslations.inputEmpty))
      .required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
  });

export const currentEducationPanelValidationSchema = (intl: IntlShape) =>
  object({
    secondarySituation: mixed<SecondarySituationEnum>().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
    currentGrade: mixed<GradeEnum>().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
    currentSchoolDepartment: string().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
    currentSchoolName: string().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
    currentSchoolCity: string().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
    preparedDiplomaTitle: string().when('secondarySituation', {
      is: (val: SecondarySituationEnum) =>
        val !== SecondarySituationEnum.College && val !== SecondarySituationEnum.LyceeGt,
      then: (schema) => schema.required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
      otherwise: (schema) => schema,
    }),
    apprentice: mixed<BinaryResponse>().when('secondarySituation', {
      is: (val: SecondarySituationEnum) => val !== SecondarySituationEnum.College,
      then: (schema) => schema.required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
      otherwise: (schema) => schema,
    }),
  });

export const programAffiliationPanelValidationSchema = (intl: IntlShape) =>
  object({
    followingVocationProgram: mixed<BinaryResponse>().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
    programAffiliationCode: string().when('followingVocationProgram', {
      is: BinaryResponse.Yes,
      then: (schema) =>
        schema
          .required(intl.formatMessage(PanelErrorTranslations.inputEmpty))
          .min(4, intl.formatMessage(PanelErrorTranslations.inputTooShort)),
      otherwise: (schema) => schema,
    }),
  });

export const maxMeetingPanelValidationSchema = (intl: IntlShape) =>
  object({
    maxMeetingsPerMonth: number(),
  });

const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/;
export const phonePanelValidationSchema = (intl: IntlShape) =>
  object({
    phoneNumber: string()
      .required(intl.formatMessage(PanelErrorTranslations.inputEmpty))
      .matches(phoneRegExp, intl.formatMessage(PanelErrorTranslations.invalidPhoneNumber)),
  });

export const languagesPanelValidationSchema = (intl: IntlShape) =>
  object({
    languages: array()
      .of(string())
      .min(1, intl.formatMessage(PanelErrorTranslations.inputEmpty))
      .required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
  });

export const meetingPlacePanelValidationSchema = (intl: IntlShape) =>
  object({
    googlePlace: object().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
  });

export const meetingPreferencesPanelValidationSchema = (intl: IntlShape) =>
  object({
    meetingPreferences: array().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
    acceptGroupConversations: boolean()
      .test(
        'at-least-one-checked',
        intl.formatMessage(PanelErrorTranslations.inputEmpty),
        function (acceptGroupConversations) {
          const { meetingPreferences } = this.parent; // Récupère les meetingPreferences du même niveau
          const hasAtLeastOneMeetingPreferenceTrue = meetingPreferences.some(
            (preference: { value: boolean }) => preference.value === true,
          );

          // Vérifie si au moins une préférence est true ou si acceptGroupConversations est true
          return hasAtLeastOneMeetingPreferenceTrue || acceptGroupConversations === true;
        },
      )
      .required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
  });

export const avatarPanelValidationSchema = (intl: IntlShape) =>
  object({
    avatar: mixed().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
  });

export const memberPresentationValidationSchema = (intl: IntlShape) =>
  object({
    memberPresentation: string().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
  });

export const ambassadorPresentationValidationSchema = (intl: IntlShape) =>
  object({
    ambassadorPresentation: string().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
  });

export const aspirationPresentationValidationSchema = (intl: IntlShape) =>
  object({
    aspirationPresentation: string().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
  });

export const expertisePresentationValidationSchema = (intl: IntlShape) =>
  object({
    expertisePresentation: string().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
  });

export const interestsPresentationValidationSchema = (intl: IntlShape) =>
  object({
    interestsPresentation: string().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
  });

export const pastEducationPanelValidationSchema = (intl: IntlShape) =>
  object({
    acquiredDiplomaLevel: mixed<DiplomaEnum>().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
    acquiredDiplomaTitle: string().when('acquiredDiplomaLevel', {
      is: (AcquiredDiplomaLevel: DiplomaEnum) =>
        AcquiredDiplomaLevel !== DiplomaEnum.None &&
        AcquiredDiplomaLevel !== DiplomaEnum.Cep &&
        AcquiredDiplomaLevel !== DiplomaEnum.Brevet,
      then: (schema) => schema.required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
      otherwise: (schema) => schema,
    }),
    acquiredDiplomaSchoolName: string().when('acquiredDiplomaLevel', {
      is: (AcquiredDiplomaLevel: DiplomaEnum) => AcquiredDiplomaLevel !== DiplomaEnum.None,
      then: (schema) => schema.required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
      otherwise: (schema) => schema,
    }),
    startDate: number().when('acquiredDiplomaLevel', {
      is: (AcquiredDiplomaLevel: DiplomaEnum) => AcquiredDiplomaLevel !== DiplomaEnum.None,
      then: (schema) =>
        schema
          .min(1, intl.formatMessage(PanelErrorTranslations.inputEmpty))
          .max(new Date().getFullYear(), intl.formatMessage(PanelErrorTranslations.endDateInFuture)),
      otherwise: (schema) => schema,
    }),
    endDate: number().when('acquiredDiplomaLevel', {
      is: (AcquiredDiplomaLevel: DiplomaEnum) => AcquiredDiplomaLevel !== DiplomaEnum.None,
      then: (schema) =>
        schema
          .min(ref('startDate'), intl.formatMessage(PanelErrorTranslations.endDateBeforeStartDate))
          .min(1, intl.formatMessage(PanelErrorTranslations.inputEmpty))
          .max(new Date().getFullYear(), intl.formatMessage(PanelErrorTranslations.endDateInFuture)),
      otherwise: (schema) => schema,
    }),
  });
