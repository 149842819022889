/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Icon } from '@myjobglasses/account-ui';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Navigate, useLocation, Location } from 'react-router-dom';
import { Formik } from 'formik';
import Routes from '../../../Routes';
import RTFBold from '../../commons/rich-text-formatting/bold/RTFBold';
import { useRequestEmailAddressConfirmationEmail } from './MustConfirmEmail.hooks';
import translations from './MustConfirmEmail.translations';
import validation from './MustConfirmEmail.validation';

import styles from './MustConfirmEmail.module.scss';
import Layout from 'components/layout/Layout';

type LocationState = {
  email: string;
  tutorEmail?: boolean;
};

function MustConfirmEmail() {
  const intl = useIntl();
  const location = useLocation() as Location<LocationState>;
  const [sentStatus, setSentStatus] = useState<boolean>(false);

  const onSubmit = useRequestEmailAddressConfirmationEmail(setSentStatus);

  if (!location.state.email) {
    return <Navigate to={Routes.home} />;
  }

  return (
    <Layout withAsideImage>
      <div className={styles.mustConfirmEmailAdressView}>
        <div className={styles.mustConfirmEmailAdress}>
          <div className={styles.iconHeader}>
            <Icon
              name="gpp_good"
              className={styles.icon}
            />
          </div>
          <h1 className={styles.title}>
            {intl.formatMessage(location.state.tutorEmail ? translations.titleTutor : translations.title)}
          </h1>
          <p className={styles.description}>
            {intl.formatMessage(location.state.tutorEmail ? translations.descriptionTutor : translations.description, {
              email: location.state.email,
              boldThis: RTFBold,
            })}
          </p>
          <Formik
            initialValues={{
              email: location.state.email,
            }}
            validateOnMount
            validateOnChange
            validationSchema={validation}
            enableReinitialize
            onSubmit={onSubmit}
          >
            {({
              isValid,
              handleSubmit,
              // eslint-disable-next-line arrow-body-style
            }) => {
              return (
                <form
                  className={styles.form}
                  onSubmit={handleSubmit}
                >
                  {sentStatus ? (
                    <p
                      data-testid="email-resend"
                      className={styles.emailSent}
                    >
                      {intl.formatMessage(translations.emailSent)}
                    </p>
                  ) : (
                    <button
                      type="submit"
                      data-testid="submit-button"
                      className={styles.submitButton}
                      disabled={!isValid}
                    >
                      {intl.formatMessage(translations.resendEmail)}
                    </button>
                  )}
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </Layout>
  );
}

export default MustConfirmEmail;
