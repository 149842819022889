import { useEffect } from 'react';
import { useFormik } from 'formik';
import ArrowRightIcon from 'assets/component-icons/ArrowRightIcon';
import BriefcaseIcon from 'assets/component-icons/BriefcaseIcon';
import BuildingIcon from 'assets/component-icons/BuildingIcon';
import ClockRewindIcon from 'assets/component-icons/ClockRewindIcon';
import TextInput from '../../../components/text-input/TextInput';
import PrimaryButton from '../../../components/primary-button/PrimaryButton';
import useProfileCompletionUserUpdate from './ProfileCompletionUserUpdate.hook';
import { useIntl } from 'react-intl';
import { OtherSituationPanelTranslations } from './panels.translations';
import { otherSituationPanelValidationSchema } from './panels.validation';
import { CommonTranslations } from 'i18n/common.translations';
import CheckboxInput from 'components/checkbox-input/CheckboxInput';
import useFormikNetworkState from 'hooks/use-formik-network-state';
import formikError from 'components/formik-helper/formik.helper';

type FormikValues = {
  previousPosition: string;
  previousCompanyName: string;
  previousPositionYearsOfExperiences: number;
  neverWorked: boolean;
};

export default function OtherSituationPanel() {
  const intl = useIntl();

  const updateUser = useProfileCompletionUserUpdate();
  const networkState = useFormikNetworkState(ArrowRightIcon);

  const formik = useFormik<FormikValues>({
    initialValues: {
      previousPosition: '',
      previousCompanyName: '',
      previousPositionYearsOfExperiences: 0,
      neverWorked: false,
    },
    validationSchema: otherSituationPanelValidationSchema(intl),
    validateOnMount: true,
    onSubmit: async (values) => {
      networkState.loading();
      await updateUser(values);
      networkState.succeeded();
    },
  });

  useEffect(() => {
    if (formik.values.neverWorked) {
      formik.resetForm();
      formik.setFieldValue('neverWorked', true); // Keep neverworked state as true since we checked it
    }
  }, [formik.values.neverWorked]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextInput
        dataCy="previous-position"
        name="previousPosition"
        label={intl.formatMessage(OtherSituationPanelTranslations.previousJobTitle)}
        placeholder={intl.formatMessage(OtherSituationPanelTranslations.previousJobTitlePlaceholder)}
        icon={BriefcaseIcon}
        value={formik.values.previousPosition}
        onChange={formik.handleChange}
        disabled={formik.values.neverWorked}
        error={formikError(formik.touched, formik.errors, 'previousPosition')}
      />

      <TextInput
        dataCy="previous-company-name"
        name="previousCompanyName"
        label={intl.formatMessage(OtherSituationPanelTranslations.previousCompanyName)}
        placeholder={intl.formatMessage(OtherSituationPanelTranslations.previousCompanyNamePlaceholder)}
        icon={BuildingIcon}
        value={formik.values.previousCompanyName}
        onChange={formik.handleChange}
        disabled={formik.values.neverWorked}
        error={formikError(formik.touched, formik.errors, 'previousCompanyName')}
      />

      <TextInput
        dataCy="previous-years-of-experience"
        name="previousPositionYearsOfExperiences"
        label={intl.formatMessage(OtherSituationPanelTranslations.previousYearsOfExperience)}
        placeholder={intl.formatMessage(OtherSituationPanelTranslations.previousYearsOfExperiencePlaceholder)}
        icon={ClockRewindIcon}
        value={formik.values.previousPositionYearsOfExperiences}
        type="number"
        onChange={formik.handleChange}
        disabled={formik.values.neverWorked}
        error={formikError(formik.touched, formik.errors, 'previousPositionYearsOfExperiences')}
      />

      <CheckboxInput
        name="neverWorked"
        label={intl.formatMessage(OtherSituationPanelTranslations.neverWorked)}
        checked={formik.values.neverWorked}
        onChange={formik.handleChange}
      />

      <PrimaryButton
        label={intl.formatMessage(CommonTranslations.continue)}
        icon={networkState.iconBasedOnNetworkState}
        submit
        disabled={!formik.dirty}
      />
    </form>
  );
}
