import { PropsWithChildren } from 'react';
import './Layout.scss';
import classNames from 'classnames';
import { Languages, useLanguage } from 'hooks/use-languages/useLanguages';
import { setStorageItem } from 'utils/storage';
import Storage from 'enums/storage';

type LayoutProps = PropsWithChildren & {
  withAsideImage?: boolean;
  notAnimated?: boolean;
};

export default function Layout({ children, withAsideImage }: LayoutProps) {
  const { language, setLanguage } = useLanguage();

  function changeLanguage(lang: Languages) {
    setStorageItem(Storage.LANGUAGE, lang);
    setLanguage(lang);
    window.location.reload();
  }

  return (
    <div className="layout">
      <div className="layout__main">
        <main
          className={classNames('layout__main__container', {
            'layout__main__container--with-aside': withAsideImage,
          })}
        >
          <nav className="layout__main__container__nav">
            <img
              className="layout__main__container__nav__logo"
              src="https://assets.myjobglasses.com/logo/mjg-logo-v3-rectangle.svg"
              alt="my job glasses logo"
            />
            <div className="layout__main__container__nav__languages">
              <button
                type="button"
                className={classNames({ selected: language === 'fr' })}
                onClick={() => changeLanguage('fr')}
              >
                FR
              </button>
              |
              <button
                type="button"
                className={classNames({ selected: language === 'en' })}
                onClick={() => changeLanguage('en')}
              >
                EN
              </button>
            </div>
          </nav>

          <div className="layout__main__container__separator" />

          <div className="layout__main__container__content">{children}</div>
        </main>
      </div>
      {withAsideImage && <aside className="layout__aside" />}
    </div>
  );
}

export function LayoutHeader({ children }: PropsWithChildren) {
  return <div className="layout-header">{children}</div>;
}

export function LayoutSeparator({ children }: PropsWithChildren) {
  return <div className="layout-separator">{children}</div>;
}
