import { gql } from '@apollo/client';

export const CREATE_EDUCATION_MUTATION = gql`
  mutation CreateEducation(
    $diplomaTitle: String!
    $diplomaType: DiplomaEnum!
    $schoolName: String!
    $startedAt: ISO8601DateTime!
    $endedAt: ISO8601DateTime!
    $userId: ID!
  ) {
    createEducation(
      diplomaTitle: $diplomaTitle
      diplomaType: $diplomaType
      schoolName: $schoolName
      startedAt: $startedAt
      endedAt: $endedAt
      userId: $userId
    ) {
      id
      diplomaTitle
      diplomaType
      schoolName
      startedAt
      endedAt
    }
  }
`;

export const UPDATE_EDUCATION_MUTATION = gql`
  mutation UpdateEducation(
    $educationId: ID!
    $diplomaTitle: String!
    $diplomaType: DiplomaEnum!
    $schoolName: String!
    $startedAt: ISO8601DateTime!
    $endedAt: ISO8601DateTime!
    $userId: ID!
  ) {
    updateEducation(
      educationId: $educationId
      diplomaTitle: $diplomaTitle
      diplomaType: $diplomaType
      schoolName: $schoolName
      startedAt: $startedAt
      endedAt: $endedAt
      userId: $userId
    ) {
      id
      diplomaTitle
      diplomaType
      schoolName
      startedAt
      endedAt
    }
  }
`;

export const REMOVE_EDUCATION_MUTATION = gql`
  mutation RemoveEducation($educationId: ID!, $userId: ID!) {
    destroyEducation(educationId: $educationId, userId: $userId)
  }
`;
