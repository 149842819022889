import { gql } from '@apollo/client';

export const USER_PARTS_FRAGMENT = gql`
  fragment UserParts on AmbivalentUser {
    id
    editionCompletionStatus: completionFor(completionContext: EDITION) {
      fieldsCompletion {
        name
        complete
        points
        disabled
      }
    }
    currentUserAuthorizations {
      canPublish
      canRequestAdminRhModeration
      canRequestAdminRhUnpublication
      canRequestModeration
      canUnpublish
    }
    isModeratable
    isAdminRhModeratable
    isPublishable
    isInModeration
    isUnpublishable

    professionalId
    publishedAt
    publicationStatus
    isPaidAmbassador
    canBecomeAmbassador

    avatars {
      url
    }
    gender
    firstName
    lastName
    birthdate
    isMinor
    tutorEmailConfirmedAt
    tutorEmail

    primarySituation {
      key
      name(locale: $locale)
    }
    secondarySituation {
      key
      name(locale: $locale)
    }

    jobTitle
    companyName
    yearsOfExperience
    position

    previousPosition
    previousCompanyName
    previousPositionYearsOfExperiences
    neverWorked

    availableGrades
    currentGrade
    currentSchoolName
    currentSchoolCity
    currentSchoolDepartment
    apprentice
    preparedDiplomaTitle

    presentationTranslations {
      memberPresentationExample
      memberPresentationQuestion

      ambassadorPresentationExample(locale: $locale)
      ambassadorPresentationQuestion(locale: $locale)
      interestsExample(locale: $locale)
      interestsQuestion(locale: $locale)
      aspirationsExample(locale: $locale)
      aspirationsQuestion(locale: $locale)
      expertisesExample(locale: $locale)
      expertisesQuestion(locale: $locale)
    }

    memberPresentation
    ambassadorPresentation
    interestsPresentation
    aspirationPresentation
    expertisePresentation

    educations {
      id
      diplomaTitle
      diplomaType
      schoolName
      startedAt
      endedAt
    }

    phoneCountryCode
    phoneWithoutCountryCode

    googlePlaceId
    meetingPlace

    languages

    meetingPreferences {
      key
      name(locale: $locale)
      description(locale: $locale)
      value
    }

    maxMeetingsPerMonth

    galleryImages {
      id
      imageUrl
    }

    canShare
    wantToShare

    recommendedQualifications
    mandatorySkills

    mainAdminEmail

    locatedInLaReunion
    teachedSchoolSubjects
    accompaniedStudyLevels {
      key
      name(locale: $locale)
    }
    headTeacher
    isCoach
    jobSeekerSupportPrograms
    jobSeekerSupportOrganizationType
    canAcceptGroupConversation
    acceptGroupConversations
  }
`;

export const GET_USER_QUERY = gql`
  ${USER_PARTS_FRAGMENT}
  query GetUser($locale: AvailableLanguagesEnum!) {
    currentUserV2 {
      ...UserParts
    }
  }
`;

export const GET_OTHER_USER_QUERY = gql`
  ${USER_PARTS_FRAGMENT}
  query GetOtherUser($locale: AvailableLanguagesEnum!, $id: ID!) {
    getUser(id: $id) {
      ...UserParts
    }
  }
`;

export const UPDATE_USER = gql`
  ${USER_PARTS_FRAGMENT}
  mutation UpdateUser(
    $locale: AvailableLanguagesEnum!
    $userId: ID!
    $avatar: Upload
    $gender: GenderEnum
    $firstName: String
    $lastName: String
    $primarySituation: PrimarySituationEnum
    $secondarySituation: SecondarySituationEnum
    $position: String
    $companyName: String
    $yearsOfExperience: Int
    $previousPosition: String
    $previousCompanyName: String
    $previousPositionYearsOfExperiences: Int
    $neverWorked: Boolean
    $currentGrade: GradeEnum
    $currentSchoolName: String
    $currentSchoolCity: String
    $currentSchoolDepartment: String
    $apprentice: Boolean
    $meetingPlace: String
    $googlePlaceId: String
    $normalizedPhone: String
    $languages: [Language!]
    $meetingPreferences: [MeetingPreferenceInput!]
    $maxMeetingsPerMonth: Int
    $wantToShare: Boolean
    $memberPresentation: String
    $ambassadorPresentation: String
    $expertisePresentation: String
    $aspirationPresentation: String
    $interestsPresentation: String
    $recommendedQualifications: [String!]
    $mandatorySkills: [String!]
    $preparedDiplomaTitle: String
    $accompaniedStudyLevels: [GradeEnum!]
    $teachedSchoolSubjects: [String!]
    $headTeacher: Boolean
    $jobSeekerSupportPrograms: [String!]
    $jobSeekerSupportOrganizationType: String
    $acceptGroupConversations: Boolean
  ) {
    updateUser(
      userId: $userId

      avatar: $avatar
      gender: $gender
      firstName: $firstName
      lastName: $lastName

      primarySituation: $primarySituation
      secondarySituation: $secondarySituation

      position: $position
      companyName: $companyName
      yearsOfExperience: $yearsOfExperience

      previousPosition: $previousPosition
      previousCompanyName: $previousCompanyName
      previousPositionYearsOfExperiences: $previousPositionYearsOfExperiences
      neverWorked: $neverWorked

      currentGrade: $currentGrade
      currentSchoolName: $currentSchoolName
      currentSchoolCity: $currentSchoolCity
      currentSchoolDepartment: $currentSchoolDepartment
      apprentice: $apprentice

      meetingPlace: $meetingPlace
      googlePlaceId: $googlePlaceId

      normalizedPhone: $normalizedPhone

      languages: $languages

      meetingPreferences: $meetingPreferences

      maxMeetingsPerMonth: $maxMeetingsPerMonth

      wantToShare: $wantToShare

      memberPresentation: $memberPresentation
      ambassadorPresentation: $ambassadorPresentation
      expertisePresentation: $expertisePresentation
      aspirationPresentation: $aspirationPresentation
      interestsPresentation: $interestsPresentation

      recommendedQualifications: $recommendedQualifications
      mandatorySkills: $mandatorySkills
      preparedDiplomaTitle: $preparedDiplomaTitle
      accompaniedStudyLevels: $accompaniedStudyLevels
      teachedSchoolSubjects: $teachedSchoolSubjects
      headTeacher: $headTeacher
      jobSeekerSupportPrograms: $jobSeekerSupportPrograms
      jobSeekerSupportOrganizationType: $jobSeekerSupportOrganizationType
      acceptGroupConversations: $acceptGroupConversations
    ) {
      ...UserParts
    }
  }
`;
