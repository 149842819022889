import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'MustConfirmEmail.title',
    defaultMessage: 'Confirmer mon adresse email',
  },
  titleTutor: {
    id: 'MustConfirmEmail.titleTutor',
    defaultMessage: 'Confirmer l’adresse email de mon parent ou responsable légal',
  },
  description: {
    id: 'MustConfirmEmail.description',
    defaultMessage:
      'Votre compte a bien été créé. Pour poursuivre votre expérience, veuillez cliquer sur le lien qui a été envoyé à l’adresse <boldThis>{email}</boldThis>.',
  },
  descriptionTutor: {
    id: 'MustConfirmEmail.descriptionTutor',
    defaultMessage:
      'Pour confirmer votre inscription, votre parent ou responsable légal doit cliquer sur le lien qui a été envoyé par email à l’adresse suivante : <boldThis>{email}</boldThis>.',
  },
  resendEmail: {
    id: 'MustConfirmEmail.resendEmail',
    defaultMessage: 'envoyer un autre email',
  },
  emailSent: {
    id: 'MustConfirmEmail.emailSent',
    defaultMessage: 'email envoyé',
  },
});
