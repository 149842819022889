export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A URL of an avatar image */
  AvatarUrl: { input: any; output: any };
  /** An email */
  Email: { input: any; output: any };
  /** An ISO 8601-encoded date */
  ISO8601Date: { input: any; output: any };
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: any; output: any };
  /** A URL of a company logo */
  LogoUrl: { input: any; output: any };
  /** A int value between 0 and 100 */
  Percentage: { input: any; output: any };
  Upload: { input: any; output: any };
};

export enum AcceptInvitationErrorEnum {
  /** Invitation already accepted */
  AlreadyAccepted = 'ALREADY_ACCEPTED',
  /** Invitation already claimed by another user */
  AlreadyClaimed = 'ALREADY_CLAIMED',
  /** Not found */
  NotFound = 'NOT_FOUND',
  /** Unauthorized / Unauthenticated */
  Unauthorized = 'UNAUTHORIZED',
}

export enum AcknowledgeConversationMutationErrorEnum {
  NotFound = 'NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED',
}

/** Autogenerated return type of AddRecruitingWorkingArea. */
export type AddRecruitingWorkingAreaPayload = {
  __typename?: 'AddRecruitingWorkingAreaPayload';
  result: Scalars['Boolean']['output'];
  workingAreas: Array<Scalars['String']['output']>;
};

/** The result of a AffiliateToAssignment mutation */
export type AffiliationResult = {
  __typename?: 'AffiliationResult';
  assignmentActivityStartsAt: Scalars['ISO8601DateTime']['output'];
  earlyAffiliationWarning: Scalars['Boolean']['output'];
};

export type AmbassadorAutocompleteResult = {
  __typename?: 'AmbassadorAutocompleteResult';
  matchingResults: Array<MatchingResult>;
  type: Scalars['String']['output'];
};

export enum AmbassadorBadgeEnum {
  AppointmentNo_1Occurred = 'APPOINTMENT_NO_1_OCCURRED',
  AppointmentNo_1Reviewed = 'APPOINTMENT_NO_1_REVIEWED',
  AppointmentNo_3Occurred = 'APPOINTMENT_NO_3_OCCURRED',
  AppointmentNo_5Occurred = 'APPOINTMENT_NO_5_OCCURRED',
  AppointmentNo_10Occurred = 'APPOINTMENT_NO_10_OCCURRED',
  AppointmentNo_15Occurred = 'APPOINTMENT_NO_15_OCCURRED',
  AppointmentNo_20Occurred = 'APPOINTMENT_NO_20_OCCURRED',
  AppointmentNo_30Occurred = 'APPOINTMENT_NO_30_OCCURRED',
  AppointmentNo_50Occurred = 'APPOINTMENT_NO_50_OCCURRED',
  AppointmentNo_75Occurred = 'APPOINTMENT_NO_75_OCCURRED',
  AppointmentNo_100Occurred = 'APPOINTMENT_NO_100_OCCURRED',
  AppointmentNo_150Occurred = 'APPOINTMENT_NO_150_OCCURRED',
  AppointmentNo_200Occurred = 'APPOINTMENT_NO_200_OCCURRED',
  AppointmentNo_300Occurred = 'APPOINTMENT_NO_300_OCCURRED',
  AppointmentNo_500Occurred = 'APPOINTMENT_NO_500_OCCURRED',
  FirstConversationReceived = 'FIRST_CONVERSATION_RECEIVED',
  FirstConversationReplied = 'FIRST_CONVERSATION_REPLIED',
  ProfileCompletedForTheFirstTime = 'PROFILE_COMPLETED_FOR_THE_FIRST_TIME',
  ProfilePublishedForTheFirstTime = 'PROFILE_PUBLISHED_FOR_THE_FIRST_TIME',
  Registration = 'REGISTRATION',
}

/** Search result for professionals */
export type AmbassadorResults = InterlocutorInterface &
  UserProfileTypeInterface & {
    __typename?: 'AmbassadorResults';
    acquiredDiplomaAcquisitionYear?: Maybe<Scalars['Int']['output']>;
    acquiredDiplomaBeginYear?: Maybe<Scalars['Int']['output']>;
    acquiredDiplomaLevel?: Maybe<Diploma>;
    acquiredDiplomaSchoolName?: Maybe<Scalars['String']['output']>;
    acquiredDiplomaTitle?: Maybe<Scalars['String']['output']>;
    ambassadorPresentation?: Maybe<Scalars['String']['output']>;
    anonymizedOrDeleted?: Maybe<Scalars['Boolean']['output']>;
    aspirationPresentation?: Maybe<Scalars['String']['output']>;
    avatar: Avatar;
    avatars: Avatar;
    banner?: Maybe<OrganizationCardBanner>;
    companyDescription?: Maybe<Scalars['String']['output']>;
    companyLogo?: Maybe<OrganizationLogo>;
    companyName?: Maybe<Scalars['String']['output']>;
    currentGrade?: Maybe<Grade>;
    currentGradeOrDiploma?: Maybe<Scalars['String']['output']>;
    currentSchoolCity?: Maybe<Scalars['String']['output']>;
    currentSchoolDepartment?: Maybe<Scalars['String']['output']>;
    currentSchoolLogo?: Maybe<OrganizationLogo>;
    currentSchoolName?: Maybe<Scalars['String']['output']>;
    email: Scalars['String']['output'];
    expertisePresentation?: Maybe<Scalars['String']['output']>;
    firstName: Scalars['String']['output'];
    firstPublishedAt?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    interestsPresentation?: Maybe<Scalars['String']['output']>;
    jobTitle?: Maybe<Scalars['String']['output']>;
    languages?: Maybe<Array<Language>>;
    lastName?: Maybe<Scalars['String']['output']>;
    meetingPlace?: Maybe<Scalars['String']['output']>;
    memberPresentation?: Maybe<Scalars['String']['output']>;
    phoneNumber?: Maybe<Scalars['String']['output']>;
    position?: Maybe<Scalars['String']['output']>;
    positiveLikeCount?: Maybe<Scalars['Int']['output']>;
    premium?: Maybe<Scalars['Boolean']['output']>;
    preparedDiplomaTitle?: Maybe<Diploma>;
    previousCompanyLogo?: Maybe<OrganizationLogo>;
    previousCompanyName?: Maybe<Scalars['String']['output']>;
    previousPosition?: Maybe<Scalars['String']['output']>;
    previousPositionYearsOfExperiences?: Maybe<Scalars['Int']['output']>;
    primarySituation?: Maybe<PrimarySituation>;
    professionalId: Scalars['ID']['output'];
    profileType: UserProfile;
    publicLastName?: Maybe<Scalars['Boolean']['output']>;
    schoolName?: Maybe<Scalars['String']['output']>;
    secondarySituation?: Maybe<SecondarySituation>;
    unavailableUntil?: Maybe<Scalars['ISO8601DateTime']['output']>;
    visibilityStatus: VisibilityStatusEnum;
    yearsOfExperience?: Maybe<Scalars['Int']['output']>;
  };

export type AmbassadorSearchResult = {
  __typename?: 'AmbassadorSearchResult';
  results: Array<AmbassadorResults>;
  total: Scalars['Int']['output'];
};

export enum AmbassadorsAutocompleteQueryErrorEnum {
  /** Term is too short. Please provide a term with at least 3 characters. */
  TermTooShort = 'TERM_TOO_SHORT',
}

export type AmbivalentUser = UserProfileTypeInterface & {
  __typename?: 'AmbivalentUser';
  acceptGroupConversations: Scalars['Boolean']['output'];
  acceptedConversationTypes: Array<ConversationType>;
  accessToken?: Maybe<Scalars['String']['output']>;
  accompaniedStudyLevels?: Maybe<Array<Grade>>;
  acquiredDiplomaAcquisitionYear?: Maybe<Scalars['Int']['output']>;
  acquiredDiplomaBeginYear?: Maybe<Scalars['Int']['output']>;
  acquiredDiplomaLevel?: Maybe<DiplomaEnum>;
  acquiredDiplomaSchoolName?: Maybe<Scalars['String']['output']>;
  acquiredDiplomaTitle?: Maybe<Scalars['String']['output']>;
  ambassadorBadges: Array<Badget>;
  ambassadorPresentation?: Maybe<Scalars['String']['output']>;
  ambassadorProfileViewsCount: Scalars['Int']['output'];
  ambivalenceActivated: Scalars['Boolean']['output'];
  apprentice?: Maybe<Scalars['Boolean']['output']>;
  aspirationPresentation?: Maybe<Scalars['String']['output']>;
  aspirations: Array<Aspiration>;
  available: Scalars['Boolean']['output'];
  availableConversationTypes: Array<ConversationType>;
  availableGrades: Array<GradeEnum>;
  avatar: Avatar;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  avatars: Avatar;
  birthdate?: Maybe<Scalars['ISO8601Date']['output']>;
  bypassAvailabilityRules?: Maybe<Scalars['Boolean']['output']>;
  bypassContactLimitRules?: Maybe<Scalars['Boolean']['output']>;
  bypassInteractionRules?: Maybe<Scalars['Boolean']['output']>;
  bypassMeetingPreferencesRules?: Maybe<Scalars['Boolean']['output']>;
  canAcceptGroupConversation: Scalars['Boolean']['output'];
  canBecomeAmbassador?: Maybe<Scalars['Boolean']['output']>;
  canContact: Scalars['Boolean']['output'];
  canEditCompany: Scalars['Boolean']['output'];
  canShare: Scalars['Boolean']['output'];
  careerStartedAt?: Maybe<Scalars['ISO8601Date']['output']>;
  collectiveMeetingsAsAmbassadorCount: Scalars['Int']['output'];
  companyAdminProfiles?: Maybe<Array<CompanyAdminProfile>>;
  companyDescription?: Maybe<Scalars['String']['output']>;
  companyLogo?: Maybe<OrganizationLogo>;
  companyLogoUrl?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  companySize?: Maybe<CompanySize>;
  completionFor: ContextualCompletion;
  completionStatuses: Array<FieldCompletionStatus>;
  conversationsAsAmbassadorCount: Scalars['Int']['output'];
  conversationsAsMemberCount: Scalars['Int']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  currentGrade?: Maybe<GradeEnum>;
  currentHibernation?: Maybe<Hibernation>;
  currentScholarityEndAt?: Maybe<Scalars['ISO8601Date']['output']>;
  currentScholarityStartAt?: Maybe<Scalars['ISO8601Date']['output']>;
  currentSchoolCity?: Maybe<Scalars['String']['output']>;
  currentSchoolDepartment?: Maybe<Scalars['String']['output']>;
  currentSchoolLogo?: Maybe<OrganizationLogo>;
  currentSchoolName?: Maybe<Scalars['String']['output']>;
  currentUserAuthorizations: UserAuthorization;
  currentUserCanContact: Scalars['Boolean']['output'];
  currentUserCanContactErrors: Array<InteractionServiceErrorEnum>;
  currentUserWishedAmbassador: Scalars['Boolean']['output'];
  dontSharePhoneNumber: Scalars['Boolean']['output'];
  educations: Array<ProfileEducation>;
  email: Scalars['String']['output'];
  expertisePresentation?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  firstPublishedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  franceTravailIdentityNonce?: Maybe<Scalars['String']['output']>;
  galleryImages: Array<GalleryImage>;
  gamificationRank?: Maybe<Scalars['Float']['output']>;
  gamificationScore: Scalars['Int']['output'];
  gamificationScoreDetails: Array<GamificationScoreEntry>;
  gender?: Maybe<GenderEnum>;
  googlePlaceId?: Maybe<Scalars['String']['output']>;
  headTeacher?: Maybe<Scalars['Boolean']['output']>;
  hibernations: Array<Hibernation>;
  id: Scalars['ID']['output'];
  impactedMembersAsAmbassadorCount: Scalars['Int']['output'];
  intercomHash?: Maybe<Scalars['String']['output']>;
  interestsPresentation?: Maybe<Scalars['String']['output']>;
  isAdminRhModeratable: Scalars['Boolean']['output'];
  isAmbassador: Scalars['Boolean']['output'];
  isCoach: Scalars['Boolean']['output'];
  isInModeration: Scalars['Boolean']['output'];
  isMinor?: Maybe<Scalars['Boolean']['output']>;
  isMjgAdmin: Scalars['Boolean']['output'];
  isModeratable: Scalars['Boolean']['output'];
  isPaidAmbassador: Scalars['Boolean']['output'];
  isPremiumAmbassador: Scalars['Boolean']['output'];
  isPublishable: Scalars['Boolean']['output'];
  isUnpublishable: Scalars['Boolean']['output'];
  jobSeekerSupportOrganizationType?: Maybe<Scalars['String']['output']>;
  jobSeekerSupportPrograms?: Maybe<Array<Scalars['String']['output']>>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  languages: Array<Language>;
  lastName?: Maybe<Scalars['String']['output']>;
  likesReceivedCount: Scalars['Int']['output'];
  linkedinIdentityNonce?: Maybe<Scalars['String']['output']>;
  locatedInLaReunion: Scalars['Boolean']['output'];
  mainAdminEmail?: Maybe<Scalars['String']['output']>;
  mandatorySkills: Array<Scalars['String']['output']>;
  maxContactsPerWeek?: Maybe<Scalars['Int']['output']>;
  maxMeetingsAsAmbassadorPerMonth?: Maybe<Scalars['Int']['output']>;
  maxMeetingsPerMonth?: Maybe<Scalars['Int']['output']>;
  meetingPlace?: Maybe<Scalars['String']['output']>;
  meetingPreferences?: Maybe<Array<MeetingPreference>>;
  meetingsAsAmbassadorCount: Scalars['Int']['output'];
  meetingsAsMemberCount: Scalars['Int']['output'];
  memberBadges: Array<Badget>;
  memberPresentation?: Maybe<Scalars['String']['output']>;
  mergedAmbassadorPresentation: Scalars['String']['output'];
  messageExplanationDraft?: Maybe<Scalars['String']['output']>;
  messagePresentationDraft?: Maybe<Scalars['String']['output']>;
  mustConfirmEmail: Scalars['Boolean']['output'];
  mustConfirmTutorEmail: Scalars['Boolean']['output'];
  neverWorked?: Maybe<Scalars['Boolean']['output']>;
  newMessageCount: Scalars['Int']['output'];
  newMessagesFromAmbassadorsCount: Scalars['Int']['output'];
  newMessagesFromMembersCount: Scalars['Int']['output'];
  onlyFrenchWarning: Scalars['Boolean']['output'];
  onlyFrenchWarningConfirmText?: Maybe<Scalars['String']['output']>;
  onlyFrenchWarningText?: Maybe<Scalars['String']['output']>;
  participations: Array<Participation>;
  phoneCountryCode?: Maybe<Scalars['String']['output']>;
  phoneWithoutCountryCode?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  premiumAmbassador: Scalars['Boolean']['output'];
  premiumSchoolAmbassador: Scalars['Boolean']['output'];
  preparedDiplomaTitle?: Maybe<Scalars['String']['output']>;
  presentation?: Maybe<Scalars['String']['output']>;
  presentationTranslations: PresentationTranslations;
  previousCompanyLogo?: Maybe<OrganizationLogo>;
  previousCompanyName?: Maybe<Scalars['String']['output']>;
  previousPosition?: Maybe<Scalars['String']['output']>;
  previousPositionYearsOfExperiences?: Maybe<Scalars['Int']['output']>;
  primarySituation?: Maybe<PrimarySituation>;
  professionalId?: Maybe<Scalars['String']['output']>;
  profileBackgroundUrl?: Maybe<Scalars['String']['output']>;
  profileBannerDesktop?: Maybe<OrganizationProfileBannerDesktop>;
  profileBannerMobile?: Maybe<OrganizationProfileBannerMobile>;
  profileType: UserProfile;
  publicationStatus: PublicationStatuses;
  publishedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  recommendedProfessionals: Array<AmbassadorResults>;
  recommendedQualifications: Array<Scalars['String']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
  remainingContactsCount: Scalars['Int']['output'];
  remainingMeetingsAsAmbassadorThisMonth?: Maybe<Scalars['Int']['output']>;
  requestProfileModificationUpdate?: Maybe<Scalars['Boolean']['output']>;
  responseRateAsAmbassador?: Maybe<Scalars['Float']['output']>;
  responseRateAsMember?: Maybe<Scalars['Float']['output']>;
  responseTimeAsAmbassador?: Maybe<Scalars['Int']['output']>;
  schoolAdminProfiles?: Maybe<Array<CompanyAdminProfile>>;
  schoolDescription?: Maybe<Scalars['String']['output']>;
  secondarySituation?: Maybe<SecondarySituation>;
  seenProfilesCount: Scalars['Int']['output'];
  showCongratulationsPanel: Scalars['Boolean']['output'];
  showWelcomePanel: Scalars['Boolean']['output'];
  signedUpAsHighSchoolTeacher: Scalars['Boolean']['output'];
  signedUpAsOrianeAmbassador: Scalars['Boolean']['output'];
  skills: Array<Scalars['String']['output']>;
  studentId?: Maybe<Scalars['String']['output']>;
  studyLevels: Array<StudyLevel>;
  teachedSchoolSubjects?: Maybe<Array<Scalars['String']['output']>>;
  tutorEmail?: Maybe<Scalars['String']['output']>;
  tutorEmailConfirmedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  unacknowledgedMessagesCount: Scalars['Int']['output'];
  unavailableUntil?: Maybe<Scalars['ISO8601DateTime']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  videoconferenceBetaActivated: Scalars['Boolean']['output'];
  visibleInSearchEngine: Scalars['Boolean']['output'];
  vocationCoachSignup?: Maybe<Scalars['Boolean']['output']>;
  wantToShare?: Maybe<Scalars['Boolean']['output']>;
  wishesCount: Scalars['Int']['output'];
  wishesReceivedCount: Scalars['Int']['output'];
  yearsOfExperience?: Maybe<Scalars['Int']['output']>;
};

export type AmbivalentUserAvatarUrlArgs = {
  version?: InputMaybe<PictureVersion>;
};

export type AmbivalentUserCompletionForArgs = {
  completionContext: CompletionContextEnum;
};

/** Possible return values for antivirus */
export enum AntivirusStatus {
  /** attachment has been approved by antivirus */
  AttachmentAccepted = 'ATTACHMENT_ACCEPTED',
  /** attachment has been rejected by antivirus */
  AttachmentRejected = 'ATTACHMENT_REJECTED',
  /** scan is not finshed yet */
  ScanInProgress = 'SCAN_IN_PROGRESS',
}

export type Appointment = {
  __typename?: 'Appointment';
  ambassador: AppointmentInterlocutor;
  ambassadorReportedStatus?: Maybe<AppointmentStatus>;
  ambassadorReview?: Maybe<AppointmentReviewFromProfessional>;
  appointmentType: ConversationTypeKeyEnum;
  cancelled: Scalars['Boolean']['output'];
  conversation: Conversation;
  createdAt: Scalars['ISO8601DateTime']['output'];
  currentUserCanCancel: Scalars['Boolean']['output'];
  currentUserCanMove: Scalars['Boolean']['output'];
  currentUserCanReview: Scalars['Boolean']['output'];
  currentUserCanSnooze: Scalars['Boolean']['output'];
  currentUserInterlocutor: AppointmentInterlocutor;
  currentUserInterlocutorAspiration?: Maybe<StudentAspiration>;
  currentUserInterlocutorReviewMessage?: Maybe<Scalars['String']['output']>;
  currentUserIsAmbassador: Scalars['Boolean']['output'];
  currentUserIsMember: Scalars['Boolean']['output'];
  currentUserIsProfessional: Scalars['Boolean']['output'];
  currentUserMustReview: Scalars['Boolean']['output'];
  currentUserRecommendInterlocutor: Scalars['Boolean']['output'];
  currentUserRecommendedByInterlocutor: Scalars['Boolean']['output'];
  currentUserReviewMessage?: Maybe<Scalars['String']['output']>;
  from: Scalars['ISO8601DateTime']['output'];
  googleCalendarUrl: Scalars['String']['output'];
  googlePlaceId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  interlocutor: AppointmentInterlocutor;
  interlocutorAspiration?: Maybe<StudentAspiration>;
  interlocutorReviewMessage?: Maybe<Scalars['String']['output']>;
  meetingPlace?: Maybe<Scalars['String']['output']>;
  member: AppointmentInterlocutor;
  memberReportedStatus?: Maybe<AppointmentStatus>;
  memberReview?: Maybe<AppointmentReviewFromStudent>;
  professional?: Maybe<PublicProfessionalProfile>;
  professionalStatus?: Maybe<Scalars['String']['output']>;
  reviewFromProfessional?: Maybe<AppointmentReviewFromProfessional>;
  reviewFromStudent?: Maybe<AppointmentReviewFromStudent>;
  seeker?: Maybe<PublicStudentProfile>;
  startAt: Scalars['ISO8601DateTime']['output'];
  status?: Maybe<AppointmentStatus>;
  studentStatus?: Maybe<Scalars['String']['output']>;
  type: AppointmentTypesEnum;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  videoconferenceLink?: Maybe<Scalars['String']['output']>;
  yahooCalendarUrl: Scalars['String']['output'];
};

export enum AppointmentBookingErrorEnum {
  AmbassadorMustHaveAReply = 'AMBASSADOR_MUST_HAVE_A_REPLY',
  ConversationIsDisabled = 'CONVERSATION_IS_DISABLED',
  MustBeTheConversationRecipient = 'MUST_BE_THE_CONVERSATION_RECIPIENT',
}

/** A record of a seeker claiming that an appointment has occurred in the past. */
export type AppointmentClaim = {
  __typename?: 'AppointmentClaim';
  accepted?: Maybe<Scalars['Boolean']['output']>;
  appointment?: Maybe<Appointment>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  from: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  reason?: Maybe<Scalars['String']['output']>;
  resolvedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  timeZone: Scalars['String']['output'];
  to: Scalars['ISO8601DateTime']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for Appointment. */
export type AppointmentConnection = {
  __typename?: 'AppointmentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<AppointmentEdge>>;
  /** A list of nodes. */
  nodes: Array<Appointment>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export enum AppointmentDidNotTookPlaceReasonEnum {
  Cancelled = 'CANCELLED',
  DidNotShowUp = 'DID_NOT_SHOW_UP',
  ForgotToGo = 'FORGOT_TO_GO',
  Other = 'OTHER',
  Rescheduled = 'RESCHEDULED',
  WasNotScheduled = 'WAS_NOT_SCHEDULED',
}

/** An edge in a connection. */
export type AppointmentEdge = {
  __typename?: 'AppointmentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Appointment>;
};

export type AppointmentInterlocutor = InterlocutorInterface &
  UserProfileTypeInterface & {
    __typename?: 'AppointmentInterlocutor';
    acquiredDiplomaAcquisitionYear?: Maybe<Scalars['Int']['output']>;
    acquiredDiplomaBeginYear?: Maybe<Scalars['Int']['output']>;
    acquiredDiplomaLevel?: Maybe<Diploma>;
    acquiredDiplomaSchoolName?: Maybe<Scalars['String']['output']>;
    acquiredDiplomaTitle?: Maybe<Scalars['String']['output']>;
    ambassadorPresentation?: Maybe<Scalars['String']['output']>;
    anonymizedOrDeleted?: Maybe<Scalars['Boolean']['output']>;
    aspirationPresentation?: Maybe<Scalars['String']['output']>;
    avatar: Avatar;
    avatars: Avatar;
    banner?: Maybe<OrganizationCardBanner>;
    companyDescription?: Maybe<Scalars['String']['output']>;
    companyLogo?: Maybe<OrganizationLogo>;
    companyName?: Maybe<Scalars['String']['output']>;
    currentGrade?: Maybe<Grade>;
    currentGradeOrDiploma?: Maybe<Scalars['String']['output']>;
    currentSchoolCity?: Maybe<Scalars['String']['output']>;
    currentSchoolDepartment?: Maybe<Scalars['String']['output']>;
    currentSchoolLogo?: Maybe<OrganizationLogo>;
    currentSchoolName?: Maybe<Scalars['String']['output']>;
    email: Scalars['String']['output'];
    expertisePresentation?: Maybe<Scalars['String']['output']>;
    firstName: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    interestsPresentation?: Maybe<Scalars['String']['output']>;
    jobTitle?: Maybe<Scalars['String']['output']>;
    lastName?: Maybe<Scalars['String']['output']>;
    meetingPlace?: Maybe<Scalars['String']['output']>;
    memberPresentation?: Maybe<Scalars['String']['output']>;
    normalizedPhone?: Maybe<Scalars['String']['output']>;
    phoneNumber?: Maybe<Scalars['String']['output']>;
    position?: Maybe<Scalars['String']['output']>;
    preparedDiplomaTitle?: Maybe<Diploma>;
    previousCompanyLogo?: Maybe<OrganizationLogo>;
    previousCompanyName?: Maybe<Scalars['String']['output']>;
    previousPosition?: Maybe<Scalars['String']['output']>;
    previousPositionYearsOfExperiences?: Maybe<Scalars['Int']['output']>;
    primarySituation?: Maybe<PrimarySituation>;
    profileType: UserProfile;
    schoolName?: Maybe<Scalars['String']['output']>;
    secondarySituation?: Maybe<SecondarySituation>;
    visibilityStatus: VisibilityStatusEnum;
    yearsOfExperience?: Maybe<Scalars['Int']['output']>;
  };

/** Temporal state of an appointment */
export enum AppointmentListScope {
  /** All */
  All = 'ALL',
  /** confirmed by the requesting profile */
  ConfirmedBySelf = 'CONFIRMED_BY_SELF',
  /** Only the next one */
  Next = 'NEXT',
  /** All the pasts */
  Past = 'PAST',
  /** All the futures */
  Upcoming = 'UPCOMING',
}

/** The interface of appointment review */
export type AppointmentReview = {
  answer1?: Maybe<Scalars['Int']['output']>;
  answer2?: Maybe<Scalars['Int']['output']>;
  answer3?: Maybe<Scalars['Int']['output']>;
  appointmentStatus: AppointmentStatus;
  question1?: Maybe<Scalars['String']['output']>;
  question2?: Maybe<Scalars['String']['output']>;
  question3?: Maybe<Scalars['String']['output']>;
  reviewedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

/** An appointment review from professional */
export type AppointmentReviewFromProfessional = AppointmentReview & {
  __typename?: 'AppointmentReviewFromProfessional';
  answer1?: Maybe<Scalars['Int']['output']>;
  answer2?: Maybe<Scalars['Int']['output']>;
  answer3?: Maybe<Scalars['Int']['output']>;
  appointmentStatus: AppointmentStatus;
  message?: Maybe<Scalars['String']['output']>;
  messageForHr?: Maybe<Scalars['String']['output']>;
  messageForHrRequired: Scalars['Boolean']['output'];
  question1?: Maybe<Scalars['String']['output']>;
  question2?: Maybe<Scalars['String']['output']>;
  question2Skipable: Scalars['Boolean']['output'];
  question3?: Maybe<Scalars['String']['output']>;
  question3Skipable: Scalars['Boolean']['output'];
  reviewQuestion1: Scalars['String']['output'];
  reviewQuestion2: Scalars['String']['output'];
  reviewQuestion3: Scalars['String']['output'];
  reviewedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

/** An appointment review from professional */
export type AppointmentReviewFromProfessionalReviewQuestion1Args = {
  locale?: InputMaybe<AvailableLanguagesEnum>;
};

/** An appointment review from professional */
export type AppointmentReviewFromProfessionalReviewQuestion2Args = {
  locale?: InputMaybe<AvailableLanguagesEnum>;
};

/** An appointment review from professional */
export type AppointmentReviewFromProfessionalReviewQuestion3Args = {
  locale?: InputMaybe<AvailableLanguagesEnum>;
};

/** An appointment review from student */
export type AppointmentReviewFromStudent = AppointmentReview & {
  __typename?: 'AppointmentReviewFromStudent';
  answer1?: Maybe<Scalars['Int']['output']>;
  answer2?: Maybe<Scalars['Int']['output']>;
  answer3?: Maybe<Scalars['Int']['output']>;
  appointmentStatus: AppointmentStatus;
  aspiration?: Maybe<StudentAspiration>;
  availableWorkingAreas?: Maybe<Array<Scalars['String']['output']>>;
  canFillAspiration: Scalars['Boolean']['output'];
  canFillSchoolAspiration: Scalars['Boolean']['output'];
  companySubsidiaryDesignation?: Maybe<Scalars['String']['output']>;
  liked?: Maybe<Scalars['Boolean']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  question1?: Maybe<Scalars['String']['output']>;
  question2?: Maybe<Scalars['String']['output']>;
  question3?: Maybe<Scalars['String']['output']>;
  reviewQuestion0: Scalars['String']['output'];
  reviewQuestion1: Scalars['String']['output'];
  reviewQuestion2: Scalars['String']['output'];
  reviewQuestion3: Scalars['String']['output'];
  reviewedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  schoolAspiration?: Maybe<SchoolAspiration>;
  schoolInterestAvailableDates?: Maybe<Array<SchoolInterestAvailableDates>>;
  schoolInterestAvailableGrades?: Maybe<Array<SchoolInterestAvailableGrades>>;
  schoolInterestLevelAndYearQuestionTitle: Scalars['String']['output'];
  schoolInterestLevelQuestion: Scalars['String']['output'];
  schoolInterestWantToBeRecontactedQuestion: Scalars['String']['output'];
  schoolInterestWantToBeRecontactedTitle: Scalars['String']['output'];
  schoolInterestYearQuestion: Scalars['String']['output'];
  wantToBeRecontacted?: Maybe<Scalars['Boolean']['output']>;
};

/** An appointment review from student */
export type AppointmentReviewFromStudentCompanySubsidiaryDesignationArgs = {
  locale?: InputMaybe<AvailableLanguagesEnum>;
};

/** An appointment review from student */
export type AppointmentReviewFromStudentReviewQuestion0Args = {
  locale?: InputMaybe<AvailableLanguagesEnum>;
};

/** An appointment review from student */
export type AppointmentReviewFromStudentReviewQuestion1Args = {
  locale?: InputMaybe<AvailableLanguagesEnum>;
};

/** An appointment review from student */
export type AppointmentReviewFromStudentReviewQuestion2Args = {
  locale?: InputMaybe<AvailableLanguagesEnum>;
};

/** An appointment review from student */
export type AppointmentReviewFromStudentReviewQuestion3Args = {
  locale?: InputMaybe<AvailableLanguagesEnum>;
};

/** An appointment review from student */
export type AppointmentReviewFromStudentSchoolInterestLevelAndYearQuestionTitleArgs = {
  locale?: InputMaybe<AvailableLanguagesEnum>;
};

/** An appointment review from student */
export type AppointmentReviewFromStudentSchoolInterestLevelQuestionArgs = {
  locale?: InputMaybe<AvailableLanguagesEnum>;
};

/** An appointment review from student */
export type AppointmentReviewFromStudentSchoolInterestWantToBeRecontactedQuestionArgs = {
  locale?: InputMaybe<AvailableLanguagesEnum>;
};

/** An appointment review from student */
export type AppointmentReviewFromStudentSchoolInterestWantToBeRecontactedTitleArgs = {
  locale?: InputMaybe<AvailableLanguagesEnum>;
};

/** An appointment review from student */
export type AppointmentReviewFromStudentSchoolInterestYearQuestionArgs = {
  locale?: InputMaybe<AvailableLanguagesEnum>;
};

/** status of an appointment from an individual point of view */
export enum AppointmentStatus {
  /** translation missing: en.graphql.schema.enums.appointment_status.awaiting_review */
  AwaitingReview = 'AWAITING_REVIEW',
  /** translation missing: en.graphql.schema.enums.appointment_status.both_forgot_to_go */
  BothForgotToGo = 'BOTH_FORGOT_TO_GO',
  /** translation missing: en.graphql.schema.enums.appointment_status.cancelled */
  Cancelled = 'CANCELLED',
  /** translation missing: en.graphql.schema.enums.appointment_status.conflict */
  Conflict = 'CONFLICT',
  /** translation missing: en.graphql.schema.enums.appointment_status.did_not_show_up */
  DidNotShowUp = 'DID_NOT_SHOW_UP',
  /** translation missing: en.graphql.schema.enums.appointment_status.forgot_to_go */
  ForgotToGo = 'FORGOT_TO_GO',
  /** translation missing: en.graphql.schema.enums.appointment_status.other */
  Other = 'OTHER',
  /** translation missing: en.graphql.schema.enums.appointment_status.rescheduled */
  Rescheduled = 'RESCHEDULED',
  /** translation missing: en.graphql.schema.enums.appointment_status.stood_up_by_professional */
  StoodUpByProfessional = 'STOOD_UP_BY_PROFESSIONAL',
  /** translation missing: en.graphql.schema.enums.appointment_status.stood_up_by_student */
  StoodUpByStudent = 'STOOD_UP_BY_STUDENT',
  /** translation missing: en.graphql.schema.enums.appointment_status.took_place */
  TookPlace = 'TOOK_PLACE',
  /** translation missing: en.graphql.schema.enums.appointment_status.unbooked */
  Unbooked = 'UNBOOKED',
  /** translation missing: en.graphql.schema.enums.appointment_status.upcoming */
  Upcoming = 'UPCOMING',
  /** translation missing: en.graphql.schema.enums.appointment_status.was_not_scheduled */
  WasNotScheduled = 'WAS_NOT_SCHEDULED',
}

/** Appointments meeting types */
export enum AppointmentTypesEnum {
  Phone = 'PHONE',
  Physical = 'PHYSICAL',
  Video = 'VIDEO',
}

/** List of filters for appointments query */
export type AppointmentsFilter = {
  cancelled?: InputMaybe<Scalars['Boolean']['input']>;
  conversationIdEq?: InputMaybe<Scalars['ID']['input']>;
  currentUserCanReview?: InputMaybe<Scalars['Boolean']['input']>;
  fromGt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  fromLt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  idEq?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idNin?: InputMaybe<Array<Scalars['ID']['input']>>;
  past?: InputMaybe<Scalars['Boolean']['input']>;
  seekerIdEq?: InputMaybe<Scalars['ID']['input']>;
  seekerIdIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  startAtGt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  startAtLt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
};

/** List of sorts for appointments query (titi toto tata) */
export enum AppointmentsSortFieldsEnum {
  FromAsc = 'FROM_ASC',
  FromDesc = 'FROM_DESC',
  StartAtAsc = 'START_AT_ASC',
  StartAtDesc = 'START_AT_DESC',
}

export type Aspiration = {
  __typename?: 'Aspiration';
  description: Scalars['String']['output'];
  duration: InternshipDuration;
  id: Scalars['ID']['output'];
  startsAtMonth: Scalars['Int']['output'];
  startsAtYear: Scalars['Int']['output'];
  status: InternshipStatus;
  type: InternshipTypeEnum;
  user: AmbivalentUser;
};

export type AspirationsInput = {
  duration: InternshipDuration;
  franceLevel1AdministrativeAreas?: InputMaybe<Array<FranceLevel1AdministrativeAreasEnum>>;
  internshipType: InternshipTypeEnum;
  postalCode: Scalars['String']['input'];
  startAt: Scalars['ISO8601Date']['input'];
  workingAreas?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** An assignment participant */
export type AssignmentParticipant = {
  __typename?: 'AssignmentParticipant';
  affiliatedAt: Scalars['ISO8601DateTime']['output'];
  appointmentWithFeedbackCount?: Maybe<Scalars['Int']['output']>;
  avatar?: Maybe<Scalars['AvatarUrl']['output']>;
  conversationCount?: Maybe<Scalars['Int']['output']>;
  conversationWithoutReplyFromStudentCount?: Maybe<Scalars['Int']['output']>;
  firstAppointmentAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  firstConversationAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  pendingAppointmentCount?: Maybe<Scalars['Int']['output']>;
  status: StudentStatus;
};

/** An assignment participant */
export type AssignmentParticipantAvatarArgs = {
  version?: InputMaybe<PictureVersion>;
};

/** Temporal state of an assignment */
export enum AssignmentsListScope {
  /** The current one */
  Current = 'CURRENT',
  /** All the pasts */
  Past = 'PAST',
  /** All the futures */
  Upcoming = 'UPCOMING',
}

export type AttrChange = {
  __typename?: 'AttrChange';
  attr: Scalars['String']['output'];
  from?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['String']['output']>;
};

/** List of all available locals for translated fields */
export enum AvailableLanguagesEnum {
  En = 'EN',
  Fr = 'FR',
}

export type Avatar = {
  __typename?: 'Avatar';
  small: Image;
  thumb: Image;
  url?: Maybe<Scalars['String']['output']>;
};

export enum BadgeEnum {
  AccountCreated = 'ACCOUNT_CREATED',
  AppointmentNo_1Occurred = 'APPOINTMENT_NO_1_OCCURRED',
  AppointmentNo_1Reviewed = 'APPOINTMENT_NO_1_REVIEWED',
  AppointmentNo_3Occurred = 'APPOINTMENT_NO_3_OCCURRED',
  AppointmentNo_5Occurred = 'APPOINTMENT_NO_5_OCCURRED',
  AppointmentNo_10Occurred = 'APPOINTMENT_NO_10_OCCURRED',
  AppointmentNo_15Occurred = 'APPOINTMENT_NO_15_OCCURRED',
  AppointmentNo_20Occurred = 'APPOINTMENT_NO_20_OCCURRED',
  AppointmentNo_30Occurred = 'APPOINTMENT_NO_30_OCCURRED',
  AppointmentNo_50Occurred = 'APPOINTMENT_NO_50_OCCURRED',
  AppointmentNo_75Occurred = 'APPOINTMENT_NO_75_OCCURRED',
  AppointmentNo_100Occurred = 'APPOINTMENT_NO_100_OCCURRED',
  AppointmentNo_150Occurred = 'APPOINTMENT_NO_150_OCCURRED',
  AppointmentNo_200Occurred = 'APPOINTMENT_NO_200_OCCURRED',
  AppointmentNo_300Occurred = 'APPOINTMENT_NO_300_OCCURRED',
  AppointmentNo_500Occurred = 'APPOINTMENT_NO_500_OCCURRED',
  FifteenthAppointmentOccurred = 'FIFTEENTH_APPOINTMENT_OCCURRED',
  FifthAppointmentOccurred = 'FIFTH_APPOINTMENT_OCCURRED',
  FiftiethAppointmentOccurred = 'FIFTIETH_APPOINTMENT_OCCURRED',
  FirstAppointmentOccurred = 'FIRST_APPOINTMENT_OCCURRED',
  FirstAppointmentReview = 'FIRST_APPOINTMENT_REVIEW',
  FirstConversationReceived = 'FIRST_CONVERSATION_RECEIVED',
  FirstConversationReplied = 'FIRST_CONVERSATION_REPLIED',
  FirstMessageSent = 'FIRST_MESSAGE_SENT',
  HundredthAppointmentOccurred = 'HUNDREDTH_APPOINTMENT_OCCURRED',
  ProfileComplete = 'PROFILE_COMPLETE',
  ProfileCompletedForTheFirstTime = 'PROFILE_COMPLETED_FOR_THE_FIRST_TIME',
  ProfilePublishedForTheFirstTime = 'PROFILE_PUBLISHED_FOR_THE_FIRST_TIME',
  Registration = 'REGISTRATION',
  SeventyFifthAppointmentOccurred = 'SEVENTY_FIFTH_APPOINTMENT_OCCURRED',
  TenthAppointmentOccurred = 'TENTH_APPOINTMENT_OCCURRED',
  ThirdAppointmentOccurred = 'THIRD_APPOINTMENT_OCCURRED',
  ThirthiethAppointmentOccurred = 'THIRTHIETH_APPOINTMENT_OCCURRED',
  TwentiethAppointmentOccurred = 'TWENTIETH_APPOINTMENT_OCCURRED',
}

export type Badget = {
  __typename?: 'Badget';
  completed: Scalars['Boolean']['output'];
  name: BadgeEnum;
};

export type Bold = {
  __typename?: 'Bold';
  length: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
};

export enum BookAppointmentMutationErrorEnum {
  AmbassadorMustHaveAReply = 'AMBASSADOR_MUST_HAVE_A_REPLY',
  AppointmentAlreadyScheduled = 'APPOINTMENT_ALREADY_SCHEDULED',
  BookAppointmentUnauthorized = 'BOOK_APPOINTMENT_UNAUTHORIZED',
  ConversationIsDisabled = 'CONVERSATION_IS_DISABLED',
  MeetingPlaceRequiredForPhysicalAppointment = 'MEETING_PLACE_REQUIRED_FOR_PHYSICAL_APPOINTMENT',
  MustBeTheConversationRecipient = 'MUST_BE_THE_CONVERSATION_RECIPIENT',
  MustHaveConversationTogether = 'MUST_HAVE_CONVERSATION_TOGETHER',
  NotFound = 'NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED',
  UnexpectedValidationErrors = 'UNEXPECTED_VALIDATION_ERRORS',
}

export type Bucket = {
  __typename?: 'Bucket';
  docCount?: Maybe<Scalars['Int']['output']>;
  key: Scalars['String']['output'];
  label?: Maybe<Scalars['String']['output']>;
};

export type Change = {
  __typename?: 'Change';
  changes: Array<AttrChange>;
  employee: CompanyEmployee;
};

export type CheckEmailAvailabilityQueryPayload = {
  __typename?: 'CheckEmailAvailabilityQueryPayload';
  available: Scalars['Boolean']['output'];
  hasFranceTravailIdentity: Scalars['Boolean']['output'];
  hasLinkedinIdentity: Scalars['Boolean']['output'];
  hasPassword: Scalars['Boolean']['output'];
  isConfirmed: Scalars['Boolean']['output'];
  softDeleted: Scalars['Boolean']['output'];
};

export enum ClaimAppointmentMutationErrorEnum {
  AlreadyExists = 'ALREADY_EXISTS',
  AmbassadorMustHaveAReply = 'AMBASSADOR_MUST_HAVE_A_REPLY',
  AmbassadorSoftDeleted = 'AMBASSADOR_SOFT_DELETED',
  ClaimAppointmentUnauthorized = 'CLAIM_APPOINTMENT_UNAUTHORIZED',
  ClaimInvalid = 'CLAIM_INVALID',
  ConversationIsDisabled = 'CONVERSATION_IS_DISABLED',
  InitiatorSoftDeleted = 'INITIATOR_SOFT_DELETED',
  MeetingPlaceRequired = 'MEETING_PLACE_REQUIRED',
  MustInitiateAConversation = 'MUST_INITIATE_A_CONVERSATION',
  NotFound = 'NOT_FOUND',
  StartedAtMustBePast = 'STARTED_AT_MUST_BE_PAST',
  Unauthorized = 'UNAUTHORIZED',
  UnexpectedValidationErrors = 'UNEXPECTED_VALIDATION_ERRORS',
}

export enum ClaimCertificateErrorEnum {
  /** Current user cannot claim certificate */
  CannotClaimCertificate = 'CANNOT_CLAIM_CERTIFICATE',
  /** Not found */
  NotFound = 'NOT_FOUND',
  /** Unauthorized / Unauthenticated */
  Unauthorized = 'UNAUTHORIZED',
}

/** A coach assignment */
export type CoachAssignment = {
  __typename?: 'CoachAssignment';
  affiliatedStudentCount: Scalars['Int']['output'];
  campusName: Scalars['String']['output'];
  dataRecalculatedAt: Scalars['ISO8601DateTime']['output'];
  diplomaLevel: DiplomaLevel;
  endsAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  participants: Array<AssignmentParticipant>;
  schoolName: Scalars['String']['output'];
  size: Scalars['Int']['output'];
  startsAt: Scalars['ISO8601DateTime']['output'];
  studentWithAppointmentWithFeedbackCount?: Maybe<Scalars['Int']['output']>;
  studentWithConversationCount?: Maybe<Scalars['Int']['output']>;
};

export type CollaborationUser = {
  __typename?: 'CollaborationUser';
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
};

export type ComesFrom = {
  __typename?: 'ComesFrom';
  key: ComesFromKeyEnum;
  name: Scalars['String']['output'];
};

export type ComesFromNameArgs = {
  locale?: InputMaybe<AvailableLanguagesEnum>;
};

/** Comes From Enum */
export enum ComesFromEnum {
  Event = 'EVENT',
  FranceTravail = 'FRANCE_TRAVAIL',
  Media = 'MEDIA',
  Other = 'OTHER',
  SearchEngine = 'SEARCH_ENGINE',
  SocialNetwork = 'SOCIAL_NETWORK',
  Webinar = 'WEBINAR',
  WordOfMouth = 'WORD_OF_MOUTH',
  YourCompany = 'YOUR_COMPANY',
  YourSchool = 'YOUR_SCHOOL',
}

export enum ComesFromKeyEnum {
  Event = 'EVENT',
  FranceTravail = 'FRANCE_TRAVAIL',
  Media = 'MEDIA',
  Other = 'OTHER',
  SearchEngine = 'SEARCH_ENGINE',
  SocialNetwork = 'SOCIAL_NETWORK',
  Webinar = 'WEBINAR',
  WordOfMouth = 'WORD_OF_MOUTH',
  YourCompany = 'YOUR_COMPANY',
  YourSchool = 'YOUR_SCHOOL',
}

/** Available language in email and communication */
export enum CommunicationLanguage {
  /** English */
  English = 'ENGLISH',
  /** French */
  French = 'FRENCH',
}

/** A company */
export type Company = {
  __typename?: 'Company';
  activeProfilePercentage: Scalars['Percentage']['output'];
  companyLogo?: Maybe<Scalars['LogoUrl']['output']>;
  companySector?: Maybe<Scalars['String']['output']>;
  companySize?: Maybe<CompanySize>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  crmActivation: Scalars['Boolean']['output'];
  customAttributesSet: CustomAttributesSet;
  description?: Maybe<Scalars['String']['output']>;
  employeePackSize: Scalars['Int']['output'];
  employeesAvailablePlacesCount: Scalars['Int']['output'];
  employeesCustomAttribute1Distribution?: Maybe<CustomAttributeDistribution>;
  employeesCustomAttribute2Distribution?: Maybe<CustomAttributeDistribution>;
  employeesCustomAttribute3Distribution?: Maybe<CustomAttributeDistribution>;
  employeesIcedUpCount: Scalars['Int']['output'];
  employeesInCrisisCount: Scalars['Int']['output'];
  employeesVisibleCount: Scalars['Int']['output'];
  establishmentType?: Maybe<SecondarySituation>;
  id: Scalars['ID']['output'];
  identifier?: Maybe<Scalars['String']['output']>;
  isClient: Scalars['Boolean']['output'];
  isPremiumSchool: Scalars['Boolean']['output'];
  lastReportGeneratedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  name: Scalars['String']['output'];
  publishedEmployeesCount: Scalars['Int']['output'];
  recruitingWorkingAreas: Array<Scalars['String']['output']>;
};

/** A company admin profile */
export type CompanyAdminProfile = {
  __typename?: 'CompanyAdminProfile';
  company: Company;
  id: Scalars['ID']['output'];
};

export enum CompanyAppointmentsQueryErrorEnum {
  NotFound = 'NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED',
}

/** A company employee */
export type CompanyEmployee = {
  __typename?: 'CompanyEmployee';
  ambivalent: Scalars['Boolean']['output'];
  avatar?: Maybe<Scalars['AvatarUrl']['output']>;
  canApprove: Scalars['Boolean']['output'];
  canArchive: Scalars['Boolean']['output'];
  canPublish: Scalars['Boolean']['output'];
  canReceiveInvitationReminder: Scalars['Boolean']['output'];
  canUnpublish: Scalars['Boolean']['output'];
  companyAttribute1?: Maybe<Scalars['String']['output']>;
  companyAttribute2?: Maybe<Scalars['String']['output']>;
  companyAttribute3?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  jobTitle?: Maybe<Scalars['String']['output']>;
  lastInvitationEmailSentAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  lastName: Scalars['String']['output'];
  preparedDiplomaTitle?: Maybe<Scalars['String']['output']>;
  status: EmployeeStatus;
  userId?: Maybe<Scalars['ID']['output']>;
  userMetTag?: Maybe<UserMetTag>;
  visibleInSearch?: Maybe<Scalars['Boolean']['output']>;
};

/** A company employee */
export type CompanyEmployeeAvatarArgs = {
  version?: InputMaybe<PictureVersion>;
};

/** A company sector */
export type CompanyEmployeeAppointment = {
  __typename?: 'CompanyEmployeeAppointment';
  from: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  member: AppointmentInterlocutor;
  professional?: Maybe<CompanyEmployee>;
  reviewFromProfessional: AppointmentReviewFromProfessional;
  reviewFromStudent: AppointmentReviewFromStudent;
};

export type CompanyEmployeeAppointmentsPayload = {
  __typename?: 'CompanyEmployeeAppointmentsPayload';
  appointments: Array<CompanyEmployeeAppointment>;
  totalCount: Scalars['Int']['output'];
};

/** The connection type for CompanyEmployee. */
export type CompanyEmployeeConnection = {
  __typename?: 'CompanyEmployeeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<CompanyEmployeeEdge>>;
  /** A list of nodes. */
  nodes: Array<CompanyEmployee>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CompanyEmployeeEdge = {
  __typename?: 'CompanyEmployeeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<CompanyEmployee>;
};

export enum CompanyEmployeesQueryErrorEnum {
  NotFound = 'NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED',
}

export type CompanyEmployeesQueryFiltersCustomAttributeInput = {
  key: Scalars['String']['input'];
  values: Array<Scalars['String']['input']>;
};

export enum CompanyEmployeesQueryFiltersHrTableStatusEnum {
  Active = 'ACTIVE',
  Approved = 'APPROVED',
  AtRisk = 'AT_RISK',
  CompleteProfile = 'COMPLETE_PROFILE',
  Hibernated = 'HIBERNATED',
  Inactive = 'INACTIVE',
  IncompleteProfile = 'INCOMPLETE_PROFILE',
  Invited = 'INVITED',
  InvitedMultipleTimes = 'INVITED_MULTIPLE_TIMES',
  Unpublished = 'UNPUBLISHED',
}

export enum CompanyEmployeesQuerySortEnum {
  FirstNameAsc = 'FIRST_NAME_ASC',
  FirstNameDesc = 'FIRST_NAME_DESC',
  LastNameAsc = 'LAST_NAME_ASC',
  LastNameDesc = 'LAST_NAME_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
}

/** A company employee in the context of a company report */
export type CompanyReport = {
  __typename?: 'CompanyReport';
  appointmentCount: Scalars['Int']['output'];
  bestProfiles: Array<CompanyReportTopEmployee>;
  company: Company;
  companyId: Scalars['ID']['output'];
  contactedEmployeesCount: Scalars['Int']['output'];
  employeesViewCount: Scalars['Int']['output'];
  from: Scalars['ISO8601DateTime']['output'];
  isPremiumSchool: Scalars['Boolean']['output'];
  lastUpdatedAt: Scalars['ISO8601DateTime']['output'];
  likeCount: Scalars['Int']['output'];
  newConversationCount: Scalars['Int']['output'];
  publishedEmployeeCount: Scalars['Int']['output'];
  publishedEmployeesWithAppointmentRate: Scalars['Percentage']['output'];
  responseRate: Scalars['Percentage']['output'];
  studentsSeeingThemselvesInTheSectorRate: Scalars['Percentage']['output'];
  to: Scalars['ISO8601DateTime']['output'];
  unfollowedConversationsCount: Scalars['Int']['output'];
  uniqueProfileViewsFromStudents: Scalars['Int']['output'];
  wishCount: Scalars['Int']['output'];
};

/** A company employee in the context of a company report */
export type CompanyReportBestProfilesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};

/** All the company sizes */
export enum CompanyReportRange {
  /** from the beginning until now */
  FromTheBeginning = 'FROM_THE_BEGINNING',
  /** from the beginning of last month until the end of last month */
  LastMonth = 'LAST_MONTH',
  /** from the beginning of the month until now */
  ThisMonth = 'THIS_MONTH',
}

/** A company employee in the context of a company report */
export type CompanyReportTopEmployee = {
  __typename?: 'CompanyReportTopEmployee';
  appointmentCount: Scalars['Int']['output'];
  avatar?: Maybe<Scalars['AvatarUrl']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  jobTitle: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  medal: Scalars['Int']['output'];
};

/** A company employee in the context of a company report */
export type CompanyReportTopEmployeeAvatarArgs = {
  version?: InputMaybe<PictureVersion>;
};

/** A company sector */
export type CompanySector = {
  __typename?: 'CompanySector';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

/** All the company sizes */
export enum CompanySize {
  /** One employee */
  OnlyOne = 'ONLY_ONE',
  /** Over 10 000 employees */
  Over_10000 = 'OVER_10000',
  /** Between 1 and 10 employees */
  Under_10 = 'UNDER_10',
  /** Between 10 and 50 employees */
  Under_50 = 'UNDER_50',
  /** Between 50 and 200 employees */
  Under_200 = 'UNDER_200',
  /** Between 200 and 500 employees */
  Under_500 = 'UNDER_500',
  /** Between 500 and 1 000 employees */
  Under_1000 = 'UNDER_1000',
  /** Between 1 000 and 5 000 employees */
  Under_5000 = 'UNDER_5000',
  /** Between 5 000 and 10 000 employees */
  Under_10000 = 'UNDER_10000',
  /** Unknown number of employees */
  Unknown = 'UNKNOWN',
}

export type CompenyEmployeesQueryFilter = {
  archivedEq?: InputMaybe<Scalars['Boolean']['input']>;
  companyAttributeIn?: InputMaybe<Array<CompanyEmployeesQueryFiltersCustomAttributeInput>>;
  hrTableStatusIn?: InputMaybe<Array<CompanyEmployeesQueryFiltersHrTableStatusEnum>>;
  q?: InputMaybe<Scalars['String']['input']>;
};

export enum CompletionContextEnum {
  ContactAmbassador = 'CONTACT_AMBASSADOR',
  Edition = 'EDITION',
  Publication = 'PUBLICATION',
  RequestPublication = 'REQUEST_PUBLICATION',
  StepByStep = 'STEP_BY_STEP',
}

export enum ConfirmEmailErrorEnum {
  /** Email is already confirmed */
  AlreadyConfirmed = 'ALREADY_CONFIRMED',
  /** __Signin__: Needs email confirmation */
  EmailUnconfirmed = 'EMAIL_UNCONFIRMED',
  /** User not found */
  NotFound = 'NOT_FOUND',
  /** __Signin__: Needs tutor email confirmation */
  TutorEmailUnconfirmed = 'TUTOR_EMAIL_UNCONFIRMED',
  /** __Signin__: User account is restricted */
  UserAccountRestricted = 'USER_ACCOUNT_RESTRICTED',
  /** __Signin__: User is soft deleted */
  UserSoftDeleted = 'USER_SOFT_DELETED',
}

export enum ConfirmTutorEmailErrorEnum {
  /** Tutor email is already confirmed */
  AlreadyConfirmed = 'ALREADY_CONFIRMED',
  /** User not found */
  NotFound = 'NOT_FOUND',
}

export type ContextualCompletion = {
  __typename?: 'ContextualCompletion';
  complete: Scalars['Boolean']['output'];
  fieldsCompletion: Array<FieldCompletionStatus>;
  fieldsTotalPoints: Scalars['Int']['output'];
  percentage: Scalars['Int']['output'];
  userTotalPoints: Scalars['Int']['output'];
};

export type Conversation = {
  __typename?: 'Conversation';
  actionRequired: Scalars['Boolean']['output'];
  appointmentClaims: Array<AppointmentClaim>;
  conversationType: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  currentUserAcknowledged: Scalars['Boolean']['output'];
  currentUserAnswered: Scalars['Boolean']['output'];
  currentUserCanBookAppointment: Scalars['Boolean']['output'];
  currentUserCanBookAppointmentErrors: Array<AppointmentBookingErrorEnum>;
  currentUserIsInitiator: Scalars['Boolean']['output'];
  currentUserUnreadMessagesCount: Scalars['Int']['output'];
  disabled: Scalars['Boolean']['output'];
  groupSession?: Maybe<GroupSession>;
  id: Scalars['ID']['output'];
  initiator: AmbivalentUser;
  interlocutor?: Maybe<AmbivalentUser>;
  interlocutorV2?: Maybe<ConversationInterlocutor>;
  lastMessage?: Maybe<Message>;
  messages: Array<Message>;
  professional?: Maybe<PublicProfessionalProfile>;
  recipient: AmbivalentUser;
  student?: Maybe<PublicStudentProfile>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for Conversation. */
export type ConversationConnection = {
  __typename?: 'ConversationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ConversationEdge>>;
  /** A list of nodes. */
  nodes: Array<Conversation>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ConversationEdge = {
  __typename?: 'ConversationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Conversation>;
};

export type ConversationInterlocutor = InterlocutorInterface &
  UserProfileTypeInterface & {
    __typename?: 'ConversationInterlocutor';
    acquiredDiplomaAcquisitionYear?: Maybe<Scalars['Int']['output']>;
    acquiredDiplomaBeginYear?: Maybe<Scalars['Int']['output']>;
    acquiredDiplomaLevel?: Maybe<Diploma>;
    acquiredDiplomaSchoolName?: Maybe<Scalars['String']['output']>;
    acquiredDiplomaTitle?: Maybe<Scalars['String']['output']>;
    ambassadorPresentation?: Maybe<Scalars['String']['output']>;
    anonymizedOrDeleted?: Maybe<Scalars['Boolean']['output']>;
    aspirationPresentation?: Maybe<Scalars['String']['output']>;
    avatar: Avatar;
    avatars: Avatar;
    banner?: Maybe<OrganizationCardBanner>;
    companyDescription?: Maybe<Scalars['String']['output']>;
    companyLogo?: Maybe<OrganizationLogo>;
    companyName?: Maybe<Scalars['String']['output']>;
    currentGrade?: Maybe<Grade>;
    currentGradeOrDiploma?: Maybe<Scalars['String']['output']>;
    currentSchoolCity?: Maybe<Scalars['String']['output']>;
    currentSchoolDepartment?: Maybe<Scalars['String']['output']>;
    currentSchoolLogo?: Maybe<OrganizationLogo>;
    currentSchoolName?: Maybe<Scalars['String']['output']>;
    email: Scalars['String']['output'];
    expertisePresentation?: Maybe<Scalars['String']['output']>;
    firstName: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    interestsPresentation?: Maybe<Scalars['String']['output']>;
    jobTitle?: Maybe<Scalars['String']['output']>;
    lastName?: Maybe<Scalars['String']['output']>;
    meetingPlace?: Maybe<Scalars['String']['output']>;
    memberPresentation?: Maybe<Scalars['String']['output']>;
    normalizedPhone?: Maybe<Scalars['String']['output']>;
    phoneNumber?: Maybe<Scalars['String']['output']>;
    position?: Maybe<Scalars['String']['output']>;
    preparedDiplomaTitle?: Maybe<Diploma>;
    previousCompanyLogo?: Maybe<OrganizationLogo>;
    previousCompanyName?: Maybe<Scalars['String']['output']>;
    previousPosition?: Maybe<Scalars['String']['output']>;
    previousPositionYearsOfExperiences?: Maybe<Scalars['Int']['output']>;
    primarySituation?: Maybe<PrimarySituation>;
    profileType: UserProfile;
    schoolName?: Maybe<Scalars['String']['output']>;
    secondarySituation?: Maybe<SecondarySituation>;
    visibilityStatus: VisibilityStatusEnum;
    yearsOfExperience?: Maybe<Scalars['Int']['output']>;
  };

export type ConversationType = {
  __typename?: 'ConversationType';
  key: ConversationTypeKeyEnum;
  name: Scalars['String']['output'];
  value?: Maybe<Scalars['Boolean']['output']>;
};

export type ConversationTypeNameArgs = {
  locale?: InputMaybe<AvailableLanguagesEnum>;
};

export enum ConversationTypeKeyEnum {
  ClassGroup = 'CLASS_GROUP',
  Individual = 'INDIVIDUAL',
  ProgramGroup = 'PROGRAM_GROUP',
}

/** List of filters for conversations query */
export type ConversationsFilter = {
  createdAtGt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  createdAtLt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  currentUserAcknowledged?: InputMaybe<Scalars['Boolean']['input']>;
  currentUserActionNeeded?: InputMaybe<Scalars['Boolean']['input']>;
  currentUserIsInitiator?: InputMaybe<Scalars['Boolean']['input']>;
  currentUserNotAnswered?: InputMaybe<Scalars['Boolean']['input']>;
  excludeAnswered?: InputMaybe<Scalars['Boolean']['input']>;
  excludeInitiated?: InputMaybe<Scalars['Boolean']['input']>;
  excludeReceived?: InputMaybe<Scalars['Boolean']['input']>;
  excludeSeen?: InputMaybe<Scalars['Boolean']['input']>;
  idEq?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idNin?: InputMaybe<Array<Scalars['ID']['input']>>;
  initiatorIdEq?: InputMaybe<Scalars['ID']['input']>;
  initiatorIdIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  updatedAtGt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  updatedAtLt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
};

export enum CreateConversationMutationErrorEnum {
  AmbassadorMeetingPreferencesPreventsContact = 'AMBASSADOR_MEETING_PREFERENCES_PREVENTS_CONTACT',
  AmbassadorUnavailable = 'AMBASSADOR_UNAVAILABLE',
  ContactUnauthorized = 'CONTACT_UNAUTHORIZED',
  ConversationAlreadyExists = 'CONVERSATION_ALREADY_EXISTS',
  ConversationStartFailed = 'CONVERSATION_START_FAILED',
  GroupSessionCreationFailed = 'GROUP_SESSION_CREATION_FAILED',
  MemberProfileNotComplete = 'MEMBER_PROFILE_NOT_COMPLETE',
  MessageCannotBeBlank = 'MESSAGE_CANNOT_BE_BLANK',
  NotFound = 'NOT_FOUND',
  NoRemainingContact = 'NO_REMAINING_CONTACT',
  ParticipantsCountCannotBeBlank = 'PARTICIPANTS_COUNT_CANNOT_BE_BLANK',
  Unauthorized = 'UNAUTHORIZED',
  UsersMustBeDifferent = 'USERS_MUST_BE_DIFFERENT',
  UserInteractionGroupPreventsContact = 'USER_INTERACTION_GROUP_PREVENTS_CONTACT',
}

export enum CreateGouvSchoolMutationErrorEnum {
  NotFound = 'NOT_FOUND',
}

export enum CreateHibernationMutationErrorEnum {
  /** Hibernation exceed the 62 days limit */
  HibernationTooBroad = 'HIBERNATION_TOO_BROAD',
  /** Ambassador account is restricted and cannot hibernate (iced_up) */
  IcedUpAmbassadorCannotHibernate = 'ICED_UP_AMBASSADOR_CANNOT_HIBERNATE',
  /** Only ambassador can hibernate */
  OnlyAmbassadorCanHibernate = 'ONLY_AMBASSADOR_CAN_HIBERNATE',
  /** Two hibernations overlaps */
  OverlappingHibernations = 'OVERLAPPING_HIBERNATIONS',
  /** Ambassador account is restricted and cannot hibernate (restricted) */
  RestrictedAmbassadorCannotHibernate = 'RESTRICTED_AMBASSADOR_CANNOT_HIBERNATE',
  /** Start at must be before start at */
  StartAtMustBeBeforeEndAt = 'START_AT_MUST_BE_BEFORE_END_AT',
  /** Start at must be in the future */
  StartAtMustBeFuture = 'START_AT_MUST_BE_FUTURE',
  /** Unauthenticated */
  Unauthenticated = 'UNAUTHENTICATED',
  /** Ambassador account is unpublished and cannot hibernate */
  UnpublishedAmbassadorCannotHibernate = 'UNPUBLISHED_AMBASSADOR_CANNOT_HIBERNATE',
}

export enum CreateMessageTemplateMutationErrorEnum {
  MessageTemplateInvalid = 'MESSAGE_TEMPLATE_INVALID',
  NotFound = 'NOT_FOUND',
  TitleBlank = 'TITLE_BLANK',
  TitleTooShort = 'TITLE_TOO_SHORT',
  Unauthorized = 'UNAUTHORIZED',
}

export type CustomAttributeDistribution = {
  __typename?: 'CustomAttributeDistribution';
  distribution: Array<CustomAttributeDistributionItem>;
  name: Scalars['String']['output'];
  total: Scalars['Int']['output'];
};

export type CustomAttributeDistributionItem = {
  __typename?: 'CustomAttributeDistributionItem';
  count: Scalars['Int']['output'];
  key: Scalars['String']['output'];
  percentage: Scalars['Float']['output'];
};

/** A set that contains all the custom employee attributes of a company */
export type CustomAttributesSet = {
  __typename?: 'CustomAttributesSet';
  companyAttributeValues1: Array<Scalars['String']['output']>;
  companyAttributeValues2: Array<Scalars['String']['output']>;
  companyAttributeValues3: Array<Scalars['String']['output']>;
  customEmployeeAttribute1?: Maybe<Scalars['String']['output']>;
  customEmployeeAttribute1NormalizedName?: Maybe<Scalars['String']['output']>;
  customEmployeeAttribute2?: Maybe<Scalars['String']['output']>;
  customEmployeeAttribute2NormalizedName?: Maybe<Scalars['String']['output']>;
  customEmployeeAttribute3?: Maybe<Scalars['String']['output']>;
  customEmployeeAttribute3NormalizedName?: Maybe<Scalars['String']['output']>;
};

export type DefaultMessageTemplate = {
  __typename?: 'DefaultMessageTemplate';
  body: Scalars['String']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type DefaultMessageTemplateBodyArgs = {
  locale?: InputMaybe<AvailableLanguagesEnum>;
};

export type DefaultMessageTemplateTitleArgs = {
  locale?: InputMaybe<AvailableLanguagesEnum>;
};

export enum DefaultMessageTemplatesQueryErrorEnum {
  NotFound = 'NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED',
}

export enum DestroyHibernationMutationErrorEnum {
  /** Hibernation 'effectiveWakeAt' is already defined and past */
  CannotDestroyWakedHibernation = 'CANNOT_DESTROY_WAKED_HIBERNATION',
  /** Hibernation is not found or belongs to another user */
  NotFound = 'NOT_FOUND',
  /** Unauthenticated */
  Unauthenticated = 'UNAUTHENTICATED',
}

export enum DestroyMessageTemplateMutationErrorEnum {
  NotFound = 'NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED',
}

export type Diploma = {
  __typename?: 'Diploma';
  key: DiplomaKeyEnum;
  name: Scalars['String']['output'];
};

export type DiplomaNameArgs = {
  locale?: InputMaybe<AvailableLanguagesEnum>;
};

export enum DiplomaEnum {
  Bac_2 = 'BAC_2',
  Bac_3 = 'BAC_3',
  Bac_4 = 'BAC_4',
  Bac_5 = 'BAC_5',
  BacGt = 'BAC_GT',
  BacPro = 'BAC_PRO',
  Bep = 'BEP',
  Brevet = 'BREVET',
  Cap = 'CAP',
  Cep = 'CEP',
  Doctorat = 'DOCTORAT',
  None = 'NONE',
}

export enum DiplomaKeyEnum {
  Bac_2 = 'BAC_2',
  Bac_3 = 'BAC_3',
  Bac_4 = 'BAC_4',
  Bac_5 = 'BAC_5',
  BacGt = 'BAC_GT',
  BacPro = 'BAC_PRO',
  Bep = 'BEP',
  Brevet = 'BREVET',
  Cap = 'CAP',
  Cep = 'CEP',
  Doctorat = 'DOCTORAT',
  None = 'NONE',
}

/** All the diploma levels allowed in a FDR configuration */
export enum DiplomaLevel {
  /** L1 */
  L1 = 'L1',
  /** L2 */
  L2 = 'L2',
  /** L3 */
  L3 = 'L3',
  /** M1 */
  M1 = 'M1',
  /** M2 */
  M2 = 'M2',
  /** Other */
  Other = 'OTHER',
  /** PhD */
  Phd = 'PHD',
}

/** An education */
export type Education = {
  __typename?: 'Education';
  diplomaTitle?: Maybe<Scalars['String']['output']>;
  diplomaType?: Maybe<DiplomaEnum>;
  endsAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['ID']['output'];
  schoolName: Scalars['String']['output'];
  startsAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

/** The status of the employee profile from the perspective of the HR admin */
export enum EmployeeStatus {
  /** The employee is active on the platform */
  Active = 'ACTIVE',
  /** The employee profile is anonymized */
  Anonymized = 'ANONYMIZED',
  /** The employee's profile is complete and approved but is not yet published */
  Approved = 'APPROVED',
  /** The employee profile is archived */
  Archived = 'ARCHIVED',
  /** The employee is at risk of becoming inactive */
  AtRisk = 'AT_RISK',
  /** The employee has never been published but has a complete profile */
  CompleteProfile = 'COMPLETE_PROFILE',
  /** The employee is in hibernation mode */
  Hibernated = 'HIBERNATED',
  /** The employee is inactive on the platform */
  Inactive = 'INACTIVE',
  /** The employee has never been published and has an incomplete profile */
  IncompleteProfile = 'INCOMPLETE_PROFILE',
  /** The employee has been invited to the platform but has not yet signed up */
  Invited = 'INVITED',
  /** translation missing: en.graphql.schema.enums.employee_status.invited_multiple_times */
  InvitedMultipleTimes = 'INVITED_MULTIPLE_TIMES',
  /** The employee has been unpublished from the platform */
  Unpublished = 'UNPUBLISHED',
}

export enum ExtractInfosFromInvitationMutationErrorEnum {
  AlreadyClaimed = 'ALREADY_CLAIMED',
  Invalid = 'INVALID',
}

/** A FDR */
export type Fdr = {
  __typename?: 'FDR';
  id: Scalars['ID']['output'];
  operationName: Scalars['String']['output'];
};

/** a fdr configuration success */
export type FdrConfigurationSuccess = {
  __typename?: 'FDRConfigurationSuccess';
  fdrCoachLink: Scalars['String']['output'];
};

export enum FacetKeyEnum {
  Companies = 'COMPANIES',
  CompanySizes = 'COMPANY_SIZES',
  CurrentGrade = 'CURRENT_GRADE',
  CurrentSchoolName = 'CURRENT_SCHOOL_NAME',
  JobCategory = 'JOB_CATEGORY',
  JobTitle = 'JOB_TITLE',
  Languages = 'LANGUAGES',
  LevelOfExperience = 'LEVEL_OF_EXPERIENCE',
  Location = 'LOCATION',
  PreparedDiplomaTitle = 'PREPARED_DIPLOMA_TITLE',
  ProfessionalTypes = 'PROFESSIONAL_TYPES',
  RecommendedQualifications = 'RECOMMENDED_QUALIFICATIONS',
  SchoolNames = 'SCHOOL_NAMES',
  SecondarySituations = 'SECONDARY_SITUATIONS',
  Sectors = 'SECTORS',
  TagNames = 'TAG_NAMES',
}

export enum FieldCompletionName {
  AcquiredDiploma = 'ACQUIRED_DIPLOMA',
  AmbassadorPresentation = 'AMBASSADOR_PRESENTATION',
  AspirationPresentation = 'ASPIRATION_PRESENTATION',
  Avatar = 'AVATAR',
  Birthdate = 'BIRTHDATE',
  CompanyLogo = 'COMPANY_LOGO',
  CompanyName = 'COMPANY_NAME',
  CompanySector = 'COMPANY_SECTOR',
  CompanySize = 'COMPANY_SIZE',
  CurrentScholarityInfos = 'CURRENT_SCHOLARITY_INFOS',
  CurrentSchoolDepartment = 'CURRENT_SCHOOL_DEPARTMENT',
  CurrentSchoolName = 'CURRENT_SCHOOL_NAME',
  CurrentSchoolType = 'CURRENT_SCHOOL_TYPE',
  EditionMemberPresentation = 'EDITION_MEMBER_PRESENTATION',
  ExpertisePresentation = 'EXPERTISE_PRESENTATION',
  FirstName = 'FIRST_NAME',
  Gender = 'GENDER',
  InterestsPresentation = 'INTERESTS_PRESENTATION',
  JobSeekerSupportOrganizationType = 'JOB_SEEKER_SUPPORT_ORGANIZATION_TYPE',
  JobSeekerSupportPrograms = 'JOB_SEEKER_SUPPORT_PROGRAMS',
  JobSeekerSupportProgramsAndPositionPanel = 'JOB_SEEKER_SUPPORT_PROGRAMS_AND_POSITION_PANEL',
  JobTitle = 'JOB_TITLE',
  JoinProgram = 'JOIN_PROGRAM',
  Languages = 'LANGUAGES',
  LastName = 'LAST_NAME',
  LookingForOpportunity = 'LOOKING_FOR_OPPORTUNITY',
  MaxMeetingPerMonth = 'MAX_MEETING_PER_MONTH',
  MeetingPlace = 'MEETING_PLACE',
  MeetingPreferences = 'MEETING_PREFERENCES',
  MemberPresentation = 'MEMBER_PRESENTATION',
  MemberPresentationStudent = 'MEMBER_PRESENTATION_STUDENT',
  MustWantToShare = 'MUST_WANT_TO_SHARE',
  NeverWorked = 'NEVER_WORKED',
  Orientation = 'ORIENTATION',
  Password = 'PASSWORD',
  Phone = 'PHONE',
  PreparedDiplomaTitle = 'PREPARED_DIPLOMA_TITLE',
  PreviousCompanyName = 'PREVIOUS_COMPANY_NAME',
  PreviousJobTitle = 'PREVIOUS_JOB_TITLE',
  PreviousPositionYearsOfExperiences = 'PREVIOUS_POSITION_YEARS_OF_EXPERIENCES',
  PrimarySituation = 'PRIMARY_SITUATION',
  ReturningToStudy = 'RETURNING_TO_STUDY',
  SecondarySituation = 'SECONDARY_SITUATION',
  ShowCongratulationsPanel = 'SHOW_CONGRATULATIONS_PANEL',
  ShowCongratulationsPanelUpdate = 'SHOW_CONGRATULATIONS_PANEL_UPDATE',
  StudentSupportCurrentSchoolAndPositionPanel = 'STUDENT_SUPPORT_CURRENT_SCHOOL_AND_POSITION_PANEL',
  SupportMemberPresentation = 'SUPPORT_MEMBER_PRESENTATION',
  WantToShare = 'WANT_TO_SHARE',
  WantToShareStudent = 'WANT_TO_SHARE_STUDENT',
  Welcome = 'WELCOME',
  WelcomeUpdate = 'WELCOME_UPDATE',
  YearsOfExperience = 'YEARS_OF_EXPERIENCE',
}

export type FieldCompletionStatus = {
  __typename?: 'FieldCompletionStatus';
  complete: Scalars['Boolean']['output'];
  disabled: Scalars['Boolean']['output'];
  name: FieldCompletionName;
  points: Scalars['Int']['output'];
};

export type FranceLevel1AdministrativeArea = {
  __typename?: 'FranceLevel1AdministrativeArea';
  key: FranceLevel1AdministrativeAreasEnum;
  name: Scalars['String']['output'];
};

export type FranceLevel1AdministrativeAreaNameArgs = {
  locale?: InputMaybe<AvailableLanguagesEnum>;
};

export enum FranceLevel1AdministrativeAreasEnum {
  AuvergneRhoneAlpes = 'AUVERGNE_RHONE_ALPES',
  BourgogneFrancheComte = 'BOURGOGNE_FRANCHE_COMTE',
  Bretagne = 'BRETAGNE',
  CentreValDeLoire = 'CENTRE_VAL_DE_LOIRE',
  Corse = 'CORSE',
  GrandEst = 'GRAND_EST',
  HautsDeFrance = 'HAUTS_DE_FRANCE',
  IleDeFrance = 'ILE_DE_FRANCE',
  Normandie = 'NORMANDIE',
  NouvelleAquitaine = 'NOUVELLE_AQUITAINE',
  Occitanie = 'OCCITANIE',
  PaysDeLaLoire = 'PAYS_DE_LA_LOIRE',
  ProvenceAlpesCoteDAzur = 'PROVENCE_ALPES_COTE_D_AZUR',
}

export type GalleryImage = {
  __typename?: 'GalleryImage';
  id: Scalars['ID']['output'];
  imageUrl: Scalars['String']['output'];
  user: AmbivalentUser;
};

export type GamificationScoreEntry = {
  __typename?: 'GamificationScoreEntry';
  formula: Array<Scalars['Int']['output']>;
  name: GamificationScoreNameEnum;
  total: Scalars['Int']['output'];
};

export enum GamificationScoreNameEnum {
  Appointments = 'APPOINTMENTS',
  Bonus = 'BONUS',
  Replies = 'REPLIES',
  Views = 'VIEWS',
}

export enum GenderEnum {
  Female = 'FEMALE',
  Male = 'MALE',
  Unspecified = 'UNSPECIFIED',
}

/** Goverment School */
export type GouvSchool = {
  __typename?: 'GouvSchool';
  academyLabel?: Maybe<Scalars['String']['output']>;
  agriculturalSchool?: Maybe<Scalars['Boolean']['output']>;
  communeName?: Maybe<Scalars['String']['output']>;
  departmentLabel?: Maybe<Scalars['String']['output']>;
  establishmentCategory?: Maybe<Array<Scalars['String']['output']>>;
  establishmentType?: Maybe<Scalars['String']['output']>;
  generalPath?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  lastApiUpdate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  militarySchool?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  priorityEducation?: Maybe<Scalars['Boolean']['output']>;
  professionalPath?: Maybe<Scalars['Boolean']['output']>;
  publicPrivateStatus?: Maybe<Scalars['String']['output']>;
  regionLabel?: Maybe<Scalars['String']['output']>;
  sigle?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  technologicalPath?: Maybe<Scalars['Boolean']['output']>;
  uai?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export enum GouvSchoolErrorEnum {
  DepartmentLabelCannotBeEmpty = 'DEPARTMENT_LABEL_CANNOT_BE_EMPTY',
}

/** Filters for GouvSchool search query */
export type GouvSchoolSearchFilter = {
  /** Filter by city */
  communeName?: InputMaybe<Scalars['String']['input']>;
  /** Filter by department */
  departmentLabel?: InputMaybe<Scalars['String']['input']>;
  /** Filter by establishment type */
  establishmentType?: InputMaybe<SecondarySituationEnum>;
};

/** Search result for schools */
export type GouvSchoolSearchResults = {
  __typename?: 'GouvSchoolSearchResults';
  academyLabel?: Maybe<Scalars['String']['output']>;
  agriculturalSchool?: Maybe<Scalars['Boolean']['output']>;
  communeName?: Maybe<Scalars['String']['output']>;
  departmentLabel?: Maybe<Scalars['String']['output']>;
  establishmentType?: Maybe<Scalars['String']['output']>;
  generalPath?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  militarySchool?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  priorityEducation?: Maybe<Scalars['Boolean']['output']>;
  professionalPath?: Maybe<Scalars['Boolean']['output']>;
  publicPrivateStatus?: Maybe<Scalars['String']['output']>;
  regionLabel?: Maybe<Scalars['String']['output']>;
  sigle?: Maybe<Scalars['String']['output']>;
  technologicalPath?: Maybe<Scalars['Boolean']['output']>;
  uai?: Maybe<Scalars['String']['output']>;
};

export type Grade = {
  __typename?: 'Grade';
  key: GradeKeyEnum;
  name: Scalars['String']['output'];
};

export type GradeNameArgs = {
  locale?: InputMaybe<AvailableLanguagesEnum>;
};

export enum GradeEnum {
  Autre = 'AUTRE',
  Bts1 = 'BTS1',
  Bts2 = 'BTS2',
  But1 = 'BUT1',
  But2 = 'BUT2',
  But3 = 'BUT3',
  Cap1 = 'CAP1',
  Cap2 = 'CAP2',
  Cfa1 = 'CFA1',
  Cfa2 = 'CFA2',
  Cfat = 'CFAT',
  College3 = 'COLLEGE3',
  College4 = 'COLLEGE4',
  College5 = 'COLLEGE5',
  College6 = 'COLLEGE6',
  Cpge1 = 'CPGE1',
  Cpge2 = 'CPGE2',
  Dcg1 = 'DCG1',
  Dcg2 = 'DCG2',
  Dcg3 = 'DCG3',
  DiplomeUniversitaire = 'DIPLOME_UNIVERSITAIRE',
  Doctorat = 'DOCTORAT',
  Ec1 = 'EC1',
  Ec2 = 'EC2',
  Ec3 = 'EC3',
  Ec4 = 'EC4',
  Ec5 = 'EC5',
  Ec6 = 'EC6',
  Ei1 = 'EI1',
  Ei2 = 'EI2',
  Ei3 = 'EI3',
  Ei4 = 'EI4',
  Ei5 = 'EI5',
  Ei6 = 'EI6',
  Licence1 = 'LICENCE1',
  Licence2 = 'LICENCE2',
  Licence3 = 'LICENCE3',
  Lycee1 = 'LYCEE1',
  Lycee2 = 'LYCEE2',
  LyceeT = 'LYCEE_T',
  Master1 = 'MASTER1',
  Master2 = 'MASTER2',
  Medecine1 = 'MEDECINE1',
  Medecine2 = 'MEDECINE2',
  Medecine3 = 'MEDECINE3',
}

export enum GradeKeyEnum {
  Autre = 'AUTRE',
  Bts1 = 'BTS1',
  Bts2 = 'BTS2',
  But1 = 'BUT1',
  But2 = 'BUT2',
  But3 = 'BUT3',
  Cap1 = 'CAP1',
  Cap2 = 'CAP2',
  Cfa1 = 'CFA1',
  Cfa2 = 'CFA2',
  Cfat = 'CFAT',
  College3 = 'COLLEGE3',
  College4 = 'COLLEGE4',
  College5 = 'COLLEGE5',
  College6 = 'COLLEGE6',
  Cpge1 = 'CPGE1',
  Cpge2 = 'CPGE2',
  Dcg1 = 'DCG1',
  Dcg2 = 'DCG2',
  Dcg3 = 'DCG3',
  DiplomeUniversitaire = 'DIPLOME_UNIVERSITAIRE',
  Doctorat = 'DOCTORAT',
  Ec1 = 'EC1',
  Ec2 = 'EC2',
  Ec3 = 'EC3',
  Ec4 = 'EC4',
  Ec5 = 'EC5',
  Ec6 = 'EC6',
  Ei1 = 'EI1',
  Ei2 = 'EI2',
  Ei3 = 'EI3',
  Ei4 = 'EI4',
  Ei5 = 'EI5',
  Ei6 = 'EI6',
  Licence1 = 'LICENCE1',
  Licence2 = 'LICENCE2',
  Licence3 = 'LICENCE3',
  Lycee1 = 'LYCEE1',
  Lycee2 = 'LYCEE2',
  LyceeT = 'LYCEE_T',
  Master1 = 'MASTER1',
  Master2 = 'MASTER2',
  Medecine1 = 'MEDECINE1',
  Medecine2 = 'MEDECINE2',
  Medecine3 = 'MEDECINE3',
}

/** Group session, attached to a conversation which reflects: Rencontres collectives */
export type GroupSession = {
  __typename?: 'GroupSession';
  classes?: Maybe<Array<GradeEnum>>;
  conversations?: Maybe<Array<Conversation>>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  femaleCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  maleCount: Scalars['Int']['output'];
  participantCount: Scalars['Int']['output'];
  programs?: Maybe<Array<Scalars['String']['output']>>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type GroupSessionInput = {
  classes?: InputMaybe<Array<Scalars['String']['input']>>;
  femaleCount: Scalars['Int']['input'];
  maleCount: Scalars['Int']['input'];
  programs?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type HermesContact = {
  __typename?: 'HermesContact';
  anonymized?: Maybe<Scalars['Boolean']['output']>;
  appointmentContactMethod?: Maybe<Scalars['String']['output']>;
  appointmentDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  companySector?: Maybe<Scalars['String']['output']>;
  conversationId?: Maybe<Scalars['ID']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  professionalFirstName?: Maybe<Scalars['String']['output']>;
  professionalUrl?: Maybe<Scalars['String']['output']>;
  state?: Maybe<HermesContactStateEnum>;
};

export enum HermesContactStateEnum {
  InvalidatedAppointment = 'INVALIDATED_APPOINTMENT',
  NoScheduledAppointment = 'NO_SCHEDULED_APPOINTMENT',
  PastAndConfirmed = 'PAST_AND_CONFIRMED',
  PastAwaitingConfirmation = 'PAST_AWAITING_CONFIRMATION',
  ProfileReportedByTheProfessional = 'PROFILE_REPORTED_BY_THE_PROFESSIONAL',
  UnfollowedConversationWithThisProfessional = 'UNFOLLOWED_CONVERSATION_WITH_THIS_PROFESSIONAL',
  Upcoming = 'UPCOMING',
}

/**
 * An ambassador request to stop being contacted or visible in search between two date
 *
 * Also see:
 * - [createHibernation](#mutation-createHibernation)
 * - [destroyHibernation](#mutation-destroyHibernation)
 * - [AmbivalentUser](#definition-AmbivalentUser)
 */
export type Hibernation = {
  __typename?: 'Hibernation';
  effectiveWakeAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['ID']['output'];
  reason?: Maybe<Scalars['String']['output']>;
  requestedWakeAt: Scalars['ISO8601Date']['output'];
  scheduledStartAt: Scalars['ISO8601Date']['output'];
};

export type Image = {
  __typename?: 'Image';
  url?: Maybe<Scalars['String']['output']>;
};

/** Interaction group keys */
export enum InteractionGroupKeyEnum {
  GraduateStudent = 'GRADUATE_STUDENT',
  HighSchoolStudent = 'HIGH_SCHOOL_STUDENT',
  InActivity = 'IN_ACTIVITY',
  MiddleSchoolStudent = 'MIDDLE_SCHOOL_STUDENT',
}

export enum InteractionServiceErrorEnum {
  AmbassadorMeetingPreferencesPreventsContact = 'AMBASSADOR_MEETING_PREFERENCES_PREVENTS_CONTACT',
  AmbassadorUnavailable = 'AMBASSADOR_UNAVAILABLE',
  MemberProfileNotComplete = 'MEMBER_PROFILE_NOT_COMPLETE',
  NoRemainingContact = 'NO_REMAINING_CONTACT',
  UserInteractionGroupPreventsContact = 'USER_INTERACTION_GROUP_PREVENTS_CONTACT',
}

export type InterlocutorInterface = {
  acquiredDiplomaAcquisitionYear?: Maybe<Scalars['Int']['output']>;
  acquiredDiplomaBeginYear?: Maybe<Scalars['Int']['output']>;
  acquiredDiplomaLevel?: Maybe<Diploma>;
  acquiredDiplomaSchoolName?: Maybe<Scalars['String']['output']>;
  acquiredDiplomaTitle?: Maybe<Scalars['String']['output']>;
  ambassadorPresentation?: Maybe<Scalars['String']['output']>;
  anonymizedOrDeleted?: Maybe<Scalars['Boolean']['output']>;
  aspirationPresentation?: Maybe<Scalars['String']['output']>;
  avatar: Avatar;
  avatars: Avatar;
  banner?: Maybe<OrganizationCardBanner>;
  companyDescription?: Maybe<Scalars['String']['output']>;
  companyLogo?: Maybe<OrganizationLogo>;
  companyName?: Maybe<Scalars['String']['output']>;
  currentGrade?: Maybe<Grade>;
  currentGradeOrDiploma?: Maybe<Scalars['String']['output']>;
  currentSchoolCity?: Maybe<Scalars['String']['output']>;
  currentSchoolDepartment?: Maybe<Scalars['String']['output']>;
  currentSchoolLogo?: Maybe<OrganizationLogo>;
  currentSchoolName?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  expertisePresentation?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  interestsPresentation?: Maybe<Scalars['String']['output']>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  meetingPlace?: Maybe<Scalars['String']['output']>;
  memberPresentation?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  preparedDiplomaTitle?: Maybe<Diploma>;
  previousCompanyLogo?: Maybe<OrganizationLogo>;
  previousCompanyName?: Maybe<Scalars['String']['output']>;
  previousPosition?: Maybe<Scalars['String']['output']>;
  previousPositionYearsOfExperiences?: Maybe<Scalars['Int']['output']>;
  primarySituation?: Maybe<PrimarySituation>;
  schoolName?: Maybe<Scalars['String']['output']>;
  secondarySituation?: Maybe<SecondarySituation>;
  visibilityStatus: VisibilityStatusEnum;
  yearsOfExperience?: Maybe<Scalars['Int']['output']>;
};

export enum InternshipDuration {
  InternshipDuration_1Months = 'INTERNSHIP_DURATION_1_MONTHS',
  InternshipDuration_2Months = 'INTERNSHIP_DURATION_2_MONTHS',
  InternshipDuration_3Months = 'INTERNSHIP_DURATION_3_MONTHS',
  InternshipDuration_4Months = 'INTERNSHIP_DURATION_4_MONTHS',
  InternshipDuration_5Months = 'INTERNSHIP_DURATION_5_MONTHS',
  InternshipDuration_6Months = 'INTERNSHIP_DURATION_6_MONTHS',
  InternshipDuration_7Months = 'INTERNSHIP_DURATION_7_MONTHS',
  InternshipDuration_8Months = 'INTERNSHIP_DURATION_8_MONTHS',
  InternshipDuration_9Months = 'INTERNSHIP_DURATION_9_MONTHS',
  InternshipDuration_10Months = 'INTERNSHIP_DURATION_10_MONTHS',
  InternshipDuration_11Months = 'INTERNSHIP_DURATION_11_MONTHS',
  InternshipDuration_12Months = 'INTERNSHIP_DURATION_12_MONTHS',
  InternshipDuration_13Months = 'INTERNSHIP_DURATION_13_MONTHS',
  InternshipDuration_14Months = 'INTERNSHIP_DURATION_14_MONTHS',
  InternshipDuration_15Months = 'INTERNSHIP_DURATION_15_MONTHS',
  InternshipDuration_16Months = 'INTERNSHIP_DURATION_16_MONTHS',
  InternshipDuration_17Months = 'INTERNSHIP_DURATION_17_MONTHS',
  InternshipDuration_18Months = 'INTERNSHIP_DURATION_18_MONTHS',
  InternshipDuration_19Months = 'INTERNSHIP_DURATION_19_MONTHS',
  InternshipDuration_20Months = 'INTERNSHIP_DURATION_20_MONTHS',
  InternshipDuration_21Months = 'INTERNSHIP_DURATION_21_MONTHS',
  InternshipDuration_22Months = 'INTERNSHIP_DURATION_22_MONTHS',
  InternshipDuration_23Months = 'INTERNSHIP_DURATION_23_MONTHS',
  InternshipDuration_24Months = 'INTERNSHIP_DURATION_24_MONTHS',
  InternshipDurationUndetermined = 'INTERNSHIP_DURATION_UNDETERMINED',
}

export enum InternshipStatus {
  HaveFound = 'HAVE_FOUND',
  LookingFor = 'LOOKING_FOR',
}

export type InternshipType = {
  __typename?: 'InternshipType';
  key: InternshipTypeEnum;
  name: Scalars['String']['output'];
};

export type InternshipTypeNameArgs = {
  locale?: InputMaybe<AvailableLanguagesEnum>;
};

export enum InternshipTypeEnum {
  Apprenticeship = 'APPRENTICESHIP',
  Cdd = 'CDD',
  Cdi = 'CDI',
  Internship = 'INTERNSHIP',
  Via = 'VIA',
  Vie = 'VIE',
}

export type Invitation = {
  __typename?: 'Invitation';
  email: Scalars['String']['output'];
  firstCustomAttribute: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  position: Scalars['String']['output'];
  secondCustomAttribute: Scalars['String']['output'];
  thirdCustomAttribute: Scalars['String']['output'];
};

export type InvitationInfos = {
  __typename?: 'InvitationInfos';
  email: Scalars['Email']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
};

export type JobSeekerSupportOrganizationType = {
  __typename?: 'JobSeekerSupportOrganizationType';
  key: JobSeekerSupportOrganizationTypeKeyEnum;
  name: Scalars['String']['output'];
};

export type JobSeekerSupportOrganizationTypeNameArgs = {
  locale?: InputMaybe<AvailableLanguagesEnum>;
};

export enum JobSeekerSupportOrganizationTypeKeyEnum {
  Apec = 'APEC',
  CapEmploi = 'CAP_EMPLOI',
  FranceTravail = 'FRANCE_TRAVAIL',
  MissionLocale = 'MISSION_LOCALE',
  Other = 'OTHER',
  Pareo = 'PAREO',
}

export type JobSeekerSupportProgram = {
  __typename?: 'JobSeekerSupportProgram';
  key: JobSeekerSupportProgramKeyEnum;
  name: Scalars['String']['output'];
};

export type JobSeekerSupportProgramNameArgs = {
  locale?: InputMaybe<AvailableLanguagesEnum>;
};

export enum JobSeekerSupportProgramKeyEnum {
  AccompagnementGlobal = 'ACCOMPAGNEMENT_GLOBAL',
  Aij = 'AIJ',
  Cej = 'CEJ',
  Csp = 'CSP',
  DynamicSeniors = 'DYNAMIC_SENIORS',
  EquipEmploi = 'EQUIP_EMPLOI',
  EquipRecrut = 'EQUIP_RECRUT',
  Mobilite = 'MOBILITE',
  Other = 'OTHER',
  PortefeuilleGuide = 'PORTEFEUILLE_GUIDE',
  PortefeuilleRenforce = 'PORTEFEUILLE_RENFORCE',
  PortefeuilleSuivi = 'PORTEFEUILLE_SUIVI',
}

export type JobTitle = {
  __typename?: 'JobTitle';
  key: JobTitleKeyEnum;
  name: Scalars['String']['output'];
};

export type JobTitleNameArgs = {
  locale?: InputMaybe<AvailableLanguagesEnum>;
};

export enum JobTitleKeyEnum {
  EducationalAdviser = 'EDUCATIONAL_ADVISER',
  HeadOfSchool = 'HEAD_OF_SCHOOL',
  OrientationCounselor = 'ORIENTATION_COUNSELOR',
  Other = 'OTHER',
  Teacher = 'TEACHER',
}

export enum JoinProgramErrorEnum {
  /** Current user is already part of the program */
  AlreadyAffiliated = 'ALREADY_AFFILIATED',
  /** Program not found */
  NotFound = 'NOT_FOUND',
  /** The program cannot have more participants */
  ProgramReachedParticipationLimit = 'PROGRAM_REACHED_PARTICIPATION_LIMIT',
  /** Unauthorized */
  Unauthorized = 'UNAUTHORIZED',
}

export enum Language {
  Afrikaans = 'AFRIKAANS',
  Albanian = 'ALBANIAN',
  Arabic = 'ARABIC',
  Armenian = 'ARMENIAN',
  Azeri = 'AZERI',
  Basque = 'BASQUE',
  Belarusian = 'BELARUSIAN',
  Bengali = 'BENGALI',
  Bosnian = 'BOSNIAN',
  Bulgarian = 'BULGARIAN',
  Burmese = 'BURMESE',
  Catalan = 'CATALAN',
  Chinese = 'CHINESE',
  Croatian = 'CROATIAN',
  Czech = 'CZECH',
  Danish = 'DANISH',
  Dutch = 'DUTCH',
  English = 'ENGLISH',
  Estonian = 'ESTONIAN',
  Filipino = 'FILIPINO',
  Finnish = 'FINNISH',
  French = 'FRENCH',
  Galician = 'GALICIAN',
  Georgian = 'GEORGIAN',
  German = 'GERMAN',
  Greek = 'GREEK',
  Guarani = 'GUARANI',
  Gujarati = 'GUJARATI',
  Hebrew = 'HEBREW',
  Hindi = 'HINDI',
  Hungarian = 'HUNGARIAN',
  Icelandic = 'ICELANDIC',
  Indonesian = 'INDONESIAN',
  Italian = 'ITALIAN',
  Japanese = 'JAPANESE',
  Javanese = 'JAVANESE',
  Kannada = 'KANNADA',
  Kazakh = 'KAZAKH',
  Khmer = 'KHMER',
  Kinyarwanda = 'KINYARWANDA',
  Korean = 'KOREAN',
  Kurdish = 'KURDISH',
  Latvian = 'LATVIAN',
  Lithuanian = 'LITHUANIAN',
  Macedonian = 'MACEDONIAN',
  Malay = 'MALAY',
  Malayalam = 'MALAYALAM',
  Marathi = 'MARATHI',
  Mongolian = 'MONGOLIAN',
  Nepalese = 'NEPALESE',
  Norwegian = 'NORWEGIAN',
  Oriya = 'ORIYA',
  Pashto = 'PASHTO',
  Persian = 'PERSIAN',
  Polish = 'POLISH',
  Portuguese = 'PORTUGUESE',
  Punjabi = 'PUNJABI',
  Romanian = 'ROMANIAN',
  Russian = 'RUSSIAN',
  Serbian = 'SERBIAN',
  Sinhalese = 'SINHALESE',
  Slovak = 'SLOVAK',
  Slovenian = 'SLOVENIAN',
  Spanish = 'SPANISH',
  Swahili = 'SWAHILI',
  Swedish = 'SWEDISH',
  Tajik = 'TAJIK',
  Tamazight = 'TAMAZIGHT',
  Tamil = 'TAMIL',
  Thai = 'THAI',
  Turkish = 'TURKISH',
  Ukrainian = 'UKRAINIAN',
  Urdu = 'URDU',
  Uzbek = 'UZBEK',
  Vietnamese = 'VIETNAMESE',
  Visayan = 'VISAYAN',
  Welsh = 'WELSH',
}

/** An MJG employee */
export type MjgEmployee = {
  __typename?: 'MJGEmployee';
  avatar: Scalars['AvatarUrl']['output'];
  firstName: Scalars['String']['output'];
  funSentence?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  jobTitle: Scalars['String']['output'];
};

/** An MJG employee */
export type MjgEmployeeAvatarArgs = {
  version?: InputMaybe<PictureVersion>;
};

/** A mandatory skill */
export type MandatorySkill = {
  __typename?: 'MandatorySkill';
  text: Scalars['String']['output'];
};

export enum MandatorySkillsAutocompleteErrorEnum {
  /** Term is too short. Please provide a term with at least 3 characters. */
  TermTooShort = 'TERM_TOO_SHORT',
}

export enum MarkAllNotificationsAsSeenMutationErrorEnum {
  Unauthorized = 'UNAUTHORIZED',
}

export enum MarkNotificationAsSeenMutationErrorEnum {
  NotFound = 'NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED',
}

export type MarkNotificationsAsSeenPayload = {
  __typename?: 'MarkNotificationsAsSeenPayload';
  modifiedCount?: Maybe<Scalars['Int']['output']>;
};

export type MatchingResult = {
  __typename?: 'MatchingResult';
  bold: Array<Bold>;
  score: Scalars['Float']['output'];
  text: Scalars['String']['output'];
};

export type MeetingPreference = {
  __typename?: 'MeetingPreference';
  description: Scalars['String']['output'];
  key: InteractionGroupKeyEnum;
  name: Scalars['String']['output'];
  value?: Maybe<Scalars['Boolean']['output']>;
};

export type MeetingPreferenceDescriptionArgs = {
  locale?: InputMaybe<AvailableLanguagesEnum>;
};

export type MeetingPreferenceNameArgs = {
  locale?: InputMaybe<AvailableLanguagesEnum>;
};

export type MeetingPreferenceInput = {
  accept: Scalars['Boolean']['input'];
  key: InteractionGroupKeyEnum;
};

export enum MemberBadgeEnum {
  AccountCreated = 'ACCOUNT_CREATED',
  FifteenthAppointmentOccurred = 'FIFTEENTH_APPOINTMENT_OCCURRED',
  FifthAppointmentOccurred = 'FIFTH_APPOINTMENT_OCCURRED',
  FiftiethAppointmentOccurred = 'FIFTIETH_APPOINTMENT_OCCURRED',
  FirstAppointmentOccurred = 'FIRST_APPOINTMENT_OCCURRED',
  FirstAppointmentReview = 'FIRST_APPOINTMENT_REVIEW',
  FirstMessageSent = 'FIRST_MESSAGE_SENT',
  HundredthAppointmentOccurred = 'HUNDREDTH_APPOINTMENT_OCCURRED',
  ProfileComplete = 'PROFILE_COMPLETE',
  SeventyFifthAppointmentOccurred = 'SEVENTY_FIFTH_APPOINTMENT_OCCURRED',
  TenthAppointmentOccurred = 'TENTH_APPOINTMENT_OCCURRED',
  ThirdAppointmentOccurred = 'THIRD_APPOINTMENT_OCCURRED',
  ThirthiethAppointmentOccurred = 'THIRTHIETH_APPOINTMENT_OCCURRED',
  TwentiethAppointmentOccurred = 'TWENTIETH_APPOINTMENT_OCCURRED',
}

/** A message */
export type Message = {
  __typename?: 'Message';
  attachment?: Maybe<MessageAttachment>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  fromSystem: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  seenAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  sender?: Maybe<PublicProfile>;
  text: Scalars['String']['output'];
  user: ConversationInterlocutor;
};

/** A message attachment */
export type MessageAttachment = {
  __typename?: 'MessageAttachment';
  name?: Maybe<Scalars['String']['output']>;
  scannedByAntivirus?: Maybe<AntivirusStatus>;
  url: Scalars['String']['output'];
};

export type MobileNotificationSubscriptions = {
  __typename?: 'MobileNotificationSubscriptions';
  appointmentCanceled: Scalars['Boolean']['output'];
  appointmentComingIn10mn: Scalars['Boolean']['output'];
  appointmentCreated: Scalars['Boolean']['output'];
  appointmentModified: Scalars['Boolean']['output'];
  evaluationToComplete: Scalars['Boolean']['output'];
  newMessageReceived: Scalars['Boolean']['output'];
};

export enum MobileNotificationSubscriptionsQueryErrorEnum {
  Unauthorized = 'UNAUTHORIZED',
}

export enum MoveAppointmentMutationErrorEnum {
  CannotMoveInThePast = 'CANNOT_MOVE_IN_THE_PAST',
  CannotMovePastAppointment = 'CANNOT_MOVE_PAST_APPOINTMENT',
  MustBeTheAmbassador = 'MUST_BE_THE_AMBASSADOR',
  NotFound = 'NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED',
  UnexpectedValidationErrors = 'UNEXPECTED_VALIDATION_ERRORS',
}

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * Accept an invitation to a Vocation program ([program](#definition-program))
   * Creates a participation ([participation](#definition-participation))
   *
   * __Errors definition__: [AcceptInvitationErrorEnum](#definition-AcceptInvitationErrorEnum)
   */
  acceptInvitation: Scalars['Boolean']['output'];
  acknowledgeConversation: Scalars['Boolean']['output'];
  addCompanyRecruitingWorkArea: AddRecruitingWorkingAreaPayload;
  affiliateToAssignment: AffiliationResult;
  approveEmployee: Scalars['Boolean']['output'];
  archiveEmployee: Scalars['Boolean']['output'];
  bookAppointment: Appointment;
  claimAppointment: AppointmentClaim;
  /**
   * Send the Vocation certificate by email
   *
   * __Errors definition__: [ClaimCertificateErrorEnum](#definition-ClaimCertificateErrorEnum)
   */
  claimCertificate: Scalars['Boolean']['output'];
  configureContractualAssignment: Scalars['Boolean']['output'];
  /**
   * Confirms the user email
   *
   * __Errors definition__: [ConfirmEmailErrorEnum](#definition-ConfirmEmailErrorEnum)
   */
  confirmEmail?: Maybe<AmbivalentUser>;
  /**
   * Confirm the tutor emails, only required for minor users
   *
   * __Errors definition__: [ConfirmTutorEmailErrorEnum](#definition-ConfirmTutorEmailErrorEnum)
   */
  confirmTutorEmail?: Maybe<Scalars['Boolean']['output']>;
  createAspiration?: Maybe<Aspiration>;
  /** Create a coach profile  on the current user */
  createCoachProfile: Scalars['Boolean']['output'];
  createContractualFdr: FdrConfigurationSuccess;
  createConversation?: Maybe<Conversation>;
  createEducation?: Maybe<ProfileEducation>;
  /** Create a government school record which will go through moderation */
  createGouvSchool?: Maybe<GouvSchool>;
  /**
   *  Make the current user uncontactable and not visible in the search between start_at and end_at
   *
   * __Errors definitions__: [CreateHibernationMutationErrorEnum](#definition-CreateHibernationMutationErrorEnum)
   */
  createHibernation?: Maybe<Hibernation>;
  createMessageTemplate: UserMessageTemplate;
  desafilliateStudentFromAssignment: Scalars['Boolean']['output'];
  destroyAspiration?: Maybe<Scalars['Boolean']['output']>;
  destroyEducation?: Maybe<Scalars['Boolean']['output']>;
  /**
   *  Destroy future hibernation, or awake fron ongoing hibernation
   *
   * __Errors definitions__: [DestroyHibernationMutationErrorEnum](#definition-DestroyHibernationMutationErrorEnum)
   */
  destroyHibernation?: Maybe<Scalars['Boolean']['output']>;
  destroyMessageTemplate: Scalars['Boolean']['output'];
  extractInfosFromInvitation: InvitationInfos;
  importEmployeesList?: Maybe<Scalars['Boolean']['output']>;
  inviteEmployee: Scalars['Boolean']['output'];
  joinProgram: Participation;
  markAllNotificationsAsSeen: MarkNotificationsAsSeenPayload;
  markNotificationAsSeen: Notification;
  moveAppointment: Appointment;
  newPassword?: Maybe<Scalars['Boolean']['output']>;
  oauth?: Maybe<OauthPayload>;
  openidConnect?: Maybe<OpenidConnectPayload>;
  publish: Scalars['Boolean']['output'];
  /**
   * Create a new access_token that last 4 hours
   *
   * Authenticating mutations:
   * - [signin](#mutation-signin)
   * - [signup](#mutation-signup)
   * - [confirmEmail](#mutation-confirmEmail)
   * - [oauth](#mutation-oauth)
   *
   * Errors: [refreshAccessTokenErrorEnum](#definition-refreshAccessTokenErrorEnum)
   */
  refreshAccessToken?: Maybe<RefreshAccessTokenPayload>;
  removeCompanyRecruitingWorkArea: RemoveRecruitingWorkingAreaPayload;
  removeGalleryImage?: Maybe<AmbivalentUser>;
  reportAppointmentDidNotTookPlace: Appointment;
  reportMember?: Maybe<Report>;
  requestEmailAddressConfirmationEmail?: Maybe<Scalars['Boolean']['output']>;
  requestModeration: Scalars['Boolean']['output'];
  /** Request a new password / password forgotten */
  requestNewPassword?: Maybe<Scalars['Boolean']['output']>;
  reviewAppointmentAsAmbassador: Appointment;
  reviewAppointmentAsMember: Appointment;
  /** Saves the user's push notification token */
  saveNotificationToken: SaveTokenFields;
  sendEmployeeInvitationEmail: Scalars['Boolean']['output'];
  sendMessage: Message;
  signin?: Maybe<User>;
  signout?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Register a user
   * __Errors definition__:
   * [signupErrorEnum](#definition-signupErrorEnum):
   *
   * description: "[signupErrorEnum](#definition-signupErrorEnum):
   * "
   */
  signup?: Maybe<AmbivalentUser>;
  snoozeReview: Appointment;
  /**
   * Soft delete an account, unpublishing him and making his account unaccessible without admin intervention
   *
   * __Errors definition__: [SoftDeleteMutationErrorEnum](#definition-SoftDeleteMutationErrorEnum)
   */
  softDelete?: Maybe<Scalars['Boolean']['output']>;
  unbookAppointment: Appointment;
  unpublish: Scalars['Boolean']['output'];
  unwishAmbassador: Scalars['Boolean']['output'];
  updateAspiration?: Maybe<Aspiration>;
  updateCompany: Scalars['Boolean']['output'];
  updateContractualAssignment: Scalars['Boolean']['output'];
  updateCustomAttribute: Scalars['Boolean']['output'];
  updateEducation?: Maybe<ProfileEducation>;
  updateMessageTemplate: UserMessageTemplate;
  updateMobileNotificationSubscriptions: MobileNotificationSubscriptions;
  /**
   * Updates a user
   *
   * __Errors definition__: [UpdateUserErrorEnum](#definition-UpdateUserErrorEnum)
   */
  updateUser?: Maybe<AmbivalentUser>;
  uploadGalleryImage?: Maybe<GalleryImage>;
  verifyEmployeesList?: Maybe<VerifyEmployeesListPayload>;
  wishAmbassador: Scalars['Boolean']['output'];
  wishProfessional?: Maybe<Scalars['Boolean']['output']>;
};

export type MutationAcceptInvitationArgs = {
  invitationId: Scalars['ID']['input'];
};

export type MutationAcknowledgeConversationArgs = {
  conversationId: Scalars['ID']['input'];
};

export type MutationAddCompanyRecruitingWorkAreaArgs = {
  companyId: Scalars['ID']['input'];
  workingArea: Scalars['String']['input'];
};

export type MutationAffiliateToAssignmentArgs = {
  assignmentAffiliationToken: Scalars['String']['input'];
};

export type MutationApproveEmployeeArgs = {
  employeeId: Scalars['ID']['input'];
};

export type MutationArchiveEmployeeArgs = {
  employeeId: Scalars['ID']['input'];
};

export type MutationBookAppointmentArgs = {
  googlePlaceId?: InputMaybe<Scalars['String']['input']>;
  meetingPlace?: InputMaybe<Scalars['String']['input']>;
  startAt: Scalars['ISO8601DateTime']['input'];
  type: AppointmentTypesEnum;
  userId: Scalars['ID']['input'];
  videoconferenceLink?: InputMaybe<Scalars['String']['input']>;
};

export type MutationClaimAppointmentArgs = {
  conversationId: Scalars['ID']['input'];
  googlePlaceId?: InputMaybe<Scalars['String']['input']>;
  meetingPlace?: InputMaybe<Scalars['String']['input']>;
  startAt: Scalars['ISO8601DateTime']['input'];
  type: AppointmentTypesEnum;
  userId: Scalars['ID']['input'];
};

export type MutationClaimCertificateArgs = {
  participationId: Scalars['ID']['input'];
};

export type MutationConfigureContractualAssignmentArgs = {
  emailLanguage: CommunicationLanguage;
  fdrId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  size: Scalars['Int']['input'];
};

export type MutationConfirmEmailArgs = {
  confirmationToken: Scalars['String']['input'];
};

export type MutationConfirmTutorEmailArgs = {
  confirmationToken: Scalars['String']['input'];
};

export type MutationCreateAspirationArgs = {
  description: Scalars['String']['input'];
  duration: InternshipDuration;
  startsAtMonth: Scalars['Int']['input'];
  startsAtYear: Scalars['Int']['input'];
  status: InternshipStatus;
  type: InternshipTypeEnum;
  userId: Scalars['ID']['input'];
};

export type MutationCreateContractualFdrArgs = {
  academicYear: Scalars['String']['input'];
  appointmentBonusThreshold1?: InputMaybe<Scalars['Int']['input']>;
  appointmentBonusThreshold2?: InputMaybe<Scalars['Int']['input']>;
  campusName: Scalars['String']['input'];
  conversationBonusActivated: Scalars['Boolean']['input'];
  conversationBonusDeadline?: InputMaybe<Scalars['Int']['input']>;
  conversationBonusMessageCount?: InputMaybe<Scalars['Int']['input']>;
  conversationPenaltyActivated: Scalars['Boolean']['input'];
  customIdentifier?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  diplomaLevel: Array<DiplomaLevel>;
  emailReminderActivated: Scalars['Boolean']['input'];
  emailReminderLanguage?: InputMaybe<CommunicationLanguage>;
  emailReminderWeeklyRate?: InputMaybe<Scalars['Int']['input']>;
  endsAt: Scalars['ISO8601DateTime']['input'];
  numberOfMandatoryAppointmentsPerStudent: Scalars['Int']['input'];
  schoolName: Scalars['String']['input'];
  startsAt: Scalars['ISO8601DateTime']['input'];
  supervisorEmail?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateConversationArgs = {
  conversationType?: InputMaybe<ConversationTypeKeyEnum>;
  groupSessionInput?: InputMaybe<GroupSessionInput>;
  message: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationCreateEducationArgs = {
  diplomaTitle: Scalars['String']['input'];
  diplomaType: DiplomaEnum;
  endedAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  schoolName: Scalars['String']['input'];
  startedAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  userId: Scalars['ID']['input'];
};

export type MutationCreateGouvSchoolArgs = {
  communeName?: InputMaybe<Scalars['String']['input']>;
  departmentLabel?: InputMaybe<Scalars['String']['input']>;
  establishmentType?: InputMaybe<SecondarySituationEnum>;
  name: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationCreateHibernationArgs = {
  endAt: Scalars['ISO8601Date']['input'];
  startAt: Scalars['ISO8601Date']['input'];
};

export type MutationCreateMessageTemplateArgs = {
  body: Scalars['String']['input'];
  fromTemplate?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationDesafilliateStudentFromAssignmentArgs = {
  assignmentId: Scalars['ID']['input'];
  participationId: Scalars['ID']['input'];
};

export type MutationDestroyAspirationArgs = {
  aspirationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationDestroyEducationArgs = {
  educationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationDestroyHibernationArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDestroyMessageTemplateArgs = {
  id: Scalars['ID']['input'];
};

export type MutationExtractInfosFromInvitationArgs = {
  invitationToken: Scalars['String']['input'];
};

export type MutationImportEmployeesListArgs = {
  companyId: Scalars['ID']['input'];
  file: Scalars['Upload']['input'];
};

export type MutationInviteEmployeeArgs = {
  companyAttribute1?: InputMaybe<Scalars['String']['input']>;
  companyAttribute2?: InputMaybe<Scalars['String']['input']>;
  companyAttribute3?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['ID']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type MutationJoinProgramArgs = {
  programCode: Scalars['String']['input'];
};

export type MutationMarkNotificationAsSeenArgs = {
  id: Scalars['ID']['input'];
};

export type MutationMoveAppointmentArgs = {
  googlePlaceId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  meetingPlace?: InputMaybe<Scalars['String']['input']>;
  startAt: Scalars['ISO8601DateTime']['input'];
  type: AppointmentTypesEnum;
  videoconferenceLink?: InputMaybe<Scalars['String']['input']>;
};

export type MutationNewPasswordArgs = {
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type MutationOauthArgs = {
  authorizationCode: Scalars['String']['input'];
  provider: OauthProviderEnum;
  redirectUri: Scalars['String']['input'];
};

export type MutationOpenidConnectArgs = {
  identityToken: Scalars['String']['input'];
  provider: OpenidConnectProvider;
};

export type MutationPublishArgs = {
  userId: Scalars['ID']['input'];
};

export type MutationRefreshAccessTokenArgs = {
  refreshToken: Scalars['String']['input'];
};

export type MutationRemoveCompanyRecruitingWorkAreaArgs = {
  companyId: Scalars['ID']['input'];
  workingArea: Scalars['String']['input'];
};

export type MutationRemoveGalleryImageArgs = {
  imageId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationReportAppointmentDidNotTookPlaceArgs = {
  appointmentId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  reason: AppointmentDidNotTookPlaceReasonEnum;
};

export type MutationReportMemberArgs = {
  explanation: Scalars['String']['input'];
  reason: ReportReason;
  reportedId: Scalars['ID']['input'];
};

export type MutationRequestEmailAddressConfirmationEmailArgs = {
  email: Scalars['Email']['input'];
};

export type MutationRequestModerationArgs = {
  userId: Scalars['ID']['input'];
};

export type MutationRequestNewPasswordArgs = {
  email: Scalars['Email']['input'];
};

export type MutationReviewAppointmentAsAmbassadorArgs = {
  id: Scalars['ID']['input'];
  message: Scalars['String']['input'];
  messageForHr?: InputMaybe<Scalars['String']['input']>;
  question1: Scalars['Int']['input'];
  question2?: InputMaybe<Scalars['Int']['input']>;
  question3?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationReviewAppointmentAsMemberArgs = {
  aspiration?: InputMaybe<AspirationsInput>;
  id: Scalars['ID']['input'];
  liked: Scalars['Boolean']['input'];
  message: Scalars['String']['input'];
  question1: Scalars['Int']['input'];
  question2: Scalars['Int']['input'];
  question3: Scalars['Int']['input'];
  schoolAspiration?: InputMaybe<SchoolAspirationInput>;
};

export type MutationSaveNotificationTokenArgs = {
  token: Scalars['String']['input'];
};

export type MutationSendEmployeeInvitationEmailArgs = {
  employeeId: Scalars['ID']['input'];
};

export type MutationSendMessageArgs = {
  attachment?: InputMaybe<Scalars['Upload']['input']>;
  conversationId: Scalars['ID']['input'];
  fromMessageTemplateId?: InputMaybe<Scalars['ID']['input']>;
  text: Scalars['String']['input'];
};

export type MutationSigninArgs = {
  email: Scalars['Email']['input'];
  password: Scalars['String']['input'];
};

export type MutationSignupArgs = {
  birthdate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  comesFrom?: InputMaybe<ComesFromEnum>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  highSchoolTeacher?: InputMaybe<Scalars['Boolean']['input']>;
  invitationToken?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  oauthNonce?: InputMaybe<Scalars['String']['input']>;
  orianeAmbassador?: InputMaybe<Scalars['Boolean']['input']>;
  schoolTeacherDetails?: InputMaybe<SchoolTeacherDetails>;
  tutorEmail?: InputMaybe<Scalars['String']['input']>;
  vocationCoachSignup?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationSnoozeReviewArgs = {
  appointmentId: Scalars['ID']['input'];
};

export type MutationSoftDeleteArgs = {
  reason: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationUnbookAppointmentArgs = {
  id: Scalars['ID']['input'];
};

export type MutationUnpublishArgs = {
  userId: Scalars['ID']['input'];
};

export type MutationUnwishAmbassadorArgs = {
  userId: Scalars['ID']['input'];
};

export type MutationUpdateAspirationArgs = {
  aspirationId: Scalars['ID']['input'];
  description: Scalars['String']['input'];
  duration: InternshipDuration;
  startsAtMonth: Scalars['Int']['input'];
  startsAtYear: Scalars['Int']['input'];
  status: InternshipStatus;
  type: InternshipTypeEnum;
  userId: Scalars['ID']['input'];
};

export type MutationUpdateCompanyArgs = {
  companyId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  establishmentType?: InputMaybe<SecondarySituationEnum>;
  name?: InputMaybe<Scalars['String']['input']>;
  sectorId?: InputMaybe<Scalars['ID']['input']>;
  size?: InputMaybe<CompanySize>;
};

export type MutationUpdateContractualAssignmentArgs = {
  assignmentId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationUpdateCustomAttributeArgs = {
  companyAttributeValues?: InputMaybe<Array<Scalars['String']['input']>>;
  companyId: Scalars['ID']['input'];
  customAttributeNumber: Scalars['Int']['input'];
  customEmployeeAttribute?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateEducationArgs = {
  diplomaTitle: Scalars['String']['input'];
  diplomaType: DiplomaEnum;
  educationId: Scalars['ID']['input'];
  endedAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  schoolName: Scalars['String']['input'];
  startedAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  userId: Scalars['ID']['input'];
};

export type MutationUpdateMessageTemplateArgs = {
  body: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export type MutationUpdateMobileNotificationSubscriptionsArgs = {
  appointmentCanceled?: InputMaybe<Scalars['Boolean']['input']>;
  appointmentComingIn10mn?: InputMaybe<Scalars['Boolean']['input']>;
  appointmentCreated?: InputMaybe<Scalars['Boolean']['input']>;
  appointmentModified?: InputMaybe<Scalars['Boolean']['input']>;
  evaluationToComplete?: InputMaybe<Scalars['Boolean']['input']>;
  newMessageReceived?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationUpdateUserArgs = {
  acceptGroupConversations?: InputMaybe<Scalars['Boolean']['input']>;
  accompaniedStudyLevels?: InputMaybe<Array<GradeEnum>>;
  acquiredDiplomaAcquisitionYear?: InputMaybe<Scalars['Int']['input']>;
  acquiredDiplomaBeginYear?: InputMaybe<Scalars['Int']['input']>;
  acquiredDiplomaLevel?: InputMaybe<DiplomaEnum>;
  acquiredDiplomaSchoolName?: InputMaybe<Scalars['String']['input']>;
  acquiredDiplomaTitle?: InputMaybe<Scalars['String']['input']>;
  ambassadorPresentation?: InputMaybe<Scalars['String']['input']>;
  apprentice?: InputMaybe<Scalars['Boolean']['input']>;
  aspirationPresentation?: InputMaybe<Scalars['String']['input']>;
  avatar?: InputMaybe<Scalars['Upload']['input']>;
  birthdate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  currentGrade?: InputMaybe<GradeEnum>;
  currentPassword?: InputMaybe<Scalars['String']['input']>;
  currentScholarityEndAt?: InputMaybe<Scalars['ISO8601Date']['input']>;
  currentScholarityStartAt?: InputMaybe<Scalars['ISO8601Date']['input']>;
  currentSchoolCity?: InputMaybe<Scalars['String']['input']>;
  currentSchoolDepartment?: InputMaybe<Scalars['String']['input']>;
  currentSchoolName?: InputMaybe<Scalars['String']['input']>;
  dontSharePhoneNumber?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  expertisePresentation?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<GenderEnum>;
  googlePlaceId?: InputMaybe<Scalars['String']['input']>;
  headTeacher?: InputMaybe<Scalars['Boolean']['input']>;
  interestsPresentation?: InputMaybe<Scalars['String']['input']>;
  jobSeekerSupportOrganizationType?: InputMaybe<Scalars['String']['input']>;
  jobSeekerSupportPrograms?: InputMaybe<Array<Scalars['String']['input']>>;
  jobSeeking?: InputMaybe<Scalars['Boolean']['input']>;
  languages?: InputMaybe<Array<Language>>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mandatorySkills?: InputMaybe<Array<Scalars['String']['input']>>;
  maxMeetingsPerMonth?: InputMaybe<Scalars['Int']['input']>;
  meetingPlace?: InputMaybe<Scalars['String']['input']>;
  meetingPreferences?: InputMaybe<Array<MeetingPreferenceInput>>;
  memberPresentation?: InputMaybe<Scalars['String']['input']>;
  neverWorked?: InputMaybe<Scalars['Boolean']['input']>;
  normalizedPhone?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  preparedDiplomaTitle?: InputMaybe<Scalars['String']['input']>;
  presentation?: InputMaybe<Scalars['String']['input']>;
  previousCompanyName?: InputMaybe<Scalars['String']['input']>;
  previousPosition?: InputMaybe<Scalars['String']['input']>;
  previousPositionYearsOfExperiences?: InputMaybe<Scalars['Int']['input']>;
  primarySituation?: InputMaybe<PrimarySituationEnum>;
  programParticipationCode?: InputMaybe<Scalars['String']['input']>;
  recommendedQualifications?: InputMaybe<Array<Scalars['String']['input']>>;
  returningToStudy?: InputMaybe<Scalars['Boolean']['input']>;
  schoolName?: InputMaybe<Scalars['String']['input']>;
  secondarySituation?: InputMaybe<SecondarySituationEnum>;
  showCongratulationsPanel?: InputMaybe<Scalars['Boolean']['input']>;
  showVocationPanel?: InputMaybe<Scalars['Boolean']['input']>;
  showWelcomePanel?: InputMaybe<Scalars['Boolean']['input']>;
  teachedSchoolSubjects?: InputMaybe<Array<Scalars['String']['input']>>;
  userId: Scalars['ID']['input'];
  vocationCoachSignup?: InputMaybe<Scalars['Boolean']['input']>;
  wantToShare?: InputMaybe<Scalars['Boolean']['input']>;
  yearsOfExperience?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationUploadGalleryImageArgs = {
  image: Scalars['Upload']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationVerifyEmployeesListArgs = {
  companyId: Scalars['ID']['input'];
  file: Scalars['Upload']['input'];
};

export type MutationWishAmbassadorArgs = {
  userId: Scalars['ID']['input'];
};

export type MutationWishProfessionalArgs = {
  automaticallyWished?: InputMaybe<Scalars['Boolean']['input']>;
  professionalId: Scalars['ID']['input'];
};

export type Notification = {
  __typename?: 'Notification';
  createdAt?: Maybe<Scalars['String']['output']>;
  deepLink?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  picture?: Maybe<Avatar>;
  pushNotificationSentAt?: Maybe<Scalars['String']['output']>;
  resourceId?: Maybe<Scalars['ID']['output']>;
  resourceType?: Maybe<NotificationResourceTypeEnum>;
  seen?: Maybe<Scalars['Boolean']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<NotificationTypeEnum>;
  userId?: Maybe<Scalars['ID']['output']>;
};

/** The connection type for Notification. */
export type NotificationConnection = {
  __typename?: 'NotificationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<NotificationEdge>>;
  /** A list of nodes. */
  nodes: Array<Notification>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type NotificationEdge = {
  __typename?: 'NotificationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Notification>;
};

export enum NotificationQueryErrorEnum {
  NotFound = 'NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED',
}

export enum NotificationResourceTypeEnum {
  Appointment = 'APPOINTMENT',
  Conversation = 'CONVERSATION',
}

export enum NotificationTypeEnum {
  AppointmentCanceled = 'APPOINTMENT_CANCELED',
  AppointmentComingIn_10Mn = 'APPOINTMENT_COMING_IN_10MN',
  AppointmentCreated = 'APPOINTMENT_CREATED',
  AppointmentModified = 'APPOINTMENT_MODIFIED',
  EvaluationToComplete = 'EVALUATION_TO_COMPLETE',
  NewMessageReceived = 'NEW_MESSAGE_RECEIVED',
}

export type NotificationsFiltersInput = {
  seenEq?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum NotificationsQueryErrorEnum {
  Unauthorized = 'UNAUTHORIZED',
}

export enum OauthErrorEnum {
  /** __Signin__: Needs email confirmation */
  EmailUnconfirmed = 'EMAIL_UNCONFIRMED',
  /** The provider identity is already used by another user */
  IdentityAlreadyUsedByAnotherUser = 'IDENTITY_ALREADY_USED_BY_ANOTHER_USER',
  /** Oauth provider couldn't retrieve access token */
  RequestAccessTokenFailed = 'REQUEST_ACCESS_TOKEN_FAILED',
  /** __Signin__: Needs tutor email confirmation */
  TutorEmailUnconfirmed = 'TUTOR_EMAIL_UNCONFIRMED',
  /** __Signin__: User account is restricted */
  UserAccountRestricted = 'USER_ACCOUNT_RESTRICTED',
  /** __Signin__: User is soft deleted */
  UserSoftDeleted = 'USER_SOFT_DELETED',
}

export type OauthPayload = {
  __typename?: 'OauthPayload';
  accessToken?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  nonce: Scalars['String']['output'];
  refreshToken?: Maybe<Scalars['String']['output']>;
};

export enum OauthProviderEnum {
  FranceTravail = 'FRANCE_TRAVAIL',
  Linkedin = 'LINKEDIN',
}

export enum OpenidConnectMutationErrorEnum {
  /** __Signin__: Needs email confirmation */
  EmailUnconfirmed = 'EMAIL_UNCONFIRMED',
  /** The openid token expired */
  IdentityTokenExpired = 'IDENTITY_TOKEN_EXPIRED',
  /** The openid token has an invalid format */
  IdentityTokenInvalid = 'IDENTITY_TOKEN_INVALID',
  /** The openid token belongs to another provider */
  IdentityTokenInvalidSignature = 'IDENTITY_TOKEN_INVALID_SIGNATURE',
  /** Failed to verify the openid identity */
  OpenidConnectFailed = 'OPENID_CONNECT_FAILED',
  /** __Signin__: Needs tutor email confirmation */
  TutorEmailUnconfirmed = 'TUTOR_EMAIL_UNCONFIRMED',
  /** __Signin__: User account is restricted */
  UserAccountRestricted = 'USER_ACCOUNT_RESTRICTED',
  /** __Signin__: User is soft deleted */
  UserSoftDeleted = 'USER_SOFT_DELETED',
}

export type OpenidConnectPayload = {
  __typename?: 'OpenidConnectPayload';
  accessToken?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  nonce: Scalars['String']['output'];
  refreshToken?: Maybe<Scalars['String']['output']>;
};

export enum OpenidConnectProvider {
  Apple = 'APPLE',
}

export type OrganizationCardBanner = {
  __typename?: 'OrganizationCardBanner';
  original?: Maybe<Image>;
  small?: Maybe<Image>;
  url?: Maybe<Scalars['String']['output']>;
};

export type OrganizationLogo = {
  __typename?: 'OrganizationLogo';
  original?: Maybe<Image>;
  small?: Maybe<Image>;
  smaller?: Maybe<Image>;
  thumb?: Maybe<Image>;
  url?: Maybe<Scalars['String']['output']>;
};

export type OrganizationProfileBannerDesktop = {
  __typename?: 'OrganizationProfileBannerDesktop';
  original?: Maybe<Image>;
  small?: Maybe<Image>;
  url?: Maybe<Scalars['String']['output']>;
};

export type OrganizationProfileBannerMobile = {
  __typename?: 'OrganizationProfileBannerMobile';
  original?: Maybe<Image>;
  small?: Maybe<Image>;
  url?: Maybe<Scalars['String']['output']>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Participation = {
  __typename?: 'Participation';
  certificationStatus?: Maybe<Scalars['String']['output']>;
  confirmedMeetingCount?: Maybe<Scalars['Int']['output']>;
  contacts?: Maybe<Array<HermesContact>>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  hermesContacts?: Maybe<Array<HermesContact>>;
  id: Scalars['ID']['output'];
  program?: Maybe<Program>;
  reportCount?: Maybe<Scalars['Int']['output']>;
  unfollowedConversationCount?: Maybe<Scalars['Int']['output']>;
};

/** A school that is partnered with MJG to operate FDRs */
export type PartnerSchool = {
  __typename?: 'PartnerSchool';
  configurableFdrs: Array<Fdr>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

/** All the versions of a picture */
export enum PictureVersion {
  /** A blurred version of the picture */
  Blurred = 'BLURRED',
  /** The original picture */
  Original = 'ORIGINAL',
  /** The picture resized to 200x200 */
  Small = 'SMALL',
  /** The picture resized to 100x100 */
  Smaller = 'SMALLER',
  /** The picture resized to 50x50 */
  Thumb = 'THUMB',
}

export type PresentationTranslations = {
  __typename?: 'PresentationTranslations';
  ambassadorPresentationDescription: Scalars['String']['output'];
  ambassadorPresentationExample: Scalars['String']['output'];
  ambassadorPresentationQuestion: Scalars['String']['output'];
  ambassadorPresentationTitle: Scalars['String']['output'];
  aspirationsExample: Scalars['String']['output'];
  aspirationsQuestion: Scalars['String']['output'];
  expertisesExample: Scalars['String']['output'];
  expertisesQuestion: Scalars['String']['output'];
  interestsExample: Scalars['String']['output'];
  interestsQuestion: Scalars['String']['output'];
  memberPresentationDescription: Scalars['String']['output'];
  memberPresentationExample: Scalars['String']['output'];
  memberPresentationQuestion: Scalars['String']['output'];
  memberPresentationTitle: Scalars['String']['output'];
};

export type PresentationTranslationsAmbassadorPresentationDescriptionArgs = {
  locale?: InputMaybe<AvailableLanguagesEnum>;
};

export type PresentationTranslationsAmbassadorPresentationExampleArgs = {
  locale?: InputMaybe<AvailableLanguagesEnum>;
};

export type PresentationTranslationsAmbassadorPresentationQuestionArgs = {
  locale?: InputMaybe<AvailableLanguagesEnum>;
};

export type PresentationTranslationsAmbassadorPresentationTitleArgs = {
  locale?: InputMaybe<AvailableLanguagesEnum>;
};

export type PresentationTranslationsAspirationsExampleArgs = {
  locale?: InputMaybe<AvailableLanguagesEnum>;
};

export type PresentationTranslationsAspirationsQuestionArgs = {
  locale?: InputMaybe<AvailableLanguagesEnum>;
};

export type PresentationTranslationsExpertisesExampleArgs = {
  locale?: InputMaybe<AvailableLanguagesEnum>;
};

export type PresentationTranslationsExpertisesQuestionArgs = {
  locale?: InputMaybe<AvailableLanguagesEnum>;
};

export type PresentationTranslationsInterestsExampleArgs = {
  locale?: InputMaybe<AvailableLanguagesEnum>;
};

export type PresentationTranslationsInterestsQuestionArgs = {
  locale?: InputMaybe<AvailableLanguagesEnum>;
};

export type PresentationTranslationsMemberPresentationDescriptionArgs = {
  locale?: InputMaybe<AvailableLanguagesEnum>;
};

export type PresentationTranslationsMemberPresentationExampleArgs = {
  locale?: InputMaybe<AvailableLanguagesEnum>;
};

export type PresentationTranslationsMemberPresentationQuestionArgs = {
  locale?: InputMaybe<AvailableLanguagesEnum>;
};

export type PresentationTranslationsMemberPresentationTitleArgs = {
  locale?: InputMaybe<AvailableLanguagesEnum>;
};

export type PrimarySituation = {
  __typename?: 'PrimarySituation';
  jobTitles?: Maybe<Array<JobTitle>>;
  key: PrimarySituationEnum;
  name: Scalars['String']['output'];
  secondarySituations?: Maybe<Array<SecondarySituation>>;
};

export type PrimarySituationNameArgs = {
  locale?: InputMaybe<AvailableLanguagesEnum>;
};

/** Primary situations */
export enum PrimarySituationEnum {
  JobSeeker = 'JOB_SEEKER',
  JobSeekerSupport = 'JOB_SEEKER_SUPPORT',
  Other = 'OTHER',
  PostBacStudentSupport = 'POST_BAC_STUDENT_SUPPORT',
  ProfessionalAndSecondarySchoolStudentSupport = 'PROFESSIONAL_AND_SECONDARY_SCHOOL_STUDENT_SUPPORT',
  Retired = 'RETIRED',
  Student = 'STUDENT',
  Worker = 'WORKER',
}

/** Ambassador profile queried by its owner */
export type PrivateCompanyEmployeeProfile = PrivateProfile & {
  __typename?: 'PrivateCompanyEmployeeProfile';
  appointments: Array<Appointment>;
  appointmentsCount: Scalars['Int']['output'];
  avatar?: Maybe<Scalars['AvatarUrl']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  jobTitle?: Maybe<Scalars['String']['output']>;
  lastName: Scalars['String']['output'];
  lastUpdatedAt: Scalars['ISO8601DateTime']['output'];
  signupAt: Scalars['ISO8601DateTime']['output'];
  unreadConversationsCount: Scalars['Int']['output'];
};

/** Ambassador profile queried by its owner */
export type PrivateCompanyEmployeeProfileAppointmentsArgs = {
  scope?: InputMaybe<AppointmentListScope>;
};

/** Ambassador profile queried by its owner */
export type PrivateCompanyEmployeeProfileAppointmentsCountArgs = {
  scope?: InputMaybe<AppointmentListScope>;
};

/** Ambassador profile queried by its owner */
export type PrivateCompanyEmployeeProfileAvatarArgs = {
  version?: InputMaybe<PictureVersion>;
};

/** A profile seen by the user who possess this profile */
export type PrivateProfile = {
  avatar?: Maybe<Scalars['AvatarUrl']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
};

/** A profile seen by the user who possess this profile */
export type PrivateProfileAvatarArgs = {
  version?: InputMaybe<PictureVersion>;
};

/** Timeline missions for students */
export type PrivateStudentMissions = {
  __typename?: 'PrivateStudentMissions';
  current: StudentMission;
  next?: Maybe<StudentMission>;
  previous?: Maybe<StudentMission>;
};

/** Student profile queried by its owner */
export type PrivateStudentProfile = PrivateProfile & {
  __typename?: 'PrivateStudentProfile';
  appointments: Array<Appointment>;
  appointmentsCount: Scalars['Int']['output'];
  assignments: Array<StudentParticipation>;
  avatar?: Maybe<Scalars['AvatarUrl']['output']>;
  contactsRemainingCount: Scalars['Int']['output'];
  conversationsCount: Scalars['Int']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  lastUpdatedAt: Scalars['ISO8601DateTime']['output'];
  missions: PrivateStudentMissions;
  responseRate?: Maybe<Scalars['Percentage']['output']>;
  signupAt: Scalars['ISO8601DateTime']['output'];
  uniqueProfileViewsCount: Scalars['Int']['output'];
  unreadConversationsCount: Scalars['Int']['output'];
  wishedProfessionalsCount: Scalars['Int']['output'];
};

/** Student profile queried by its owner */
export type PrivateStudentProfileAppointmentsArgs = {
  scope?: InputMaybe<AppointmentListScope>;
};

/** Student profile queried by its owner */
export type PrivateStudentProfileAppointmentsCountArgs = {
  scope?: InputMaybe<AppointmentListScope>;
};

/** Student profile queried by its owner */
export type PrivateStudentProfileAssignmentsArgs = {
  scope?: InputMaybe<AssignmentsListScope>;
};

/** Student profile queried by its owner */
export type PrivateStudentProfileAvatarArgs = {
  version?: InputMaybe<PictureVersion>;
};

export enum ProfessionalProfileQueryErrorEnum {
  NotFound = 'NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED',
}

/** All the possible type of a professional profile */
export enum ProfessionalProfileType {
  /** translation missing: en.graphql.schema.enums.professional_profile.employee */
  Employee = 'EMPLOYEE',
  /** translation missing: en.graphql.schema.enums.professional_profile.mentor */
  Mentor = 'MENTOR',
  /** translation missing: en.graphql.schema.enums.professional_profile.vip */
  Vip = 'VIP',
}

/** List of profiles */
export enum Profile {
  /** ambassador */
  Ambassador = 'AMBASSADOR',
  /** company admin */
  CompanyAdmin = 'COMPANY_ADMIN',
  /** expert */
  Expert = 'EXPERT',
  /** explorer */
  Explorer = 'EXPLORER',
  /** mentor */
  Mentor = 'MENTOR',
  /** mjg admin */
  MjgAdmin = 'MJG_ADMIN',
  /** moderator */
  Moderator = 'MODERATOR',
  /** school coach */
  SchoolCoach = 'SCHOOL_COACH',
  /** student */
  Student = 'STUDENT',
}

export type ProfileEducation = {
  __typename?: 'ProfileEducation';
  diplomaTitle?: Maybe<Scalars['String']['output']>;
  diplomaType?: Maybe<DiplomaEnum>;
  endedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['ID']['output'];
  schoolName: Scalars['String']['output'];
  startedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  user: AmbivalentUser;
};

export type Program = {
  __typename?: 'Program';
  author?: Maybe<CollaborationUser>;
  authorFirstName?: Maybe<Scalars['String']['output']>;
  collaborations?: Maybe<Array<ProgramCollaboration>>;
  description?: Maybe<Scalars['String']['output']>;
  endAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['ID']['output'];
  meetingsQuantity?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organizationName: Scalars['String']['output'];
  programTemplate?: Maybe<ProgramProgramTemplate>;
  project?: Maybe<ProgramProject>;
  startAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  templateName: Scalars['String']['output'];
};

export type ProgramCollaboration = {
  __typename?: 'ProgramCollaboration';
  id: Scalars['ID']['output'];
  user?: Maybe<CollaborationUser>;
};

export type ProgramProgramTemplate = {
  __typename?: 'ProgramProgramTemplate';
  displayedName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type ProgramProject = {
  __typename?: 'ProgramProject';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<ProjectOrganization>;
};

export type ProjectOrganization = {
  __typename?: 'ProjectOrganization';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

/** A public professional profile */
export type PublicProfessionalProfile = PublicProfile & {
  __typename?: 'PublicProfessionalProfile';
  ambassadorPresentation?: Maybe<Scalars['String']['output']>;
  aspirationPresentation?: Maybe<Scalars['String']['output']>;
  avatar?: Maybe<Scalars['AvatarUrl']['output']>;
  avatars: Avatar;
  banner?: Maybe<OrganizationCardBanner>;
  birthdate?: Maybe<Scalars['ISO8601Date']['output']>;
  companyDescription?: Maybe<Scalars['String']['output']>;
  companyLogo?: Maybe<Scalars['LogoUrl']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  companyRecruitingWorkingAreas?: Maybe<Array<Scalars['String']['output']>>;
  companySector?: Maybe<Scalars['String']['output']>;
  companySize: CompanySize;
  currentGrade?: Maybe<Grade>;
  currentSchoolLogo?: Maybe<OrganizationLogo>;
  currentSchoolName?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  educations: Array<ProfileEducation>;
  email?: Maybe<Scalars['String']['output']>;
  expertisePresentation?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  firstPublishedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  galleryImages: Array<GalleryImage>;
  id: Scalars['ID']['output'];
  interestsPresentation?: Maybe<Scalars['String']['output']>;
  isPaidAmbassador: Scalars['Boolean']['output'];
  isSubsidiaryCompany: Scalars['Boolean']['output'];
  jobTitle?: Maybe<Scalars['String']['output']>;
  languages: Array<Language>;
  lastName?: Maybe<Scalars['String']['output']>;
  likeCount?: Maybe<Scalars['Int']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  meetingPlace?: Maybe<Scalars['String']['output']>;
  meetingPreferences?: Maybe<Array<MeetingPreference>>;
  meetingsLeft: Scalars['Int']['output'];
  memberPresentation?: Maybe<Scalars['String']['output']>;
  onlyFrenchWarning: Scalars['Boolean']['output'];
  onlyFrenchWarningConfirmText?: Maybe<Scalars['String']['output']>;
  onlyFrenchWarningText?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  positiveLikeCount: Scalars['Int']['output'];
  preparedDiplomaTitle?: Maybe<Scalars['String']['output']>;
  presentation?: Maybe<Scalars['String']['output']>;
  previousCompanyName?: Maybe<Scalars['String']['output']>;
  previousJobTitle?: Maybe<Scalars['String']['output']>;
  primarySituation?: Maybe<PrimarySituation>;
  profileType: ProfessionalProfileType;
  profileViews: Scalars['Int']['output'];
  published: Scalars['Boolean']['output'];
  recommendedProfessionals: Array<AmbassadorResults>;
  recommendedQualifications?: Maybe<Array<Scalars['String']['output']>>;
  responseRate?: Maybe<Scalars['Percentage']['output']>;
  responseTime?: Maybe<Scalars['Int']['output']>;
  secondarySituation?: Maybe<SecondarySituation>;
  similarProfessionals: PublicProfessionalProfileConnection;
  skills: Array<Scalars['String']['output']>;
  status: PublicProfessionalProfileStatus;
  studyLevels: Array<StudyLevel>;
  subsidiaryDesignationEn?: Maybe<Scalars['String']['output']>;
  subsidiaryDesignationFr?: Maybe<Scalars['String']['output']>;
  unavailableUntil?: Maybe<Scalars['ISO8601DateTime']['output']>;
  user?: Maybe<AmbivalentUser>;
  userId: Scalars['ID']['output'];
  userMetTag?: Maybe<UserMetTag>;
  visibilityStatus: VisibilityStatusEnum;
};

/** A public professional profile */
export type PublicProfessionalProfileAvatarArgs = {
  version?: InputMaybe<PictureVersion>;
};

/** A public professional profile */
export type PublicProfessionalProfileSimilarProfessionalsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for PublicProfessionalProfile. */
export type PublicProfessionalProfileConnection = {
  __typename?: 'PublicProfessionalProfileConnection';
  /** A list of edges. */
  edges?: Maybe<Array<PublicProfessionalProfileEdge>>;
  /** A list of nodes. */
  nodes: Array<PublicProfessionalProfile>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type PublicProfessionalProfileEdge = {
  __typename?: 'PublicProfessionalProfileEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<PublicProfessionalProfile>;
};

/** All the possible status of a public profile */
export enum PublicProfessionalProfileStatus {
  /** translation missing: en.graphql.schema.enums.public_profile_status.anonymized */
  Anonymized = 'ANONYMIZED',
  /** translation missing: en.graphql.schema.enums.public_profile_status.hibernated */
  Hibernated = 'HIBERNATED',
  /** translation missing: en.graphql.schema.enums.public_profile_status.iced_up_or_restricted */
  IcedUpOrRestricted = 'ICED_UP_OR_RESTRICTED',
  /** translation missing: en.graphql.schema.enums.public_profile_status.never_published */
  NeverPublished = 'NEVER_PUBLISHED',
  /** translation missing: en.graphql.schema.enums.public_profile_status.published */
  Published = 'PUBLISHED',
  /** translation missing: en.graphql.schema.enums.public_profile_status.red_carpet_only */
  RedCarpetOnly = 'RED_CARPET_ONLY',
  /** translation missing: en.graphql.schema.enums.public_profile_status.soft_deleted */
  SoftDeleted = 'SOFT_DELETED',
  /** translation missing: en.graphql.schema.enums.public_profile_status.unavailable */
  Unavailable = 'UNAVAILABLE',
  /** translation missing: en.graphql.schema.enums.public_profile_status.unpublished */
  Unpublished = 'UNPUBLISHED',
}

/** A public profile */
export type PublicProfile = {
  avatar?: Maybe<Scalars['AvatarUrl']['output']>;
  avatars: Avatar;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  userId: Scalars['ID']['output'];
};

/** A public profile */
export type PublicProfileAvatarArgs = {
  version?: InputMaybe<PictureVersion>;
};

/** A public student profile */
export type PublicStudentProfile = PublicProfile & {
  __typename?: 'PublicStudentProfile';
  avatar?: Maybe<Scalars['AvatarUrl']['output']>;
  avatars: Avatar;
  birthdate?: Maybe<Scalars['ISO8601Date']['output']>;
  confirmedAppointmentCount: Scalars['Int']['output'];
  description?: Maybe<Scalars['String']['output']>;
  educations: Array<Education>;
  email?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  primarySituation?: Maybe<PrimarySituation>;
  professionalProjects: Array<StudentProfessionalProject>;
  secondarySituation?: Maybe<SecondarySituation>;
  status: StudentStatus;
  userId: Scalars['ID']['output'];
};

/** A public student profile */
export type PublicStudentProfileAvatarArgs = {
  version?: InputMaybe<PictureVersion>;
};

export enum PublicationStatuses {
  ChangesToBeCommitted = 'CHANGES_TO_BE_COMMITTED',
  PublicationRequested = 'PUBLICATION_REQUESTED',
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED',
}

export enum PublishErrorEnum {
  CannotPublishUserIcedUp = 'CANNOT_PUBLISH_USER_ICED_UP',
  CannotPublishUserInvalidated = 'CANNOT_PUBLISH_USER_INVALIDATED',
  CannotPublishUserNotCompleted = 'CANNOT_PUBLISH_USER_NOT_COMPLETED',
  CannotPublishUserPackIsFull = 'CANNOT_PUBLISH_USER_PACK_IS_FULL',
  CannotPublishUserSoftDeleted = 'CANNOT_PUBLISH_USER_SOFT_DELETED',
  CannotPublishUserUnapproved = 'CANNOT_PUBLISH_USER_UNAPPROVED',
  /** unauthorized */
  Unauthorized = 'UNAUTHORIZED',
  /** User already published */
  UserAlreadyPublished = 'USER_ALREADY_PUBLISHED',
  /** User cannot be published */
  UserCannotBePublished = 'USER_CANNOT_BE_PUBLISHED',
}

export type Query = {
  __typename?: 'Query';
  affiliatedAssignments: Array<StudentParticipation>;
  appointment?: Maybe<Appointment>;
  appointments?: Maybe<AppointmentConnection>;
  /** Autocomplete for professionals using the elasticsearch services */
  autocompleteAmbassadors: Array<AmbassadorAutocompleteResult>;
  checkEmailAvailability: CheckEmailAvailabilityQueryPayload;
  /** Check the validity of invitation_token depending on flow */
  checkInvitationToken?: Maybe<UserInvitation>;
  companies?: Maybe<Array<Company>>;
  company?: Maybe<Company>;
  companyEmployee?: Maybe<CompanyEmployee>;
  companyEmployeeAppointment?: Maybe<CompanyEmployeeAppointment>;
  companyEmployeeAppointments: CompanyEmployeeAppointmentsPayload;
  companyEmployees?: Maybe<CompanyEmployeeConnection>;
  companyReport?: Maybe<CompanyReport>;
  /** A set that contains all of the company sectors */
  companySectors?: Maybe<Array<CompanySector>>;
  configuredAssignment: CoachAssignment;
  configuredAssignments?: Maybe<Array<CoachAssignment>>;
  conversation?: Maybe<Conversation>;
  conversations?: Maybe<ConversationConnection>;
  crmFeatureActivated: Scalars['Boolean']['output'];
  currentUser?: Maybe<User>;
  currentUserV2?: Maybe<AmbivalentUser>;
  defaultMessageTemplates: Array<DefaultMessageTemplate>;
  departments?: Maybe<Array<Scalars['String']['output']>>;
  getUser: AmbivalentUser;
  /** Search for schools based on text input and additional filters. */
  gouvSchoolSearch: Array<GouvSchoolSearchResults>;
  /** Get all mandatory skills */
  mandatorySkillsAutocomplete: Array<MandatorySkill>;
  me: PrivateProfile;
  mjgEmployees: Array<MjgEmployee>;
  mobileNotificationSubscriptions: MobileNotificationSubscriptions;
  myProfile: PrivateProfile;
  notification: Notification;
  notifications: NotificationConnection;
  partnerSchool?: Maybe<PartnerSchool>;
  professionalProfile?: Maybe<PublicProfessionalProfile>;
  professionalRecruitingWorkingAreas: Array<Scalars['String']['output']>;
  program?: Maybe<Program>;
  /** Search for professionals using the elasticsearch services */
  searchAmbassadors: AmbassadorSearchResult;
  /** Gets the facets for the professional search with the document count for each key. */
  searchFacets: Array<SearchFacet>;
  searchSchoolSubjects: Array<Scalars['String']['output']>;
  settings?: Maybe<Settings>;
  user: AmbivalentUser;
  userMessageTemplates: Array<UserMessageTemplate>;
  users?: Maybe<UserConnection>;
  wishlist?: Maybe<PublicProfessionalProfileConnection>;
};

export type QueryAppointmentArgs = {
  id: Scalars['ID']['input'];
};

export type QueryAppointmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<AppointmentsFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AppointmentsSortFieldsEnum>>;
};

export type QueryAutocompleteAmbassadorsArgs = {
  searchMode?: InputMaybe<SearchTypeEnum>;
  term: Array<Scalars['String']['input']>;
};

export type QueryCheckEmailAvailabilityArgs = {
  email: Scalars['String']['input'];
};

export type QueryCheckInvitationTokenArgs = {
  flow: Profile;
  invitationToken: Scalars['String']['input'];
};

export type QueryCompaniesArgs = {
  asAdmin?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryCompanyArgs = {
  companyId: Scalars['ID']['input'];
};

export type QueryCompanyEmployeeArgs = {
  employeeId: Scalars['ID']['input'];
};

export type QueryCompanyEmployeeAppointmentArgs = {
  appointmentId: Scalars['ID']['input'];
};

export type QueryCompanyEmployeeAppointmentsArgs = {
  companyId: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryCompanyEmployeesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['ID']['input'];
  filters?: InputMaybe<CompenyEmployeesQueryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<CompanyEmployeesQuerySortEnum>>;
};

export type QueryCompanyReportArgs = {
  companyId: Scalars['ID']['input'];
  range?: CompanyReportRange;
};

export type QueryConfiguredAssignmentArgs = {
  assignmentId: Scalars['ID']['input'];
};

export type QueryConversationArgs = {
  conversationId: Scalars['ID']['input'];
};

export type QueryConversationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ConversationsFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryCrmFeatureActivatedArgs = {
  professionalUserId: Scalars['ID']['input'];
};

export type QueryDefaultMessageTemplatesArgs = {
  userId: Scalars['ID']['input'];
};

export type QueryDepartmentsArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetUserArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGouvSchoolSearchArgs = {
  filters?: InputMaybe<GouvSchoolSearchFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
};

export type QueryMandatorySkillsAutocompleteArgs = {
  term: Scalars['String']['input'];
};

export type QueryNotificationArgs = {
  id: Scalars['ID']['input'];
};

export type QueryNotificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<NotificationsFiltersInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryProfessionalProfileArgs = {
  professionalId: Scalars['ID']['input'];
};

export type QueryProfessionalRecruitingWorkingAreasArgs = {
  professionalUserId: Scalars['ID']['input'];
};

export type QueryProgramArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  participationCode?: InputMaybe<Scalars['ID']['input']>;
  programInvitationId?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerySearchAmbassadorsArgs = {
  availableAmbassadors?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  searchFacets?: InputMaybe<Array<SearchFacetInput>>;
  searchGooglePlaceId?: InputMaybe<Scalars['String']['input']>;
  searchMode?: InputMaybe<SearchTypeEnum>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
};

export type QuerySearchFacetsArgs = {
  availableAmbassadors?: InputMaybe<Scalars['Boolean']['input']>;
  searchFacets?: InputMaybe<Array<SearchFacetInput>>;
  searchGooglePlaceId?: InputMaybe<Scalars['String']['input']>;
  searchMode?: InputMaybe<SearchTypeEnum>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
};

export type QuerySearchSchoolSubjectsArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  term: Scalars['String']['input'];
};

export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};

export type QueryUserMessageTemplatesArgs = {
  userId: Scalars['ID']['input'];
};

export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UsersFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryWishlistArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export enum RefreshAccessTokenErrorEnum {
  /** Invalid refresh token */
  InvalidRefreshToken = 'INVALID_REFRESH_TOKEN',
}

/** Autogenerated return type of RefreshAccessToken. */
export type RefreshAccessTokenPayload = {
  __typename?: 'RefreshAccessTokenPayload';
  accessToken: Scalars['String']['output'];
};

/** Autogenerated return type of RemoveRecruitingWorkingArea. */
export type RemoveRecruitingWorkingAreaPayload = {
  __typename?: 'RemoveRecruitingWorkingAreaPayload';
  result: Scalars['Boolean']['output'];
  workingAreas: Array<Scalars['String']['output']>;
};

/** Report a member */
export type Report = {
  __typename?: 'Report';
  explanation: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  reason: ReportReason;
  reportedId: Scalars['ID']['output'];
};

export enum ReportAppointmentDidNotTookPlaceMutationErrorEnum {
  AlreadyReported = 'ALREADY_REPORTED',
  CannotReportFutureAppointment = 'CANNOT_REPORT_FUTURE_APPOINTMENT',
  CannotReportReviewedAppointment = 'CANNOT_REPORT_REVIEWED_APPOINTMENT',
  DescriptionRequired = 'DESCRIPTION_REQUIRED',
  NotFound = 'NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED',
  UnexpectedError = 'UNEXPECTED_ERROR',
}

export enum ReportMemberErrorEnum {
  MustHaveConversationTogether = 'MUST_HAVE_CONVERSATION_TOGETHER',
  NotFound = 'NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED',
}

export enum ReportReason {
  Behavior = 'BEHAVIOR',
  DidNotShow = 'DID_NOT_SHOW',
  IsRobot = 'IS_ROBOT',
  IsSpam = 'IS_SPAM',
  NotResponding = 'NOT_RESPONDING',
  Other = 'OTHER',
}

export enum RequestPublicationErrorEnum {
  CannotRequestPublication = 'CANNOT_REQUEST_PUBLICATION',
  CannotRequestPublicationUserFreemiumCannotRequestPublication = 'CANNOT_REQUEST_PUBLICATION_USER_FREEMIUM_CANNOT_REQUEST_PUBLICATION',
  CannotRequestPublicationUserModerationOngoing = 'CANNOT_REQUEST_PUBLICATION_USER_MODERATION_ONGOING',
  CannotRequestPublicationUserNotCompleted = 'CANNOT_REQUEST_PUBLICATION_USER_NOT_COMPLETED',
  CannotRequestPublicationUserPremiumCannotRequestPublication = 'CANNOT_REQUEST_PUBLICATION_USER_PREMIUM_CANNOT_REQUEST_PUBLICATION',
  CannotRequestPublicationUserSoftDeleted = 'CANNOT_REQUEST_PUBLICATION_USER_SOFT_DELETED',
  Unauthorized = 'UNAUTHORIZED',
}

export enum ReviewAppointmentAsAmbassadorMutationErrorEnum {
  CannotReviewFutureAppointment = 'CANNOT_REVIEW_FUTURE_APPOINTMENT',
  CurrentUserAlreadyReviewed = 'CURRENT_USER_ALREADY_REVIEWED',
  CurrentUserIsNotTheAmbassador = 'CURRENT_USER_IS_NOT_THE_AMBASSADOR',
  MessageForHrRequired = 'MESSAGE_FOR_HR_REQUIRED',
  NotFound = 'NOT_FOUND',
  Question_2Required = 'QUESTION_2_REQUIRED',
  Question_3Required = 'QUESTION_3_REQUIRED',
  Unauthorized = 'UNAUTHORIZED',
  UnexpectedValidationError = 'UNEXPECTED_VALIDATION_ERROR',
}

export enum ReviewAppointmentAsMemberMutationErrorEnum {
  AspirationStartAtMustBeFuture = 'ASPIRATION_START_AT_MUST_BE_FUTURE',
  CannotReviewFutureAppointment = 'CANNOT_REVIEW_FUTURE_APPOINTMENT',
  CurrentUserAlreadyReviewed = 'CURRENT_USER_ALREADY_REVIEWED',
  CurrentUserIsNotTheMember = 'CURRENT_USER_IS_NOT_THE_MEMBER',
  NotFound = 'NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED',
  UnexpectedValidationError = 'UNEXPECTED_VALIDATION_ERROR',
  WorkingAreaNotInTheList = 'WORKING_AREA_NOT_IN_THE_LIST',
}

export enum SaveNotificationTokenErrorEnum {
  Unauthorized = 'UNAUTHORIZED',
}

export type SaveTokenFields = {
  __typename?: 'SaveTokenFields';
  notificationPushToken: Scalars['String']['output'];
};

/** A school Aspiration */
export type SchoolAspiration = {
  __typename?: 'SchoolAspiration';
  grade?: Maybe<Grade>;
  year?: Maybe<Scalars['String']['output']>;
};

export type SchoolAspirationInput = {
  grade?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['String']['input']>;
};

/** A school coach profile */
export type SchoolCoach = PublicProfile & {
  __typename?: 'SchoolCoach';
  avatar?: Maybe<Scalars['AvatarUrl']['output']>;
  avatars: Avatar;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  status: SchoolCoachStatus;
  userId: Scalars['ID']['output'];
};

/** A school coach profile */
export type SchoolCoachAvatarArgs = {
  version?: InputMaybe<PictureVersion>;
};

/** The status of the school coach */
export enum SchoolCoachStatus {
  /** Anonymized */
  Anonymized = 'ANONYMIZED',
  /** Available */
  Available = 'AVAILABLE',
  /** Soft deleted */
  SoftDeleted = 'SOFT_DELETED',
}

export type SchoolInterestAvailableDates = {
  __typename?: 'SchoolInterestAvailableDates';
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type SchoolInterestAvailableDatesNameArgs = {
  locale?: InputMaybe<AvailableLanguagesEnum>;
};

export type SchoolInterestAvailableGrades = {
  __typename?: 'SchoolInterestAvailableGrades';
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type SchoolInterestAvailableGradesNameArgs = {
  locale?: InputMaybe<AvailableLanguagesEnum>;
};

export type SchoolTeacherDetails = {
  schoolName?: InputMaybe<Scalars['String']['input']>;
  schoolTown?: InputMaybe<Scalars['String']['input']>;
  schoolType?: InputMaybe<Scalars['String']['input']>;
  schoolZipCode?: InputMaybe<Scalars['String']['input']>;
};

export enum SearchAmbassadorsQueryErrorEnum {
  InvalidGooglePlaceId = 'INVALID_GOOGLE_PLACE_ID',
  /** page must be >= 0 */
  PageInvalid = 'PAGE_INVALID',
  /** perPage must be between 1 and 999 */
  PerPageInvalid = 'PER_PAGE_INVALID',
}

export type SearchFacet = {
  __typename?: 'SearchFacet';
  buckets: Array<Bucket>;
  key: FacetKeyEnum;
};

export type SearchFacetInput = {
  key: FacetKeyEnum;
  values: Array<Scalars['String']['input']>;
};

export enum SearchFacetsQueryErrorEnum {
  InvalidGooglePlaceId = 'INVALID_GOOGLE_PLACE_ID',
}

export enum SearchTypeEnum {
  OnlyProfessionals = 'ONLY_PROFESSIONALS',
  OnlySchoolTeachers = 'ONLY_SCHOOL_TEACHERS',
  OnlyStudents = 'ONLY_STUDENTS',
}

export type SecondarySituation = {
  __typename?: 'SecondarySituation';
  grades?: Maybe<Array<GradeEnum>>;
  gradesV2?: Maybe<Array<Grade>>;
  key: SecondarySituationEnum;
  name: Scalars['String']['output'];
  primarySituation?: Maybe<PrimarySituation>;
};

export type SecondarySituationNameArgs = {
  locale?: InputMaybe<AvailableLanguagesEnum>;
};

/** Secondary situations */
export enum SecondarySituationEnum {
  Autre = 'AUTRE',
  Cfa = 'CFA',
  College = 'COLLEGE',
  EcoleCommerce = 'ECOLE_COMMERCE',
  EcoleIngenieurs = 'ECOLE_INGENIEURS',
  Iut = 'IUT',
  LyceeAgr = 'LYCEE_AGR',
  LyceeGt = 'LYCEE_GT',
  LyceePro = 'LYCEE_PRO',
  Universite = 'UNIVERSITE',
}

export enum SendMessageMutationErrorEnum {
  ConversationDisabled = 'CONVERSATION_DISABLED',
  DuplicateMessage = 'DUPLICATE_MESSAGE',
  NotFound = 'NOT_FOUND',
  TextBlank = 'TEXT_BLANK',
  Unauthorized = 'UNAUTHORIZED',
}

/** Application settings */
export type Settings = {
  __typename?: 'Settings';
  comesFrom: Array<ComesFrom>;
  diplomas: Array<Diploma>;
  franceLevel1AdministrativeAreas: Array<FranceLevel1AdministrativeArea>;
  franceTravailAuthorizationUrl: Scalars['String']['output'];
  franceTravailMobileOauthRedirectUri: Scalars['String']['output'];
  franceTravailOauthClientId: Scalars['String']['output'];
  franceTravailOauthRealm: Scalars['String']['output'];
  franceTravailOauthScope: Scalars['String']['output'];
  grades: Array<Grade>;
  internshipTypes: Array<InternshipType>;
  jobSeekerSupportOrganizationTypes: Array<JobSeekerSupportOrganizationType>;
  jobSeekerSupportPrograms: Array<JobSeekerSupportProgram>;
  jobTitles: Array<JobTitle>;
  laReunionGooglePlaceId: Scalars['String']['output'];
  laReunionMeetingPlace: Scalars['String']['output'];
  linkedinAuthorizationUrl: Scalars['String']['output'];
  linkedinMobileOauthRedirectUri: Scalars['String']['output'];
  linkedinOauthClientId: Scalars['String']['output'];
  linkedinOauthScope: Scalars['String']['output'];
  primarySituations: Array<PrimarySituation>;
  recommendedQualifications: Array<Scalars['String']['output']>;
  secondarySituations: Array<SecondarySituation>;
};

/** Application settings */
export type SettingsLaReunionMeetingPlaceArgs = {
  locale?: InputMaybe<AvailableLanguagesEnum>;
};

export enum SigninErrorEnum {
  /** __Signin__: Needs email confirmation */
  EmailUnconfirmed = 'EMAIL_UNCONFIRMED',
  /** User not found error */
  NotFound = 'NOT_FOUND',
  /** __Signin__: Needs tutor email confirmation */
  TutorEmailUnconfirmed = 'TUTOR_EMAIL_UNCONFIRMED',
  /** __Signin__: User account is restricted */
  UserAccountRestricted = 'USER_ACCOUNT_RESTRICTED',
  /** __Signin__: User is soft deleted */
  UserSoftDeleted = 'USER_SOFT_DELETED',
}

export enum SignupErrorEnum {
  /**
   * __Validation error__:
   *   can't be blank
   */
  BirthdateBlank = 'BIRTHDATE_BLANK',
  /**
   * __Validation error__:
   *   can't be blank
   */
  EmailBlank = 'EMAIL_BLANK',
  /**
   * __Validation error__:
   *   Email domain is blacklisted
   *
   *
   * - blacklist => "private"
   */
  EmailForbiddenDomain = 'EMAIL_FORBIDDEN_DOMAIN',
  /**
   * __Validation error__:
   *   is invalid
   *
   *
   * - with => "?"
   */
  EmailInvalid = 'EMAIL_INVALID',
  /**
   * __Validation error__:
   *   Email is already used
   */
  EmailTaken = 'EMAIL_TAKEN',
  /**
   * __Validation error__:
   *   Email is already used, but it has not been confirmed
   */
  EmailTakenAndUnconfirmed = 'EMAIL_TAKEN_AND_UNCONFIRMED',
  /**
   * __Validation error__:
   *   Email is already used, by a soft deleted user
   */
  EmailTakenBySoftDeletedUser = 'EMAIL_TAKEN_BY_SOFT_DELETED_USER',
  /** __Signin__: Needs email confirmation */
  EmailUnconfirmed = 'EMAIL_UNCONFIRMED',
  /** The provider identity is already used by another user */
  IdentityAlreadyUsedByAnotherUser = 'IDENTITY_ALREADY_USED_BY_ANOTHER_USER',
  /** Identity oauthNonce is not linked to any provider account */
  IdentityNotFound = 'IDENTITY_NOT_FOUND',
  /**
   * __Validation error__:
   *   Invitation token has already been used by another user
   */
  InvitationTokenAlreadyClaimed = 'INVITATION_TOKEN_ALREADY_CLAIMED',
  /**
   * __Validation error__:
   *   can't be blank
   */
  TutorEmailBlank = 'TUTOR_EMAIL_BLANK',
  /**
   * __Validation error__:
   *   is invalid
   *
   *
   * - with => "?"
   */
  TutorEmailInvalid = 'TUTOR_EMAIL_INVALID',
  /**
   * __Validation error__:
   *   Tutor email must be different from user email
   */
  TutorEmailMustBeDifferent = 'TUTOR_EMAIL_MUST_BE_DIFFERENT',
  /** __Signin__: Needs tutor email confirmation */
  TutorEmailUnconfirmed = 'TUTOR_EMAIL_UNCONFIRMED',
  /** __Signin__: User account is restricted */
  UserAccountRestricted = 'USER_ACCOUNT_RESTRICTED',
  /** __Signin__: User is soft deleted */
  UserSoftDeleted = 'USER_SOFT_DELETED',
}

export enum SnoozeReviewMutationErrorEnum {
  CannotSnoozeFutureAppointment = 'CANNOT_SNOOZE_FUTURE_APPOINTMENT',
  CannotSnoozeReviewedAppointment = 'CANNOT_SNOOZE_REVIEWED_APPOINTMENT',
  NotFound = 'NOT_FOUND',
  ReviewAlreadySnoozed = 'REVIEW_ALREADY_SNOOZED',
  Unauthorized = 'UNAUTHORIZED',
  UnexpectedError = 'UNEXPECTED_ERROR',
}

export enum SoftDeleteMutationErrorEnum {
  /** User is already soft deleted */
  AlreadySoftDeleted = 'ALREADY_SOFT_DELETED',
  /** User id is not found */
  NotFound = 'NOT_FOUND',
  /** Only Admin RH can soft delete a premium ambassador account */
  OnlyAdminCanSoftDelete = 'ONLY_ADMIN_CAN_SOFT_DELETE',
  /** You're not allowed to soft delete this user */
  Unauthorized = 'UNAUTHORIZED',
  /** Cannot soft delete a user with future appointments */
  UserHaveFutureAppointments = 'USER_HAVE_FUTURE_APPOINTMENTS',
}

/** A student Aspiration */
export type StudentAspiration = {
  __typename?: 'StudentAspiration';
  duration: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  startsAtMonth: Scalars['Int']['output'];
  startsAtYear: Scalars['Int']['output'];
  type: StudentProfessionalProjectType;
};

/** an appointment made by a student while being in an assignment */
export type StudentAssignmentAppointment = {
  __typename?: 'StudentAssignmentAppointment';
  claim: Scalars['Boolean']['output'];
  conversation?: Maybe<Conversation>;
  from: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  status: StudentAssignmentAppointmentStatus;
};

/** The status of an appointment belonging to an assignment */
export enum StudentAssignmentAppointmentStatus {
  /** Confirmed */
  Confirmed = 'CONFIRMED',
  /** Invalidated */
  Invalidated = 'INVALIDATED',
  /** Pending */
  Pending = 'PENDING',
}

/** Student mission */
export enum StudentMission {
  /** Account created */
  AccountCreated = 'ACCOUNT_CREATED',
  /** Fifteenth appointment occured */
  FifteenthAppointmentOccurred = 'FIFTEENTH_APPOINTMENT_OCCURRED',
  /** Fifth appointment occurred */
  FifthAppointmentOccurred = 'FIFTH_APPOINTMENT_OCCURRED',
  /** Fiftieth appointment occured */
  FiftiethAppointmentOccurred = 'FIFTIETH_APPOINTMENT_OCCURRED',
  /** First appointment occured */
  FirstAppointmentOccurred = 'FIRST_APPOINTMENT_OCCURRED',
  /** First appointment review */
  FirstAppointmentReview = 'FIRST_APPOINTMENT_REVIEW',
  /** First message sent */
  FirstMessageSent = 'FIRST_MESSAGE_SENT',
  /** Hundredth appointment occurred */
  HundredthAppointmentOccurred = 'HUNDREDTH_APPOINTMENT_OCCURRED',
  /** Profile completed */
  ProfileComplete = 'PROFILE_COMPLETE',
  /** Seventy fifth appointment occurred */
  SeventyFifthAppointmentOccurred = 'SEVENTY_FIFTH_APPOINTMENT_OCCURRED',
  /** Tenth appointment occurred */
  TenthAppointmentOccurred = 'TENTH_APPOINTMENT_OCCURRED',
  /** Third appointment occurred */
  ThirdAppointmentOccurred = 'THIRD_APPOINTMENT_OCCURRED',
  /** Thithieth appointment occured */
  ThirthiethAppointmentOccurred = 'THIRTHIETH_APPOINTMENT_OCCURRED',
  /** Twentieth appointment occured */
  TwentiethAppointmentOccurred = 'TWENTIETH_APPOINTMENT_OCCURRED',
}

/** A participation viewed by a student */
export type StudentParticipation = {
  __typename?: 'StudentParticipation';
  appointments: Array<StudentAssignmentAppointment>;
  appointmentsGoal: Scalars['Int']['output'];
  coach?: Maybe<SchoolCoach>;
  confirmedAppointmentsCount: Scalars['Int']['output'];
  description: Scalars['String']['output'];
  endsAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  schoolName: Scalars['String']['output'];
  startsAt: Scalars['ISO8601DateTime']['output'];
};

/** An FDR assignment viewed by a student */
export type StudentProfessionalProject = {
  __typename?: 'StudentProfessionalProject';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  startsAtMonth: Scalars['Int']['output'];
  startsAtYear: Scalars['Int']['output'];
  status: StudentProfessionalProjectStatus;
  type: StudentProfessionalProjectType;
};

/** Student professional project status */
export enum StudentProfessionalProjectStatus {
  /** Have found */
  HaveFound = 'HAVE_FOUND',
  /** Looking for */
  LookingFor = 'LOOKING_FOR',
}

/** Student professional project type */
export enum StudentProfessionalProjectType {
  /** Apprenticeship */
  Apprenticeship = 'APPRENTICESHIP',
  /** Fixed-term contract job */
  Cdd = 'CDD',
  /** Open-ended contract job */
  Cdi = 'CDI',
  /** Internship */
  Internship = 'INTERNSHIP',
  /** International volunteering in administration */
  Via = 'VIA',
  /** International volunteering in business */
  Vie = 'VIE',
}

/** All the possible status of a student profile */
export enum StudentStatus {
  /** The student is anonymized */
  Anonymized = 'ANONYMIZED',
  /** the student is available */
  Available = 'AVAILABLE',
  /** The student is soft deleted */
  SoftDeleted = 'SOFT_DELETED',
}

export enum StudyLevel {
  Bac_2 = 'BAC_2',
  Bac_3 = 'BAC_3',
  Bac_4 = 'BAC_4',
  Bac_5 = 'BAC_5',
  BacGt = 'BAC_GT',
  BacPro = 'BAC_PRO',
  BacSuperior_5 = 'BAC_SUPERIOR_5',
  BepCap = 'BEP_CAP',
  None = 'NONE',
  NoStudyLevelRequired = 'NO_STUDY_LEVEL_REQUIRED',
  SpecificFormation = 'SPECIFIC_FORMATION',
}

export enum UnbookAppointmentMutationErrorEnum {
  AlreadyCancelled = 'ALREADY_CANCELLED',
  BookAppointmentUnauthorized = 'BOOK_APPOINTMENT_UNAUTHORIZED',
  CannotCancelPastAppointment = 'CANNOT_CANCEL_PAST_APPOINTMENT',
  MustBeTheConversationRecipient = 'MUST_BE_THE_CONVERSATION_RECIPIENT',
  NotFound = 'NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED',
}

export enum UnpublishErrorEnum {
  CannotUnpublishUser = 'CANNOT_UNPUBLISH_USER',
  CannotUnpublishUserNotPublished = 'CANNOT_UNPUBLISH_USER_NOT_PUBLISHED',
  Unauthorized = 'UNAUTHORIZED',
}

export enum UnwishAmbassadorMutationErrorEnum {
  /** not found */
  NotFound = 'NOT_FOUND',
  /** already wished */
  NotWished = 'NOT_WISHED',
  /** unauthorized */
  Unauthorized = 'UNAUTHORIZED',
}

export enum UpdateMessageTemplateMutationErrorEnum {
  MessageTemplateInvalid = 'MESSAGE_TEMPLATE_INVALID',
  NotFound = 'NOT_FOUND',
  TitleBlank = 'TITLE_BLANK',
  TitleTooShort = 'TITLE_TOO_SHORT',
  Unauthorized = 'UNAUTHORIZED',
}

export enum UpdateMobileNotificationSubscriptionsMutationErrorEnum {
  Unauthorized = 'UNAUTHORIZED',
}

export enum UpdateUserErrorEnum {
  /**
   * __Validation error__:
   *   Minimagick raise an MiniMagick::Invalid error
   */
  AvatarInvalidFile = 'AVATAR_INVALID_FILE',
  /**
   * __Validation error__:
   *   File extension not in the allowlist: jpg jpeg gif png
   */
  AvatarInvalidFileExtension = 'AVATAR_INVALID_FILE_EXTENSION',
  /**
   * __Validation error__:
   *   Avatar must be a squared image
   */
  AvatarMustBeSquare = 'AVATAR_MUST_BE_SQUARE',
  /**
   * __Validation error__:
   *   Cannot edit premium ambassador company
   */
  CompanyNameUneditable = 'COMPANY_NAME_UNEDITABLE',
  /**
   * __Validation error__:
   *   can't be blank
   */
  CurrentPasswordBlank = 'CURRENT_PASSWORD_BLANK',
  /**
   * __Validation error__:
   *   Does not match with user's password
   */
  CurrentPasswordDoesNotMatch = 'CURRENT_PASSWORD_DOES_NOT_MATCH',
  /**
   * __Validation error__:
   *   can't be blank
   */
  EmailBlank = 'EMAIL_BLANK',
  /**
   * __Validation error__:
   *   Email domain is blacklisted
   *
   *
   * - blacklist => "private"
   */
  EmailForbiddenDomain = 'EMAIL_FORBIDDEN_DOMAIN',
  /**
   * __Validation error__:
   *   is invalid
   *
   *
   * - with => "?"
   */
  EmailInvalid = 'EMAIL_INVALID',
  /**
   * __Validation error__:
   *   has already been taken
   *
   *
   * - allow_blank => "true"
   */
  EmailTaken = 'EMAIL_TAKEN',
  /**
   * __Validation error__:
   *   first name blank
   */
  FirstNameBlank = 'FIRST_NAME_BLANK',
  /**
   * __Validation error__:
   *   is not included in the list
   *
   *
   * - in => "1..50"
   */
  MaxMeetingsPerMonthInclusion = 'MAX_MEETINGS_PER_MONTH_INCLUSION',
  /**
   * __Validation error__:
   *   doit être rempli(e)
   */
  MeetingPreferencesBlank = 'MEETING_PREFERENCES_BLANK',
  /**
   * __Validation error__:
   *   key is not in the list
   *
   *
   * - in => "["middle_school_student", "high_school_student", "graduate_student", "in_activity"]"
   */
  MeetingPreferencesKeyNotFound = 'MEETING_PREFERENCES_KEY_NOT_FOUND',
  /**
   * __Validation error__:
   *   Should be a E164 phone phone number
   */
  NormalizedPhoneMustBeAPlausiblePhoneNumber = 'NORMALIZED_PHONE_MUST_BE_A_PLAUSIBLE_PHONE_NUMBER',
  /**
   * __Validation error__:
   *   is too long (maximum is 150 characters)
   *
   *
   * - maximum => "150"
   */
  PositionTooLong = 'POSITION_TOO_LONG',
  /**
   * __Validation error__:
   *   is not included in the list
   *
   *
   * - in => "["none", "cep", "brevet", "cap", "bep", "bac_gt", "bac_pro", "bac_2", "bac_3", "bac_4", "bac_5", "doctorat"]"
   */
  RecommendedQualificationsInclusion = 'RECOMMENDED_QUALIFICATIONS_INCLUSION',
  /**
   * __Validation error__:
   *   Profile cannot be republished
   */
  RequestPublicationRequestPublication = 'REQUEST_PUBLICATION_REQUEST_PUBLICATION',
}

export type User = {
  __typename?: 'User';
  accessToken?: Maybe<Scalars['String']['output']>;
  avatar?: Maybe<Scalars['AvatarUrl']['output']>;
  companyAdminProfiles?: Maybe<Array<CompanyAdminProfile>>;
  companyName?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isCoach: Scalars['Boolean']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  mustConfirmEmail: Scalars['Boolean']['output'];
  mustConfirmTutorEmail: Scalars['Boolean']['output'];
  primarySituation?: Maybe<PrimarySituationEnum>;
  professionalProfile?: Maybe<PublicProfessionalProfile>;
  profileCompletionStatus: Scalars['Percentage']['output'];
  profiles: Array<Profile>;
  refreshToken?: Maybe<Scalars['String']['output']>;
  studentProfile?: Maybe<PrivateStudentProfile>;
  tutorEmail?: Maybe<Scalars['String']['output']>;
  unreadConversationsCount: Scalars['Int']['output'];
};

export type UserAvatarArgs = {
  version?: InputMaybe<PictureVersion>;
};

export type UserAuthorization = {
  __typename?: 'UserAuthorization';
  canPublish: Scalars['Boolean']['output'];
  canRequestAdminRhModeration: Scalars['Boolean']['output'];
  canRequestAdminRhUnpublication: Scalars['Boolean']['output'];
  canRequestModeration: Scalars['Boolean']['output'];
  canUnpublish: Scalars['Boolean']['output'];
};

/** The connection type for User. */
export type UserConnection = {
  __typename?: 'UserConnection';
  /** A list of edges. */
  edges?: Maybe<Array<UserEdge>>;
  /** A list of nodes. */
  nodes: Array<User>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type UserEdge = {
  __typename?: 'UserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<User>;
};

/** A decrypted invitation_token / invitation */
export type UserInvitation = {
  __typename?: 'UserInvitation';
  email: Scalars['Email']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
};

export type UserMessageTemplate = {
  __typename?: 'UserMessageTemplate';
  body?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum UserMessageTemplatesQueryErrorEnum {
  NotFound = 'NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED',
}

/** Tag who represent type of users a pro want to met */
export type UserMetTag = {
  __typename?: 'UserMetTag';
  graduateStudent?: Maybe<Scalars['Boolean']['output']>;
  inActivity?: Maybe<Scalars['Boolean']['output']>;
  juniorHighSchoolStudent?: Maybe<Scalars['Boolean']['output']>;
  schoolTeacher?: Maybe<Scalars['Boolean']['output']>;
  seniorHighSchoolStudent?: Maybe<Scalars['Boolean']['output']>;
};

export enum UserProfile {
  Empty = 'EMPTY',
  InOffice = 'IN_OFFICE',
  InSchool = 'IN_SCHOOL',
  PreviouslyInOffice = 'PREVIOUSLY_IN_OFFICE',
}

export type UserProfileTypeInterface = {
  profileType: UserProfile;
};

export enum UserQueryErrorEnum {
  /** User not found */
  NotFound = 'NOT_FOUND',
  /** Unauthorized */
  Unauthorized = 'UNAUTHORIZED',
}

/** List of filters for users query */
export type UsersFilter = {
  email?: InputMaybe<Scalars['String']['input']>;
  emails?: InputMaybe<Array<Scalars['String']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type VerifyEmployeesListPayload = {
  __typename?: 'VerifyEmployeesListPayload';
  changes: Array<Change>;
  invitations: Array<Invitation>;
};

export enum VisibilityStatusEnum {
  Hibernated = 'HIBERNATED',
  IcedUp = 'ICED_UP',
  Invalidated = 'INVALIDATED',
  Published = 'PUBLISHED',
  Restricted = 'RESTRICTED',
  SoftDeleted = 'SOFT_DELETED',
  Unpublished = 'UNPUBLISHED',
}

export enum WishAmbassadorMutationErrorEnum {
  /** already wished */
  AlreadyWished = 'ALREADY_WISHED',
  /** not found */
  NotFound = 'NOT_FOUND',
  /** unauthorized */
  Unauthorized = 'UNAUTHORIZED',
  /** user is archived / soft_deleted / restricted */
  UserIsArchived = 'USER_IS_ARCHIVED',
  /** user is not ambassador */
  UserIsNotAmbassador = 'USER_IS_NOT_AMBASSADOR',
}

export type Get_SettingsQueryVariables = Exact<{
  locale?: InputMaybe<AvailableLanguagesEnum>;
}>;

export type Get_SettingsQuery = {
  __typename?: 'Query';
  currentUserV2?: { __typename?: 'AmbivalentUser'; id: string } | null;
  settings?: {
    __typename?: 'Settings';
    franceTravailAuthorizationUrl: string;
    linkedinAuthorizationUrl: string;
    recommendedQualifications: Array<string>;
    primarySituations: Array<{
      __typename?: 'PrimarySituation';
      key: PrimarySituationEnum;
      name: string;
      secondarySituations?: Array<{
        __typename?: 'SecondarySituation';
        key: SecondarySituationEnum;
        name: string;
        grades?: Array<GradeEnum> | null;
      }> | null;
    }>;
    jobTitles: Array<{ __typename?: 'JobTitle'; key: JobTitleKeyEnum; name: string }>;
    diplomas: Array<{ __typename?: 'Diploma'; key: DiplomaKeyEnum; name: string }>;
    comesFrom: Array<{ __typename?: 'ComesFrom'; key: ComesFromKeyEnum; name: string }>;
    jobSeekerSupportOrganizationTypes: Array<{
      __typename?: 'JobSeekerSupportOrganizationType';
      key: JobSeekerSupportOrganizationTypeKeyEnum;
      name: string;
    }>;
    jobSeekerSupportPrograms: Array<{
      __typename?: 'JobSeekerSupportProgram';
      key: JobSeekerSupportProgramKeyEnum;
      name: string;
    }>;
  } | null;
};

export type UserPartsFragment = {
  __typename?: 'AmbivalentUser';
  id: string;
  isModeratable: boolean;
  isAdminRhModeratable: boolean;
  isPublishable: boolean;
  isInModeration: boolean;
  isUnpublishable: boolean;
  professionalId?: string | null;
  publishedAt?: any | null;
  publicationStatus: PublicationStatuses;
  isPaidAmbassador: boolean;
  canBecomeAmbassador?: boolean | null;
  gender?: GenderEnum | null;
  firstName?: string | null;
  lastName?: string | null;
  birthdate?: any | null;
  isMinor?: boolean | null;
  tutorEmailConfirmedAt?: any | null;
  tutorEmail?: string | null;
  jobTitle?: string | null;
  companyName?: string | null;
  yearsOfExperience?: number | null;
  position?: string | null;
  previousPosition?: string | null;
  previousCompanyName?: string | null;
  previousPositionYearsOfExperiences?: number | null;
  neverWorked?: boolean | null;
  availableGrades: Array<GradeEnum>;
  currentGrade?: GradeEnum | null;
  currentSchoolName?: string | null;
  currentSchoolCity?: string | null;
  currentSchoolDepartment?: string | null;
  apprentice?: boolean | null;
  preparedDiplomaTitle?: string | null;
  memberPresentation?: string | null;
  ambassadorPresentation?: string | null;
  interestsPresentation?: string | null;
  aspirationPresentation?: string | null;
  expertisePresentation?: string | null;
  phoneCountryCode?: string | null;
  phoneWithoutCountryCode?: string | null;
  googlePlaceId?: string | null;
  meetingPlace?: string | null;
  languages: Array<Language>;
  maxMeetingsPerMonth?: number | null;
  canShare: boolean;
  wantToShare?: boolean | null;
  recommendedQualifications: Array<string>;
  mandatorySkills: Array<string>;
  mainAdminEmail?: string | null;
  locatedInLaReunion: boolean;
  teachedSchoolSubjects?: Array<string> | null;
  headTeacher?: boolean | null;
  isCoach: boolean;
  jobSeekerSupportPrograms?: Array<string> | null;
  jobSeekerSupportOrganizationType?: string | null;
  canAcceptGroupConversation: boolean;
  acceptGroupConversations: boolean;
  editionCompletionStatus: {
    __typename?: 'ContextualCompletion';
    fieldsCompletion: Array<{
      __typename?: 'FieldCompletionStatus';
      name: FieldCompletionName;
      complete: boolean;
      points: number;
      disabled: boolean;
    }>;
  };
  currentUserAuthorizations: {
    __typename?: 'UserAuthorization';
    canPublish: boolean;
    canRequestAdminRhModeration: boolean;
    canRequestAdminRhUnpublication: boolean;
    canRequestModeration: boolean;
    canUnpublish: boolean;
  };
  avatars: { __typename?: 'Avatar'; url?: string | null };
  primarySituation?: { __typename?: 'PrimarySituation'; key: PrimarySituationEnum; name: string } | null;
  secondarySituation?: { __typename?: 'SecondarySituation'; key: SecondarySituationEnum; name: string } | null;
  presentationTranslations: {
    __typename?: 'PresentationTranslations';
    memberPresentationExample: string;
    memberPresentationQuestion: string;
    ambassadorPresentationExample: string;
    ambassadorPresentationQuestion: string;
    interestsExample: string;
    interestsQuestion: string;
    aspirationsExample: string;
    aspirationsQuestion: string;
    expertisesExample: string;
    expertisesQuestion: string;
  };
  educations: Array<{
    __typename?: 'ProfileEducation';
    id: string;
    diplomaTitle?: string | null;
    diplomaType?: DiplomaEnum | null;
    schoolName: string;
    startedAt?: any | null;
    endedAt?: any | null;
  }>;
  meetingPreferences?: Array<{
    __typename?: 'MeetingPreference';
    key: InteractionGroupKeyEnum;
    name: string;
    description: string;
    value?: boolean | null;
  }> | null;
  galleryImages: Array<{ __typename?: 'GalleryImage'; id: string; imageUrl: string }>;
  accompaniedStudyLevels?: Array<{ __typename?: 'Grade'; key: GradeKeyEnum; name: string }> | null;
};

export type GetUserQueryVariables = Exact<{
  locale: AvailableLanguagesEnum;
}>;

export type GetUserQuery = {
  __typename?: 'Query';
  currentUserV2?: {
    __typename?: 'AmbivalentUser';
    id: string;
    isModeratable: boolean;
    isAdminRhModeratable: boolean;
    isPublishable: boolean;
    isInModeration: boolean;
    isUnpublishable: boolean;
    professionalId?: string | null;
    publishedAt?: any | null;
    publicationStatus: PublicationStatuses;
    isPaidAmbassador: boolean;
    canBecomeAmbassador?: boolean | null;
    gender?: GenderEnum | null;
    firstName?: string | null;
    lastName?: string | null;
    birthdate?: any | null;
    isMinor?: boolean | null;
    tutorEmailConfirmedAt?: any | null;
    tutorEmail?: string | null;
    jobTitle?: string | null;
    companyName?: string | null;
    yearsOfExperience?: number | null;
    position?: string | null;
    previousPosition?: string | null;
    previousCompanyName?: string | null;
    previousPositionYearsOfExperiences?: number | null;
    neverWorked?: boolean | null;
    availableGrades: Array<GradeEnum>;
    currentGrade?: GradeEnum | null;
    currentSchoolName?: string | null;
    currentSchoolCity?: string | null;
    currentSchoolDepartment?: string | null;
    apprentice?: boolean | null;
    preparedDiplomaTitle?: string | null;
    memberPresentation?: string | null;
    ambassadorPresentation?: string | null;
    interestsPresentation?: string | null;
    aspirationPresentation?: string | null;
    expertisePresentation?: string | null;
    phoneCountryCode?: string | null;
    phoneWithoutCountryCode?: string | null;
    googlePlaceId?: string | null;
    meetingPlace?: string | null;
    languages: Array<Language>;
    maxMeetingsPerMonth?: number | null;
    canShare: boolean;
    wantToShare?: boolean | null;
    recommendedQualifications: Array<string>;
    mandatorySkills: Array<string>;
    mainAdminEmail?: string | null;
    locatedInLaReunion: boolean;
    teachedSchoolSubjects?: Array<string> | null;
    headTeacher?: boolean | null;
    isCoach: boolean;
    jobSeekerSupportPrograms?: Array<string> | null;
    jobSeekerSupportOrganizationType?: string | null;
    canAcceptGroupConversation: boolean;
    acceptGroupConversations: boolean;
    editionCompletionStatus: {
      __typename?: 'ContextualCompletion';
      fieldsCompletion: Array<{
        __typename?: 'FieldCompletionStatus';
        name: FieldCompletionName;
        complete: boolean;
        points: number;
        disabled: boolean;
      }>;
    };
    currentUserAuthorizations: {
      __typename?: 'UserAuthorization';
      canPublish: boolean;
      canRequestAdminRhModeration: boolean;
      canRequestAdminRhUnpublication: boolean;
      canRequestModeration: boolean;
      canUnpublish: boolean;
    };
    avatars: { __typename?: 'Avatar'; url?: string | null };
    primarySituation?: { __typename?: 'PrimarySituation'; key: PrimarySituationEnum; name: string } | null;
    secondarySituation?: { __typename?: 'SecondarySituation'; key: SecondarySituationEnum; name: string } | null;
    presentationTranslations: {
      __typename?: 'PresentationTranslations';
      memberPresentationExample: string;
      memberPresentationQuestion: string;
      ambassadorPresentationExample: string;
      ambassadorPresentationQuestion: string;
      interestsExample: string;
      interestsQuestion: string;
      aspirationsExample: string;
      aspirationsQuestion: string;
      expertisesExample: string;
      expertisesQuestion: string;
    };
    educations: Array<{
      __typename?: 'ProfileEducation';
      id: string;
      diplomaTitle?: string | null;
      diplomaType?: DiplomaEnum | null;
      schoolName: string;
      startedAt?: any | null;
      endedAt?: any | null;
    }>;
    meetingPreferences?: Array<{
      __typename?: 'MeetingPreference';
      key: InteractionGroupKeyEnum;
      name: string;
      description: string;
      value?: boolean | null;
    }> | null;
    galleryImages: Array<{ __typename?: 'GalleryImage'; id: string; imageUrl: string }>;
    accompaniedStudyLevels?: Array<{ __typename?: 'Grade'; key: GradeKeyEnum; name: string }> | null;
  } | null;
};

export type GetOtherUserQueryVariables = Exact<{
  locale: AvailableLanguagesEnum;
  id: Scalars['ID']['input'];
}>;

export type GetOtherUserQuery = {
  __typename?: 'Query';
  getUser: {
    __typename?: 'AmbivalentUser';
    id: string;
    isModeratable: boolean;
    isAdminRhModeratable: boolean;
    isPublishable: boolean;
    isInModeration: boolean;
    isUnpublishable: boolean;
    professionalId?: string | null;
    publishedAt?: any | null;
    publicationStatus: PublicationStatuses;
    isPaidAmbassador: boolean;
    canBecomeAmbassador?: boolean | null;
    gender?: GenderEnum | null;
    firstName?: string | null;
    lastName?: string | null;
    birthdate?: any | null;
    isMinor?: boolean | null;
    tutorEmailConfirmedAt?: any | null;
    tutorEmail?: string | null;
    jobTitle?: string | null;
    companyName?: string | null;
    yearsOfExperience?: number | null;
    position?: string | null;
    previousPosition?: string | null;
    previousCompanyName?: string | null;
    previousPositionYearsOfExperiences?: number | null;
    neverWorked?: boolean | null;
    availableGrades: Array<GradeEnum>;
    currentGrade?: GradeEnum | null;
    currentSchoolName?: string | null;
    currentSchoolCity?: string | null;
    currentSchoolDepartment?: string | null;
    apprentice?: boolean | null;
    preparedDiplomaTitle?: string | null;
    memberPresentation?: string | null;
    ambassadorPresentation?: string | null;
    interestsPresentation?: string | null;
    aspirationPresentation?: string | null;
    expertisePresentation?: string | null;
    phoneCountryCode?: string | null;
    phoneWithoutCountryCode?: string | null;
    googlePlaceId?: string | null;
    meetingPlace?: string | null;
    languages: Array<Language>;
    maxMeetingsPerMonth?: number | null;
    canShare: boolean;
    wantToShare?: boolean | null;
    recommendedQualifications: Array<string>;
    mandatorySkills: Array<string>;
    mainAdminEmail?: string | null;
    locatedInLaReunion: boolean;
    teachedSchoolSubjects?: Array<string> | null;
    headTeacher?: boolean | null;
    isCoach: boolean;
    jobSeekerSupportPrograms?: Array<string> | null;
    jobSeekerSupportOrganizationType?: string | null;
    canAcceptGroupConversation: boolean;
    acceptGroupConversations: boolean;
    editionCompletionStatus: {
      __typename?: 'ContextualCompletion';
      fieldsCompletion: Array<{
        __typename?: 'FieldCompletionStatus';
        name: FieldCompletionName;
        complete: boolean;
        points: number;
        disabled: boolean;
      }>;
    };
    currentUserAuthorizations: {
      __typename?: 'UserAuthorization';
      canPublish: boolean;
      canRequestAdminRhModeration: boolean;
      canRequestAdminRhUnpublication: boolean;
      canRequestModeration: boolean;
      canUnpublish: boolean;
    };
    avatars: { __typename?: 'Avatar'; url?: string | null };
    primarySituation?: { __typename?: 'PrimarySituation'; key: PrimarySituationEnum; name: string } | null;
    secondarySituation?: { __typename?: 'SecondarySituation'; key: SecondarySituationEnum; name: string } | null;
    presentationTranslations: {
      __typename?: 'PresentationTranslations';
      memberPresentationExample: string;
      memberPresentationQuestion: string;
      ambassadorPresentationExample: string;
      ambassadorPresentationQuestion: string;
      interestsExample: string;
      interestsQuestion: string;
      aspirationsExample: string;
      aspirationsQuestion: string;
      expertisesExample: string;
      expertisesQuestion: string;
    };
    educations: Array<{
      __typename?: 'ProfileEducation';
      id: string;
      diplomaTitle?: string | null;
      diplomaType?: DiplomaEnum | null;
      schoolName: string;
      startedAt?: any | null;
      endedAt?: any | null;
    }>;
    meetingPreferences?: Array<{
      __typename?: 'MeetingPreference';
      key: InteractionGroupKeyEnum;
      name: string;
      description: string;
      value?: boolean | null;
    }> | null;
    galleryImages: Array<{ __typename?: 'GalleryImage'; id: string; imageUrl: string }>;
    accompaniedStudyLevels?: Array<{ __typename?: 'Grade'; key: GradeKeyEnum; name: string }> | null;
  };
};

export type UpdateUserMutationVariables = Exact<{
  locale: AvailableLanguagesEnum;
  userId: Scalars['ID']['input'];
  avatar?: InputMaybe<Scalars['Upload']['input']>;
  gender?: InputMaybe<GenderEnum>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  primarySituation?: InputMaybe<PrimarySituationEnum>;
  secondarySituation?: InputMaybe<SecondarySituationEnum>;
  position?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  yearsOfExperience?: InputMaybe<Scalars['Int']['input']>;
  previousPosition?: InputMaybe<Scalars['String']['input']>;
  previousCompanyName?: InputMaybe<Scalars['String']['input']>;
  previousPositionYearsOfExperiences?: InputMaybe<Scalars['Int']['input']>;
  neverWorked?: InputMaybe<Scalars['Boolean']['input']>;
  currentGrade?: InputMaybe<GradeEnum>;
  currentSchoolName?: InputMaybe<Scalars['String']['input']>;
  currentSchoolCity?: InputMaybe<Scalars['String']['input']>;
  currentSchoolDepartment?: InputMaybe<Scalars['String']['input']>;
  apprentice?: InputMaybe<Scalars['Boolean']['input']>;
  meetingPlace?: InputMaybe<Scalars['String']['input']>;
  googlePlaceId?: InputMaybe<Scalars['String']['input']>;
  normalizedPhone?: InputMaybe<Scalars['String']['input']>;
  languages?: InputMaybe<Array<Language> | Language>;
  meetingPreferences?: InputMaybe<Array<MeetingPreferenceInput> | MeetingPreferenceInput>;
  maxMeetingsPerMonth?: InputMaybe<Scalars['Int']['input']>;
  wantToShare?: InputMaybe<Scalars['Boolean']['input']>;
  memberPresentation?: InputMaybe<Scalars['String']['input']>;
  ambassadorPresentation?: InputMaybe<Scalars['String']['input']>;
  expertisePresentation?: InputMaybe<Scalars['String']['input']>;
  aspirationPresentation?: InputMaybe<Scalars['String']['input']>;
  interestsPresentation?: InputMaybe<Scalars['String']['input']>;
  recommendedQualifications?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  mandatorySkills?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  preparedDiplomaTitle?: InputMaybe<Scalars['String']['input']>;
  accompaniedStudyLevels?: InputMaybe<Array<GradeEnum> | GradeEnum>;
  teachedSchoolSubjects?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  headTeacher?: InputMaybe<Scalars['Boolean']['input']>;
  jobSeekerSupportPrograms?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  jobSeekerSupportOrganizationType?: InputMaybe<Scalars['String']['input']>;
  acceptGroupConversations?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type UpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser?: {
    __typename?: 'AmbivalentUser';
    id: string;
    isModeratable: boolean;
    isAdminRhModeratable: boolean;
    isPublishable: boolean;
    isInModeration: boolean;
    isUnpublishable: boolean;
    professionalId?: string | null;
    publishedAt?: any | null;
    publicationStatus: PublicationStatuses;
    isPaidAmbassador: boolean;
    canBecomeAmbassador?: boolean | null;
    gender?: GenderEnum | null;
    firstName?: string | null;
    lastName?: string | null;
    birthdate?: any | null;
    isMinor?: boolean | null;
    tutorEmailConfirmedAt?: any | null;
    tutorEmail?: string | null;
    jobTitle?: string | null;
    companyName?: string | null;
    yearsOfExperience?: number | null;
    position?: string | null;
    previousPosition?: string | null;
    previousCompanyName?: string | null;
    previousPositionYearsOfExperiences?: number | null;
    neverWorked?: boolean | null;
    availableGrades: Array<GradeEnum>;
    currentGrade?: GradeEnum | null;
    currentSchoolName?: string | null;
    currentSchoolCity?: string | null;
    currentSchoolDepartment?: string | null;
    apprentice?: boolean | null;
    preparedDiplomaTitle?: string | null;
    memberPresentation?: string | null;
    ambassadorPresentation?: string | null;
    interestsPresentation?: string | null;
    aspirationPresentation?: string | null;
    expertisePresentation?: string | null;
    phoneCountryCode?: string | null;
    phoneWithoutCountryCode?: string | null;
    googlePlaceId?: string | null;
    meetingPlace?: string | null;
    languages: Array<Language>;
    maxMeetingsPerMonth?: number | null;
    canShare: boolean;
    wantToShare?: boolean | null;
    recommendedQualifications: Array<string>;
    mandatorySkills: Array<string>;
    mainAdminEmail?: string | null;
    locatedInLaReunion: boolean;
    teachedSchoolSubjects?: Array<string> | null;
    headTeacher?: boolean | null;
    isCoach: boolean;
    jobSeekerSupportPrograms?: Array<string> | null;
    jobSeekerSupportOrganizationType?: string | null;
    canAcceptGroupConversation: boolean;
    acceptGroupConversations: boolean;
    editionCompletionStatus: {
      __typename?: 'ContextualCompletion';
      fieldsCompletion: Array<{
        __typename?: 'FieldCompletionStatus';
        name: FieldCompletionName;
        complete: boolean;
        points: number;
        disabled: boolean;
      }>;
    };
    currentUserAuthorizations: {
      __typename?: 'UserAuthorization';
      canPublish: boolean;
      canRequestAdminRhModeration: boolean;
      canRequestAdminRhUnpublication: boolean;
      canRequestModeration: boolean;
      canUnpublish: boolean;
    };
    avatars: { __typename?: 'Avatar'; url?: string | null };
    primarySituation?: { __typename?: 'PrimarySituation'; key: PrimarySituationEnum; name: string } | null;
    secondarySituation?: { __typename?: 'SecondarySituation'; key: SecondarySituationEnum; name: string } | null;
    presentationTranslations: {
      __typename?: 'PresentationTranslations';
      memberPresentationExample: string;
      memberPresentationQuestion: string;
      ambassadorPresentationExample: string;
      ambassadorPresentationQuestion: string;
      interestsExample: string;
      interestsQuestion: string;
      aspirationsExample: string;
      aspirationsQuestion: string;
      expertisesExample: string;
      expertisesQuestion: string;
    };
    educations: Array<{
      __typename?: 'ProfileEducation';
      id: string;
      diplomaTitle?: string | null;
      diplomaType?: DiplomaEnum | null;
      schoolName: string;
      startedAt?: any | null;
      endedAt?: any | null;
    }>;
    meetingPreferences?: Array<{
      __typename?: 'MeetingPreference';
      key: InteractionGroupKeyEnum;
      name: string;
      description: string;
      value?: boolean | null;
    }> | null;
    galleryImages: Array<{ __typename?: 'GalleryImage'; id: string; imageUrl: string }>;
    accompaniedStudyLevels?: Array<{ __typename?: 'Grade'; key: GradeKeyEnum; name: string }> | null;
  } | null;
};

export type CreateEducationMutationVariables = Exact<{
  diplomaTitle: Scalars['String']['input'];
  diplomaType: DiplomaEnum;
  schoolName: Scalars['String']['input'];
  startedAt: Scalars['ISO8601DateTime']['input'];
  endedAt: Scalars['ISO8601DateTime']['input'];
  userId: Scalars['ID']['input'];
}>;

export type CreateEducationMutation = {
  __typename?: 'Mutation';
  createEducation?: {
    __typename?: 'ProfileEducation';
    id: string;
    diplomaTitle?: string | null;
    diplomaType?: DiplomaEnum | null;
    schoolName: string;
    startedAt?: any | null;
    endedAt?: any | null;
  } | null;
};

export type UpdateEducationMutationVariables = Exact<{
  educationId: Scalars['ID']['input'];
  diplomaTitle: Scalars['String']['input'];
  diplomaType: DiplomaEnum;
  schoolName: Scalars['String']['input'];
  startedAt: Scalars['ISO8601DateTime']['input'];
  endedAt: Scalars['ISO8601DateTime']['input'];
  userId: Scalars['ID']['input'];
}>;

export type UpdateEducationMutation = {
  __typename?: 'Mutation';
  updateEducation?: {
    __typename?: 'ProfileEducation';
    id: string;
    diplomaTitle?: string | null;
    diplomaType?: DiplomaEnum | null;
    schoolName: string;
    startedAt?: any | null;
    endedAt?: any | null;
  } | null;
};

export type RemoveEducationMutationVariables = Exact<{
  educationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
}>;

export type RemoveEducationMutation = { __typename?: 'Mutation'; destroyEducation?: boolean | null };

export type UploadGalleryImageMutationVariables = Exact<{
  image: Scalars['Upload']['input'];
  userId: Scalars['ID']['input'];
}>;

export type UploadGalleryImageMutation = {
  __typename?: 'Mutation';
  uploadGalleryImage?: { __typename?: 'GalleryImage'; id: string; imageUrl: string } | null;
};

export type RemoveGalleryImageMutationVariables = Exact<{
  imageId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
}>;

export type RemoveGalleryImageMutation = {
  __typename?: 'Mutation';
  removeGalleryImage?: { __typename?: 'AmbivalentUser'; id: string } | null;
};

export type GetUserIdOfProfessionalIdQueryVariables = Exact<{
  professionalId: Scalars['ID']['input'];
}>;

export type GetUserIdOfProfessionalIdQuery = {
  __typename?: 'Query';
  professionalProfile?: { __typename?: 'PublicProfessionalProfile'; userId: string } | null;
};

export type JoinProgramWithCodeMutationVariables = Exact<{
  programCode: Scalars['String']['input'];
}>;

export type JoinProgramWithCodeMutation = {
  __typename?: 'Mutation';
  joinProgram: { __typename?: 'Participation'; id: string };
};

export type JoinProgramWithInvitationMutationVariables = Exact<{
  invitationId: Scalars['ID']['input'];
}>;

export type JoinProgramWithInvitationMutation = { __typename?: 'Mutation'; acceptInvitation: boolean };

export type RequestModerationMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;

export type RequestModerationMutation = { __typename?: 'Mutation'; requestModeration: boolean };

export type UnpublishMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;

export type UnpublishMutation = { __typename?: 'Mutation'; unpublish: boolean };

export type PublishMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;

export type PublishMutation = { __typename?: 'Mutation'; publish: boolean };

export type SearchSchoolSubjectsQueryVariables = Exact<{
  term: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
}>;

export type SearchSchoolSubjectsQuery = { __typename?: 'Query'; searchSchoolSubjects: Array<string> };

export type GetDepartmentsQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetDepartmentsQuery = { __typename?: 'Query'; departments?: Array<string> | null };

export type GetGouvSchoolsQueryVariables = Exact<{
  term: Scalars['String']['input'];
  department: Scalars['String']['input'];
  establishmentType?: InputMaybe<SecondarySituationEnum>;
}>;

export type GetGouvSchoolsQuery = {
  __typename?: 'Query';
  gouvSchoolSearch: Array<{
    __typename?: 'GouvSchoolSearchResults';
    id: string;
    name?: string | null;
    communeName?: string | null;
    academyLabel?: string | null;
  }>;
};

export type CreateGouvSchoolMutationVariables = Exact<{
  name: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
  communeName?: InputMaybe<Scalars['String']['input']>;
  establishmentType?: InputMaybe<SecondarySituationEnum>;
  departmentLabel?: InputMaybe<Scalars['String']['input']>;
}>;

export type CreateGouvSchoolMutation = {
  __typename?: 'Mutation';
  createGouvSchool?: {
    __typename?: 'GouvSchool';
    id: string;
    name?: string | null;
    communeName?: string | null;
    departmentLabel?: string | null;
  } | null;
};

export type CheckEmailAvailabilityQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type CheckEmailAvailabilityQuery = {
  __typename?: 'Query';
  checkEmailAvailability: { __typename?: 'CheckEmailAvailabilityQueryPayload'; available: boolean };
};

export type Get_Current_UserQueryVariables = Exact<{ [key: string]: never }>;

export type Get_Current_UserQuery = {
  __typename?: 'Query';
  currentUserV2?: {
    __typename?: 'AmbivalentUser';
    isCoach: boolean;
    companyAdminProfiles?: Array<{ __typename?: 'CompanyAdminProfile'; id: string }> | null;
    schoolAdminProfiles?: Array<{ __typename?: 'CompanyAdminProfile'; id: string }> | null;
  } | null;
};

export type OpenidConnectMutationVariables = Exact<{
  identityToken: Scalars['String']['input'];
  provider: OpenidConnectProvider;
}>;

export type OpenidConnectMutation = {
  __typename?: 'Mutation';
  openidConnect?: { __typename?: 'OpenidConnectPayload'; email: string; nonce: string } | null;
};

export type Sign_UpMutationVariables = Exact<{
  birthdate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  email: Scalars['String']['input'];
  invitationToken?: InputMaybe<Scalars['String']['input']>;
  oauthNonce?: InputMaybe<Scalars['String']['input']>;
  orianeAmbassador?: InputMaybe<Scalars['Boolean']['input']>;
  highSchoolTeacher?: InputMaybe<Scalars['Boolean']['input']>;
  tutorEmail?: InputMaybe<Scalars['String']['input']>;
  comesFrom?: InputMaybe<ComesFromEnum>;
  vocationCoachSignup?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type Sign_UpMutation = {
  __typename?: 'Mutation';
  signup?: { __typename?: 'AmbivalentUser'; email: string } | null;
};

export type Sign_InMutationVariables = Exact<{
  email: Scalars['Email']['input'];
  password: Scalars['String']['input'];
}>;

export type Sign_InMutation = {
  __typename?: 'Mutation';
  signin?: {
    __typename?: 'User';
    email: string;
    isCoach: boolean;
    companyAdminProfiles?: Array<{ __typename?: 'CompanyAdminProfile'; id: string }> | null;
  } | null;
};

export type OauthMutationVariables = Exact<{
  authorizationCode: Scalars['String']['input'];
  provider: OauthProviderEnum;
  redirectUri: Scalars['String']['input'];
}>;

export type OauthMutation = {
  __typename?: 'Mutation';
  oauth?: { __typename?: 'OauthPayload'; email: string; firstName: string; lastName: string; nonce: string } | null;
};

export type EmailFromInvitationMutationVariables = Exact<{
  invitationToken: Scalars['String']['input'];
}>;

export type EmailFromInvitationMutation = {
  __typename?: 'Mutation';
  extractInfosFromInvitation: { __typename?: 'InvitationInfos'; email: any };
};

export type Confirm_EmailMutationVariables = Exact<{
  confirmationToken: Scalars['String']['input'];
}>;

export type Confirm_EmailMutation = {
  __typename?: 'Mutation';
  confirmEmail?: { __typename?: 'AmbivalentUser'; id: string } | null;
};

export type Confirm_Tutor_EmailMutationVariables = Exact<{
  confirmationToken: Scalars['String']['input'];
}>;

export type Confirm_Tutor_EmailMutation = { __typename?: 'Mutation'; confirmTutorEmail?: boolean | null };

export type Request_Email_Adress_Confirmation_EmailMutationVariables = Exact<{
  email: Scalars['Email']['input'];
}>;

export type Request_Email_Adress_Confirmation_EmailMutation = {
  __typename?: 'Mutation';
  requestEmailAddressConfirmationEmail?: boolean | null;
};

export type Request_New_PasswordMutationVariables = Exact<{
  email: Scalars['Email']['input'];
}>;

export type Request_New_PasswordMutation = { __typename?: 'Mutation'; requestNewPassword?: boolean | null };

export type New_PasswordMutationVariables = Exact<{
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
}>;

export type New_PasswordMutation = { __typename?: 'Mutation'; newPassword?: boolean | null };

export type ProfileCompletionUserPartsFragment = {
  __typename?: 'AmbivalentUser';
  id: string;
  showWelcomePanel: boolean;
  isModeratable: boolean;
  isAdminRhModeratable: boolean;
  email: string;
  firstName?: string | null;
  lastName?: string | null;
  availableGrades: Array<GradeEnum>;
  wantToShare?: boolean | null;
  requestProfileModificationUpdate?: boolean | null;
  headTeacher?: boolean | null;
  teachedSchoolSubjects?: Array<string> | null;
  jobSeekerSupportOrganizationType?: string | null;
  jobSeekerSupportPrograms?: Array<string> | null;
  vocationCoachSignup?: boolean | null;
  isCoach: boolean;
  canAcceptGroupConversation: boolean;
  acceptGroupConversations: boolean;
  stepByStepCompletionStatus: {
    __typename?: 'ContextualCompletion';
    fieldsCompletion: Array<{
      __typename?: 'FieldCompletionStatus';
      name: FieldCompletionName;
      complete: boolean;
      points: number;
      disabled: boolean;
    }>;
  };
  currentUserAuthorizations: {
    __typename?: 'UserAuthorization';
    canRequestModeration: boolean;
    canRequestAdminRhModeration: boolean;
  };
  primarySituation?: { __typename?: 'PrimarySituation'; key: PrimarySituationEnum } | null;
  secondarySituation?: { __typename?: 'SecondarySituation'; key: SecondarySituationEnum; name: string } | null;
  meetingPreferences?: Array<{
    __typename?: 'MeetingPreference';
    key: InteractionGroupKeyEnum;
    name: string;
    description: string;
    value?: boolean | null;
  }> | null;
  presentationTranslations: {
    __typename?: 'PresentationTranslations';
    aspirationsQuestion: string;
    expertisesQuestion: string;
    interestsQuestion: string;
    memberPresentationQuestion: string;
    ambassadorPresentationQuestion: string;
    aspirationsExample: string;
    expertisesExample: string;
    interestsExample: string;
    memberPresentationExample: string;
    ambassadorPresentationExample: string;
  };
  accompaniedStudyLevels?: Array<{ __typename?: 'Grade'; key: GradeKeyEnum; name: string }> | null;
};

export type ProfileCompletionUserPartsWithContextFragment = {
  __typename?: 'AmbivalentUser';
  id: string;
  showWelcomePanel: boolean;
  isModeratable: boolean;
  isAdminRhModeratable: boolean;
  email: string;
  firstName?: string | null;
  lastName?: string | null;
  availableGrades: Array<GradeEnum>;
  wantToShare?: boolean | null;
  requestProfileModificationUpdate?: boolean | null;
  headTeacher?: boolean | null;
  teachedSchoolSubjects?: Array<string> | null;
  jobSeekerSupportOrganizationType?: string | null;
  jobSeekerSupportPrograms?: Array<string> | null;
  vocationCoachSignup?: boolean | null;
  isCoach: boolean;
  canAcceptGroupConversation: boolean;
  acceptGroupConversations: boolean;
  stepByStepCompletionStatus: {
    __typename?: 'ContextualCompletion';
    complete: boolean;
    fieldsCompletion: Array<{
      __typename?: 'FieldCompletionStatus';
      name: FieldCompletionName;
      complete: boolean;
      points: number;
      disabled: boolean;
    }>;
  };
  currentUserAuthorizations: {
    __typename?: 'UserAuthorization';
    canRequestModeration: boolean;
    canRequestAdminRhModeration: boolean;
  };
  primarySituation?: { __typename?: 'PrimarySituation'; key: PrimarySituationEnum } | null;
  secondarySituation?: { __typename?: 'SecondarySituation'; key: SecondarySituationEnum; name: string } | null;
  meetingPreferences?: Array<{
    __typename?: 'MeetingPreference';
    key: InteractionGroupKeyEnum;
    name: string;
    description: string;
    value?: boolean | null;
  }> | null;
  presentationTranslations: {
    __typename?: 'PresentationTranslations';
    aspirationsQuestion: string;
    expertisesQuestion: string;
    interestsQuestion: string;
    memberPresentationQuestion: string;
    ambassadorPresentationQuestion: string;
    aspirationsExample: string;
    expertisesExample: string;
    interestsExample: string;
    memberPresentationExample: string;
    ambassadorPresentationExample: string;
  };
  accompaniedStudyLevels?: Array<{ __typename?: 'Grade'; key: GradeKeyEnum; name: string }> | null;
};

export type Get_User_CompletionQueryVariables = Exact<{
  locale?: InputMaybe<AvailableLanguagesEnum>;
  completionContext: CompletionContextEnum;
}>;

export type Get_User_CompletionQuery = {
  __typename?: 'Query';
  currentUserV2?: {
    __typename?: 'AmbivalentUser';
    id: string;
    showWelcomePanel: boolean;
    isModeratable: boolean;
    isAdminRhModeratable: boolean;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    availableGrades: Array<GradeEnum>;
    wantToShare?: boolean | null;
    requestProfileModificationUpdate?: boolean | null;
    headTeacher?: boolean | null;
    teachedSchoolSubjects?: Array<string> | null;
    jobSeekerSupportOrganizationType?: string | null;
    jobSeekerSupportPrograms?: Array<string> | null;
    vocationCoachSignup?: boolean | null;
    isCoach: boolean;
    canAcceptGroupConversation: boolean;
    acceptGroupConversations: boolean;
    stepByStepCompletionStatus: {
      __typename?: 'ContextualCompletion';
      complete: boolean;
      fieldsCompletion: Array<{
        __typename?: 'FieldCompletionStatus';
        name: FieldCompletionName;
        complete: boolean;
        points: number;
        disabled: boolean;
      }>;
    };
    currentUserAuthorizations: {
      __typename?: 'UserAuthorization';
      canRequestModeration: boolean;
      canRequestAdminRhModeration: boolean;
    };
    primarySituation?: { __typename?: 'PrimarySituation'; key: PrimarySituationEnum } | null;
    secondarySituation?: { __typename?: 'SecondarySituation'; key: SecondarySituationEnum; name: string } | null;
    meetingPreferences?: Array<{
      __typename?: 'MeetingPreference';
      key: InteractionGroupKeyEnum;
      name: string;
      description: string;
      value?: boolean | null;
    }> | null;
    presentationTranslations: {
      __typename?: 'PresentationTranslations';
      aspirationsQuestion: string;
      expertisesQuestion: string;
      interestsQuestion: string;
      memberPresentationQuestion: string;
      ambassadorPresentationQuestion: string;
      aspirationsExample: string;
      expertisesExample: string;
      interestsExample: string;
      memberPresentationExample: string;
      ambassadorPresentationExample: string;
    };
    accompaniedStudyLevels?: Array<{ __typename?: 'Grade'; key: GradeKeyEnum; name: string }> | null;
  } | null;
};

export type UpdateUserProfileCompletionMutationVariables = Exact<{
  completionContext: CompletionContextEnum;
  locale?: InputMaybe<AvailableLanguagesEnum>;
  birthdate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  gender?: InputMaybe<GenderEnum>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  aspirationPresentation?: InputMaybe<Scalars['String']['input']>;
  avatar?: InputMaybe<Scalars['Upload']['input']>;
  yearsOfExperience?: InputMaybe<Scalars['Int']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  previousPosition?: InputMaybe<Scalars['String']['input']>;
  previousCompanyName?: InputMaybe<Scalars['String']['input']>;
  previousPositionYearsOfExperiences?: InputMaybe<Scalars['Int']['input']>;
  neverWorked?: InputMaybe<Scalars['Boolean']['input']>;
  currentGrade?: InputMaybe<GradeEnum>;
  currentSchoolCity?: InputMaybe<Scalars['String']['input']>;
  currentSchoolName?: InputMaybe<Scalars['String']['input']>;
  currentSchoolDepartment?: InputMaybe<Scalars['String']['input']>;
  expertisePresentation?: InputMaybe<Scalars['String']['input']>;
  googlePlaceId?: InputMaybe<Scalars['String']['input']>;
  interestsPresentation?: InputMaybe<Scalars['String']['input']>;
  languages?: InputMaybe<Array<Language> | Language>;
  maxMeetingsPerMonth?: InputMaybe<Scalars['Int']['input']>;
  meetingPlace?: InputMaybe<Scalars['String']['input']>;
  meetingPreferences?: InputMaybe<Array<MeetingPreferenceInput> | MeetingPreferenceInput>;
  normalizedPhone?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  primarySituation?: InputMaybe<PrimarySituationEnum>;
  secondarySituation?: InputMaybe<SecondarySituationEnum>;
  memberPresentation?: InputMaybe<Scalars['String']['input']>;
  ambassadorPresentation?: InputMaybe<Scalars['String']['input']>;
  showWelcomePanel?: InputMaybe<Scalars['Boolean']['input']>;
  userId: Scalars['ID']['input'];
  wantToShare?: InputMaybe<Scalars['Boolean']['input']>;
  showCongratulationsPanel?: InputMaybe<Scalars['Boolean']['input']>;
  showVocationPanel?: InputMaybe<Scalars['Boolean']['input']>;
  programParticipationCode?: InputMaybe<Scalars['String']['input']>;
  apprentice?: InputMaybe<Scalars['Boolean']['input']>;
  acquiredDiplomaAcquisitionYear?: InputMaybe<Scalars['Int']['input']>;
  acquiredDiplomaBeginYear?: InputMaybe<Scalars['Int']['input']>;
  acquiredDiplomaLevel?: InputMaybe<DiplomaEnum>;
  acquiredDiplomaSchoolName?: InputMaybe<Scalars['String']['input']>;
  acquiredDiplomaTitle?: InputMaybe<Scalars['String']['input']>;
  preparedDiplomaTitle?: InputMaybe<Scalars['String']['input']>;
  headTeacher?: InputMaybe<Scalars['Boolean']['input']>;
  teachedSchoolSubjects?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  accompaniedStudyLevels?: InputMaybe<Array<GradeEnum> | GradeEnum>;
  jobSeekerSupportOrganizationType?: InputMaybe<Scalars['String']['input']>;
  jobSeekerSupportPrograms?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  vocationCoachSignup?: InputMaybe<Scalars['Boolean']['input']>;
  acceptGroupConversations?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type UpdateUserProfileCompletionMutation = {
  __typename?: 'Mutation';
  updateUser?: {
    __typename?: 'AmbivalentUser';
    id: string;
    showWelcomePanel: boolean;
    isModeratable: boolean;
    isAdminRhModeratable: boolean;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    availableGrades: Array<GradeEnum>;
    wantToShare?: boolean | null;
    requestProfileModificationUpdate?: boolean | null;
    headTeacher?: boolean | null;
    teachedSchoolSubjects?: Array<string> | null;
    jobSeekerSupportOrganizationType?: string | null;
    jobSeekerSupportPrograms?: Array<string> | null;
    vocationCoachSignup?: boolean | null;
    isCoach: boolean;
    canAcceptGroupConversation: boolean;
    acceptGroupConversations: boolean;
    stepByStepCompletionStatus: {
      __typename?: 'ContextualCompletion';
      fieldsCompletion: Array<{
        __typename?: 'FieldCompletionStatus';
        name: FieldCompletionName;
        complete: boolean;
        points: number;
        disabled: boolean;
      }>;
    };
    currentUserAuthorizations: {
      __typename?: 'UserAuthorization';
      canRequestModeration: boolean;
      canRequestAdminRhModeration: boolean;
    };
    primarySituation?: { __typename?: 'PrimarySituation'; key: PrimarySituationEnum } | null;
    secondarySituation?: { __typename?: 'SecondarySituation'; key: SecondarySituationEnum; name: string } | null;
    meetingPreferences?: Array<{
      __typename?: 'MeetingPreference';
      key: InteractionGroupKeyEnum;
      name: string;
      description: string;
      value?: boolean | null;
    }> | null;
    presentationTranslations: {
      __typename?: 'PresentationTranslations';
      aspirationsQuestion: string;
      expertisesQuestion: string;
      interestsQuestion: string;
      memberPresentationQuestion: string;
      ambassadorPresentationQuestion: string;
      aspirationsExample: string;
      expertisesExample: string;
      interestsExample: string;
      memberPresentationExample: string;
      ambassadorPresentationExample: string;
    };
    accompaniedStudyLevels?: Array<{ __typename?: 'Grade'; key: GradeKeyEnum; name: string }> | null;
  } | null;
};

export type SignoutMutationVariables = Exact<{ [key: string]: never }>;

export type SignoutMutation = { __typename?: 'Mutation'; signout?: boolean | null };
