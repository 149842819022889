const MARKETPLACE_FRONTEND_URL = window.__RUNTIME_CONFIG__.REACT_APP_APP_FRONTEND_URL || 'http://localhost:4001';
const VOCATION_FRONTEND_URL = window.__RUNTIME_CONFIG__.REACT_APP_SELF_SERVICE_FRONTEND_URL || 'http://localhost:4002';

export function goToMarketplace() {
  if (MARKETPLACE_FRONTEND_URL) {
    localStorage.removeItem('completion_context');
    window.location.href = `${MARKETPLACE_FRONTEND_URL}`;
  }
}

export function getMarketplaceDashboardUrl() {
  return `${MARKETPLACE_FRONTEND_URL}/dashboard`;
}

export function goToMarketplaceDashboard() {
  if (MARKETPLACE_FRONTEND_URL) {
    localStorage.removeItem('completion_context');
    window.location.href = getMarketplaceDashboardUrl();
  }
}

export function getMarketplaceSettingsUrl() {
  return `${MARKETPLACE_FRONTEND_URL}/settings`;
}

export function goToMarketplaceSettings() {
  if (MARKETPLACE_FRONTEND_URL) {
    localStorage.removeItem('completion_context');
    window.location.href = getMarketplaceSettingsUrl();
  }
}

export function goToHRAdmin() {
  if (MARKETPLACE_FRONTEND_URL) {
    localStorage.removeItem('completion_context');
    window.location.href = `${MARKETPLACE_FRONTEND_URL}/company-admin`;
  }
}

export function goToVocation() {
  const vocationUrl = localStorage.getItem('vocation_url');

  if (vocationUrl) {
    localStorage.removeItem('completion_context');
    localStorage.removeItem('vocation_url');
    window.location.href = vocationUrl;
  } else if (VOCATION_FRONTEND_URL) {
    window.location.href = `${VOCATION_FRONTEND_URL}`;
  }
}
