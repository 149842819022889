import { defineMessages } from 'react-intl';

export const PanelErrorTranslations = defineMessages({
  inputEmpty: {
    id: 'PanelErrorTranslations.inputEmpty',
    defaultMessage: 'Ce champ est obligatoire',
  },
  passwordMismatch: {
    id: 'PanelErrorTranslations.passwordMismatch',
    defaultMessage: 'Les mots de passe ne correspondent pas',
  },
  passwordTooWeak: {
    id: 'PanelErrorTranslations.passwordTooWeak',
    defaultMessage: "Le mot de passe n'est pas assez fort",
  },
  inputTooShort: {
    id: 'PanelErrorTranslations.inputTooShort',
    defaultMessage: 'Ce champ est trop court',
  },
  invalidPhoneNumber: {
    id: 'PanelErrorTranslations.invalidPhoneNumber',
    defaultMessage: 'Format de numéro de téléphone invalide',
  },
  startDateInFuture: {
    id: 'PanelErrorTranslations.startDateInFuture',
    defaultMessage: 'La date de début doit être dans le passé',
  },
  endDateInFuture: {
    id: 'PanelErrorTranslations.endDateInFuture',
    defaultMessage: 'La date de fin doit être dans le passé',
  },
  endDateBeforeStartDate: {
    id: 'PanelErrorTranslations.endDateBeforeStartDate',
    defaultMessage: 'La date de fin doit être après la date de début',
  },
  invalidGradeEnum: {
    id: 'PanelErrorTranslations.invalidGradeEnum',
    defaultMessage: "Niveau(x) d'étude(s) invalide(s)",
  },
  invalidJobTitle: {
    id: 'PanelErrorTranslations.invalidJobTitle',
    defaultMessage: 'Métier invalide',
  },
});

export const WelcomePanelTranslations = defineMessages({
  title: {
    id: 'ProfileCompletion.WelcomePanelTranslations.title',
    defaultMessage: 'Bienvenue dans la communauté My Job Glasses !',
  },
  subtitle: {
    id: 'ProfileCompletion.WelcomePanelTranslations.subtitle',
    defaultMessage:
      'Votre compte a bien été activé. Nous allons vous guider afin de compléter rapidement votre profil.',
  },
  titleUpdate: {
    id: 'ProfileCompletion.WelcomePanelTranslations.titleUpdate',
    defaultMessage: 'Mettez à jour votre profil',
  },
  subtitleUpdate: {
    id: 'ProfileCompletion.WelcomePanelTranslations.subtitleUpdate',
    defaultMessage:
      'Pour mieux vous accompagner, My Job Glasses propose de nouvelles fonctionnalités. Nous allons vous demander quelques informations afin de vous en faire bénéficier.',
  },
  submitButton: {
    id: 'ProfileCompletion.WelcomePanelTranslations.submitButton',
    defaultMessage: 'Commencer',
  },
});

export const BirthdatePanelTranslations = defineMessages({
  title: {
    id: 'ProfileCompletion.BirthdatePanelTranslations.title',
    defaultMessage: 'Renseignez votre date de naissance',
  },
  subtitle: {
    id: 'ProfileCompletion.BirthdatePanelTranslations.subtitle',
    defaultMessage: 'Veuillez renseigner votre date de naissance.',
  },
  birthdate: {
    id: 'ProfileCompletion.BirthdatePanelTranslations.birthdate',
    defaultMessage: 'Date de naissance',
  },
});

export const PasswordCreationPanelTranslations = defineMessages({
  title: {
    id: 'ProfileCompletion.PasswordCreationPanelTranslations.title',
    defaultMessage: 'Créez votre mot de passe',
  },
  subtitle: {
    id: 'ProfileCompletion.PasswordCreationPanelTranslations.subtitle',
    defaultMessage: 'Veuillez choisir un mot de passe pour votre compte.',
  },
  password: {
    id: 'ProfileCompletion.PasswordCreationPanelTranslations.password',
    defaultMessage: 'Mot de passe',
  },
  passwordConfirmation: {
    id: 'ProfileCompletion.PasswordCreationPanelTranslations.passwordConfirmation',
    defaultMessage: 'Confirmez votre mot de passe',
  },
});

export const IdentityPanelTranslations = defineMessages({
  title: {
    id: 'ProfileCompletion.IdentityPanelTranslations.title',
    defaultMessage: 'Votre identité',
  },
  subtitle: {
    id: 'ProfileCompletion.IdentityPanelTranslations.subtitle',
    defaultMessage: 'Entrez votre nom, votre prénom et votre genre.',
  },
  gender: {
    id: 'ProfileCompletion.IdentityPanelTranslations.gender',
    defaultMessage: 'Genre',
  },
  genderPlaceholder: {
    id: 'ProfileCompletion.IdentityPanelTranslations.genderPlaceholder',
    defaultMessage: 'Sélectionnez votre genre',
  },
  firstName: {
    id: 'ProfileCompletion.IdentityPanelTranslations.firstName',
    defaultMessage: 'Prénom',
  },
  firstNamePlaceholder: {
    id: 'ProfileCompletion.IdentityPanelTranslations.firstNamePlaceholder',
    defaultMessage: 'Gabriel',
  },
  lastName: {
    id: 'ProfileCompletion.IdentityPanelTranslations.lastName',
    defaultMessage: 'Nom',
  },
  lastNamePlaceholder: {
    id: 'ProfileCompletion.IdentityPanelTranslations.lastNamePlaceholder',
    defaultMessage: 'Martin',
  },
});

export const PrimarySituationPanelTranslations = defineMessages({
  title: {
    id: 'ProfileCompletion.PrimarySituationPanelTranslations.title',
    defaultMessage: 'Votre situation',
  },
  subtitle: {
    id: 'ProfileCompletion.PrimarySituationPanelTranslations.subtitle',
    defaultMessage: 'Veuillez sélectionner votre situation.',
  },
});

export const ProfessorSituationTranslations = defineMessages({
  title: {
    id: 'ProfileCompletion.ProfessorSituationTranslations.title',
    defaultMessage: 'Votre établissement et votre poste',
  },
  subtitle: {
    id: 'ProfileCompletion.ProfessorSituationTranslations.subtitle',
    defaultMessage:
      'Renseignez l’établissement scolaire dans lequel vous travaillez actuellement ainsi que le rôle que vous occupez.',
  },
  secondarySituation: {
    id: 'ProfileCompletion.ProfessorSituationTranslations.secondarySituation',
    defaultMessage: "Type d'établissement",
  },
  departmentLabel: {
    id: 'ProfileCompletion.ProfessorSituationTranslations.departmentLabel',
    defaultMessage: "Département de l'établissement",
  },
  currentSchoolNameLabel: {
    id: 'ProfileCompletion.ProfessorSituationTranslations.currentSchoolNameLabel',
    defaultMessage: 'Votre établissement',
  },
  currentSchoolNamePlaceholder: {
    id: 'ProfileCompletion.ProfessorSituationTranslations.currentSchoolNamePlaceholder',
    defaultMessage: 'Entrez le nom ou la ville de votre établissement',
  },
  labelTeachedSchoolSubjects: {
    id: 'ProfileCompletion.ProfessorSituationTranslations.labelCourse',
    defaultMessage: 'Matière enseignée',
  },
  labelProfession: {
    id: 'ProfileCompletion.ProfessorSituationTranslations.labelProfession',
    defaultMessage: 'Votre métier',
  },
  accompaniedStudyLevels: {
    id: 'ProfileCompletion.ProfessorSituationTranslations.studyLevelSupported',
    defaultMessage: "Niveaux d'études accompagnés",
  },
  labelPrincipalTeacher: {
    id: 'ProfileCompletion.ProfessorSituationTranslations.labelPrincipalTeacher',
    defaultMessage: 'Etes-vous professeur principal ?',
  },
  labelOther: {
    id: 'ProfileCompletion.ProfessorSituationTranslations.labelOther',
    defaultMessage: 'Précisez',
  },
  emptySchoolNameMessage: {
    id: 'ProfileCompletion.ProfessorSituationTranslations.emptySchoolNameMessage',
    defaultMessage: "Pas de résultat. Cliquez ici si vous n'avez pas trouvé votre établissement dans la liste",
  },
});

export const FranceTravailSupportSituationTranslations = defineMessages({
  title: {
    id: 'ProfileCompletion.FranceTravailSupportSituationTranslations.title',
    defaultMessage: 'La structure dans laquelle vous travaillez',
  },
  subtitle: {
    id: 'ProfileCompletion.FranceTravailSupportSituationTranslations.subtitle',
    defaultMessage:
      'Renseignez le type de structure dans laquelle vous travaillez ainsi que le ou les programme(s) suivi(s) par les demandeurs d’emploi concernés par cette démarche.',
  },
  organizationTypeLabel: {
    id: 'ProfileCompletion.FranceTravailSupportSituationTranslations.organizationTypeLabel',
    defaultMessage: 'Type de structure dans laquelle vous travaillez',
  },
  labelOther: {
    id: 'ProfileCompletion.FranceTravailSupportSituationTranslations.labelOther',
    defaultMessage: 'Si autre, précisez',
  },
  labelJobTitle: {
    id: 'ProfileCompletion.FranceTravailSupportSituationTranslations.labelJobTitle',
    defaultMessage: 'Poste occupé',
  },
  labelCompanyName: {
    id: 'ProfileCompletion.FranceTravailSupportSituationTranslations.labelCompanyName',
    defaultMessage: 'Nom de l’agence ou de la structure dans laquelle vous travaillez',
  },
  placeholderCompanyName: {
    id: 'ProfileCompletion.FranceTravailSupportSituationTranslations.placeholderCompanyName',
    defaultMessage: 'Exemple : Agence France Travail Paris 12ème Diderot',
  },
  labelPrograms: {
    id: 'ProfileCompletion.FranceTravailSupportSituationTranslations.labelPrograms',
    defaultMessage: "Type d'accompagnement",
  },
});

export const SearchingForSituationPanelTranslations = defineMessages({
  title: {
    id: 'ProfileCompletion.SearchingForSituationPanelTranslations.title',
    defaultMessage: "Êtes-vous dans l'un des cas suivants ?",
  },

  jobSeekingLabel: {
    id: 'ProfileCompletion.SearchingForSituationPanelTranslations.jobSeekingLabel',
    defaultMessage: 'Je recherche un stage, une alternance ou un emploi',
  },
  returningToStudy: {
    id: 'ProfileCompletion.SearchingForSituationPanelTranslations.returningToStudy',
    defaultMessage: "Je suis en reprise d'études",
  },
});

export const SecondarySituationPanelTranslations = defineMessages({
  title: {
    id: 'ProfileCompletion.SecondarySituationPanelTranslations.title',
    defaultMessage: "Votre niveau d'études",
  },
  subtitle: {
    id: 'ProfileCompletion.SecondarySituationPanelTranslations.subtitle',
    defaultMessage: "Quel est votre niveau d'études actuel ?",
  },
});

export const ProfessionalSituationPanelTranslations = defineMessages({
  title: {
    id: 'ProfileCompletion.ProfessionalSituationPanelTranslations.title',
    defaultMessage: 'Votre situation professionnelle',
  },
  subtitle: {
    id: 'ProfileCompletion.ProfessionalSituationPanelTranslations.subtitle',
    defaultMessage: 'Veuillez compléter les informations relatives à votre poste actuel.',
  },

  job: {
    id: 'ProfileCompletion.ProfessionalSituationPanelTranslations.job',
    defaultMessage: 'Poste actuel',
  },
  jobPlaceholder: {
    id: 'ProfileCompletion.ProfessionalSituationPanelTranslations.jobPlaceholder',
    defaultMessage: 'Ingénieur',
  },
  companyName: {
    id: 'ProfileCompletion.ProfessionalSituationPanelTranslations.companyName',
    defaultMessage: "Nom de l'entreprise",
  },
  companyNamePlaceholder: {
    id: 'ProfileCompletion.ProfessionalSituationPanelTranslations.companyNamePlaceholder',
    defaultMessage: 'My Job Glasses',
  },
  careerStartedAt: {
    id: 'ProfileCompletion.ProfessionalSituationPanelTranslations.careerStartedAt',
    defaultMessage: "Date de début d'activité professionnelle",
  },
  careerStartedAtPlaceholder: {
    id: 'ProfileCompletion.ProfessionalSituationPanelTranslations.careerStartedAtPlaceholder',
    defaultMessage: 'Avril 1989',
  },
  previousYearsOfExperience: {
    id: 'ProfileCompletion.OtherSituationPanelTranslations.previousYearsOfExperience',
    defaultMessage: "Années d'expérience",
  },
  previousYearsOfExperiencePlaceholder: {
    id: 'ProfileCompletion.OtherSituationPanelTranslations.previousYearsOfExperiencePlaceholder',
    defaultMessage: '7',
  },
});

export const OtherSituationPanelTranslations = defineMessages({
  title: {
    id: 'ProfileCompletion.OtherSituationPanelTranslations.title',
    defaultMessage: 'Votre dernier emploi',
  },
  subtitle: {
    id: 'ProfileCompletion.OtherSituationPanelTranslations.subtitle',
    defaultMessage: 'Renseignez les informations suivantes sur le dernier poste que vous avez occupé.',
  },

  previousJobTitle: {
    id: 'ProfileCompletion.OtherSituationPanelTranslations.previousJobTitle',
    defaultMessage: 'Dernier poste occupé',
  },
  previousJobTitlePlaceholder: {
    id: 'ProfileCompletion.OtherSituationPanelTranslations.previousJobTitlePlaceholder',
    defaultMessage: 'Ingénieur',
  },
  previousCompanyName: {
    id: 'ProfileCompletion.OtherSituationPanelTranslations.previousCompanyName',
    defaultMessage: "Nom de l'entreprise",
  },
  previousCompanyNamePlaceholder: {
    id: 'ProfileCompletion.OtherSituationPanelTranslations.previousCompanyNamePlaceholder',
    defaultMessage: 'My job glasses',
  },
  previousYearsOfExperience: {
    id: 'ProfileCompletion.OtherSituationPanelTranslations.previousYearsOfExperience',
    defaultMessage: "Années d'expérience",
  },
  previousYearsOfExperiencePlaceholder: {
    id: 'ProfileCompletion.OtherSituationPanelTranslations.previousYearsOfExperiencePlaceholder',
    defaultMessage: '7',
  },
  neverWorked: {
    id: 'ProfileCompletion.OtherSituationPanelTranslations.neverWorked',
    defaultMessage: "Je n'ai jamais travaillé",
  },
});

export const CurrentEducationPanelTranslations = defineMessages({
  title: {
    id: 'ProfileCompletion.CurrentEducationPanelTranslations.title',
    defaultMessage: 'Votre formation',
  },
  subtitle: {
    id: 'ProfileCompletion.CurrentEducationPanelTranslations.subtitle',
    defaultMessage: 'Renseignez les informations suivantes sur la formation que vous suivez actuellement',
  },

  currentGradeLabel: {
    id: 'ProfileCompletion.CurrentEducationPanelTranslations.currentGradeLabel',
    defaultMessage: "Niveau d'étude",
  },
  currentGradePlaceholder: {
    id: 'ProfileCompletion.CurrentEducationPanelTranslations.currentGradePlaceholder',
    defaultMessage: '1ère année',
  },
  currentSchoolNameLabel: {
    id: 'ProfileCompletion.CurrentEducationPanelTranslations.currentSchoolNameLabel',
    defaultMessage: 'Votre établissement',
  },
  currentSchoolNamePlaceholder: {
    id: 'ProfileCompletion.CurrentEducationPanelTranslations.currentSchoolNamePlaceholder',
    defaultMessage: 'Entrez le nom ou la ville de votre établissement',
  },
  currentSchoolCityLabel: {
    id: 'ProfileCompletion.CurrentEducationPanelTranslations.currentSchoolCityLabel',
    defaultMessage: 'Ville',
  },
  currentSchoolCityPlaceholder: {
    id: 'ProfileCompletion.CurrentEducationPanelTranslations.currentSchoolCityPlaceholder',
    defaultMessage: 'Paris',
  },
  secondarySituation: {
    id: 'ProfileCompletion.CurrentEducationPanelTranslations.secondarySituation',
    defaultMessage: "Type d'établissement",
  },
  labelInputApprentice: {
    id: 'ProfileCompletion.CurrentEducationPanelTranslations.labelInputApprentice',
    defaultMessage: 'Suivez-vous cette formation en apprentissage/alternance ?',
  },
  departmentLabel: {
    id: 'ProfileCompletion.CurrentEducationPanelTranslations.departmentLabel',
    defaultMessage: "Département de l'établissement",
  },
  diplomaLabel: {
    id: 'ProfileCompletion.CurrentEducationPanelTranslations.diplomaLabel',
    defaultMessage: 'Titre du diplôme préparé',
  },
  emptySchoolNameMessage: {
    id: 'Profile.CurrentEducationPanelTranslations.emptySchoolNameMessage',
    defaultMessage: "Pas de résultat. Cliquez ici si vous n'avez pas trouvé votre établissement dans la liste",
  },
});

export const PastEducationPanelTranslations = defineMessages({
  title: {
    id: 'ProfileCompletion.PastEducationPanelTranslations.title',
    defaultMessage: "Votre niveau d'études",
  },
  subtitle: {
    id: 'ProfileCompletion.PastEducationPanelTranslations.subtitle',
    defaultMessage: 'Renseignez les informations suivantes concernant votre plus haut niveau de diplôme',
  },

  pastEducationLevel: {
    id: 'ProfileCompletion.PastEducationPanelTranslations.pastEducationLevel',
    defaultMessage: "Niveau d'études",
  },
  pastDiplomaLabel: {
    id: 'ProfileCompletion.PastEducationPanelTranslations.pastDiplomaLabel',
    defaultMessage: 'Intitulé du diplôme obtenu',
  },
  pastDiplomaPlaceholder: {
    id: 'ProfileCompletion.PastEducationPanelTranslations.pastDiplomaPlaceholder',
    defaultMessage: 'Master on Computer Science',
  },
  pastSchoolNameLabel: {
    id: 'ProfileCompletion.PastEducationPanelTranslations.pastSchoolNameLabel',
    defaultMessage: 'Nom de établissement',
  },
  periodStart: {
    id: 'ProfileCompletion.PastEducationPanelTranslations.periodStart',
    defaultMessage: 'Date de début',
  },
  periodEnd: {
    id: 'ProfileCompletion.PastEducationPanelTranslations.periodEnd',
    defaultMessage: 'Date de fin',
  },
});

export const ProgramAffiliationPanelTranslations = defineMessages({
  title: {
    id: 'ProfileCompletion.ProgramAffiliationPanelTranslations.title',
    defaultMessage: 'Rejoindre un programme',
  },
  subtitle: {
    id: 'ProfileCompletion.ProgramAffiliationPanelTranslations.subtitle',
    defaultMessage:
      'Avez-vous un code programme (fourni par votre établissement) ? Vous pourrez renseigner cette information ultérieurement.',
  },
  codeLabel: {
    id: 'ProfileCompletion.ProgramAffiliationPanelTranslations.codeLabel',
    defaultMessage: 'Code programme',
  },
  codePlaceholder: {
    id: 'ProfileCompletion.ProgramAffiliationPanelTranslations.codePlaceholder',
    defaultMessage: 'Renseignez le code du programme',
  },
});

export const WantToSharePanelTranslations = defineMessages({
  title: {
    id: 'ProfileCompletion.WantToSharePanelTranslations.title',
    defaultMessage: 'Partager mon expérience',
  },
  subtitleStudent: {
    id: 'ProfileCompletion.WantToSharePanelTranslations.subtitleStudent',
    defaultMessage:
      "Vous ne vous en rendez peut être pas compte, mais vous avez déjà beaucoup à apporter à des plus jeunes que vous (stages, choix d'orientation, options...). Et si vous aussi vous vous engagiez en leur partageant votre expérience ?",
  },
  subtitlePro: {
    id: 'ProfileCompletion.WantToSharePanelTranslations.subtitlePro',
    defaultMessage:
      "Votre expérience peut permettre à de nombreuses personnes (jeunes, demandeurs d'emploi, adultes en reconversion, seniors..) de trouver leur voie ou d'écrire la suite de leur carrière. Parce qu'une heure de votre temps peut faire la différence, rejoignez le mouvement en devenant Ambassadeur sur My Job Glasses !",
  },

  wantToPassTitle: {
    id: 'ProfileCompletion.WantToSharePanelTranslations.wantToPassTitle',
    defaultMessage: 'Je passe mon tour',
  },
  wantToShareTitle: {
    id: 'ProfileCompletion.WantToSharePanelTranslations.wantToShareTitle',
    defaultMessage: 'Je crée mon compte Ambassadeur',
  },
});

export const MaxMeetingsPanelTranslations = defineMessages({
  title: {
    id: 'ProfileCompletion.MaxMeetingsPanelTranslations.title',
    defaultMessage: 'Votre limite de rendez-vous',
  },
  subtitle: {
    id: 'ProfileCompletion.MaxMeetingsPanelTranslations.subtitle',
    defaultMessage:
      'Définissez le nombre de rendez-vous que vous acceptez d’effectuer par mois avec des Membres sur My Job Glasses. Vous pourrez à tout moment modifier cette limite depuis votre profil.',
  },

  quota: {
    id: 'ProfileCompletion.MaxMeetingsPanelTranslations.quota',
    defaultMessage: 'Mon nombre de rendez-vous par mois',
  },
  quotaOption: {
    id: 'ProfileCompletion.MaxMeetingsPanelTranslations.quotaOption',
    defaultMessage: 'rendez-vous par mois',
  },
});

export const MeetingPreferencesPanelTranslations = defineMessages({
  title: {
    id: 'ProfileCompletion.MeetingPreferencesPanelTranslations.title',
    defaultMessage: 'Paramètres de rencontres',
  },
  subtitle: {
    id: 'ProfileCompletion.MeetingPreferencesPanelTranslations.subtitle',
    defaultMessage: "Indiquez les types d'utilisateurs avec lesquels vous souhaitez échanger.",
  },
  acceptGroupConversation: {
    id: 'ProfileCompletion.MeetingPreferencesPanelTranslations.canAcceptGroupConversation',
    defaultMessage:
      "Professeurs ou conseillers à l'emploi, pour préparer mon intervention auprès de groupes de collégiens, de lycéens ou de demandeurs d'emploi (en présentiel ou en visioconférence)",
  },
});

export const PhonePanelTranslations = defineMessages({
  title: {
    id: 'ProfileCompletion.PhonePanelTranslations.title',
    defaultMessage: 'Vos coordonnées',
  },
  subtitle: {
    id: 'ProfileCompletion.PhonePanelTranslations.subtitle',
    defaultMessage:
      'Entrez votre numéro de téléphone. Cette information sera uniquement visible par les personnes avec qui un rendez-vous est prévu.',
  },

  phone: {
    id: 'ProfileCompletion.PhonePanelTranslations.phone',
    defaultMessage: 'Numéro de téléphone',
  },
});

export const MeetingPlacePanelTranslations = defineMessages({
  title: {
    id: 'ProfileCompletion.MeetingPlacePanelTranslations.title',
    defaultMessage: 'Votre lieu de rencontres',
  },
  subtitle: {
    id: 'ProfileCompletion.MeetingPlacePanelTranslations.subtitle',
    defaultMessage: 'Veuillez indiquer votre lieu de rencontres',
  },

  address: {
    id: 'ProfileCompletion.MeetingPlacePanelTranslations.address',
    defaultMessage: 'Adresse',
  },
});

export const LanguagesPanelTranslations = defineMessages({
  title: {
    id: 'ProfileCompletion.LanguagesPanelTranslations.title',
    defaultMessage: 'Quelle(s) langue(s) parlez-vous ?',
  },
  subtitle: {
    id: 'ProfileCompletion.LanguagesPanelTranslations.subtitle',
    defaultMessage: 'Indiquez les langues dans lesquelles vous pouvez réaliser les rencontres.',
  },
  languages: {
    id: 'ProfileCompletion.LanguagesPanelTranslations.languages',
    defaultMessage: 'Langues parlées',
  },
});

export const AvatarPanelTranslations = defineMessages({
  title: {
    id: 'ProfileCompletion.AvatarPanelTranslations.title',
    defaultMessage: 'Choisissez votre photo de profil',
  },
  subtitle: {
    id: 'ProfileCompletion.AvatarPanelTranslations.subtitle',
    defaultMessage:
      'Choisissez une photo de bonne qualité. Une photo est essentielle pour rendre votre profil plus attrayant et encourager vos interlocuteurs à vous répondre.',
  },
});

export const MemberPresentationPanelTranslations = defineMessages({
  title: {
    id: 'ProfileCompletion.MemberPresentationPanelTranslations.titleStudent',
    defaultMessage: 'Votre profil Membre',
  },
  subtitle: {
    id: 'ProfileCompletion.MemberPresentationPanelTranslations.subtitleStudent',
    defaultMessage:
      'Renseignez ici votre présentation. Celle-ci ne sera visible que par les personnes que vous décidez de contacter.',
  },
  titleSupport: {
    id: 'ProfileCompletion.MemberPresentationPanelTranslations.titleSupport',
    defaultMessage: 'Votre profil et votre démarche en quelques lignes',
  },
  subtitleSupport: {
    id: 'ProfileCompletion.MemberPresentationPanelTranslations.subtitleSupport',
    defaultMessage:
      'Renseignez ici votre présentation. Cela permettra aux ambassadeurs que vous contactez de savoir qui vous êtes et de mieux comprendre votre démarche.',
  },
});

export const AmbassadorPresentationPanelTranslations = defineMessages({
  title: {
    id: 'ProfileCompletion.AmbassadorPresentationPanelTranslations.title',
    defaultMessage: 'Votre Présentation (1/4)',
  },
  subtitle: {
    id: 'ProfileCompletion.AmbassadorPresentationPanelTranslations.subtitle',
    defaultMessage:
      'Complétez cette série de questions afin de construire votre profil ambassadeur et permettre aux membres d’en savoir plus sur vous.',
  },
});

export const ExpertisePresentationPanelTranslations = defineMessages({
  title: {
    id: 'ProfileCompletion.ExpertisePresentationPanelTranslations.title',
    defaultMessage: 'Votre Présentation (4/4)',
  },
  subtitle: {
    id: 'ProfileCompletion.ExpertisePresentationPanelTranslations.subtitle',
    defaultMessage: 'Complétez cette série de questions afin de construire votre profil ambassadeur.',
  },
});

export const InterestPresentationPanelTranslations = defineMessages({
  title: {
    id: 'ProfileCompletion.InterestPresentationPanelTranslations.title',
    defaultMessage: 'Votre Présentation (2/4)',
  },
  subtitle: {
    id: 'ProfileCompletion.InterestPresentationPanelTranslations.subtitle',
    defaultMessage: 'Complétez cette série de questions afin de construire votre profil ambassadeur. ',
  },
});

export const AspirationPresentationPanelTranslations = defineMessages({
  title: {
    id: 'ProfileCompletion.AspirationPresentationPanelTranslations.title',
    defaultMessage: 'Votre Présentation (3/4)',
  },
  subtitle: {
    id: 'ProfileCompletion.AspirationPresentationPanelTranslations.subtitle',
    defaultMessage: 'Complétez cette série de questions afin de construire votre profil ambassadeur.',
  },
});

export const ThankYouPanelTranslations = defineMessages({
  title: {
    id: 'ProfileCompletion.ThankYouPanelTranslations.title',
    defaultMessage: 'Bravo !',
  },
  subtitleMember: {
    id: 'ProfileCompletion.ThankYouPanelTranslations.subtitleMember',
    // eslint-disable-next-line no-irregular-whitespace
    defaultMessage: `Votre profil est complet, maintenant à vous de jouer&nbsp;!
    Vous pouvez dès à présent rechercher la première personne que vous souhaitez rencontrer.`,
  },
  subtitleAmbassador: {
    id: 'ProfileCompletion.ThankYouPanelTranslations.subtitleAmbassador',
    defaultMessage: `Votre profil est maintenant en modération. Vous recevrez un email dès qu’il sera publié.
    En attendant de recevoir votre premier message, vous pouvez recherchez des Ambassadeurs avec lesquels échanger.`,
  },
  goToMarketplaceButton: {
    id: 'ProfileCompletion.ThankYouPanelTranslations.goToMarketplaceButton',
    defaultMessage: 'Je me lance',
  },
});

export const ThankYouPanelUpdateTranslations = defineMessages({
  title: {
    id: 'ProfileCompletion.ThankYouPanelUpdateTranslations.title',
    defaultMessage: 'Bravo !',
  },
  subtitle: {
    id: 'ProfileCompletion.ThankYouPanelUpdateTranslations.subtitle',
    defaultMessage: `Votre profil est complet, vous pouvez à présent continuer à utiliser My Job Glasses. Belles rencontres !`,
  },
  goToMarketplaceButton: {
    id: 'ProfileCompletion.ThankYouPanelUpdateTranslations.goToMarketplaceButton',
    defaultMessage: 'Je me lance',
  },
});
