import TrashIcon from 'assets/component-icons/TrashIcon';
import SchoolNameAutocompleteSelectInput from 'components/autocomplete-addable-select-input/contextuals/SchoolNameAutocompleteSelectInput';
import MonthPicker from 'components/month-picker/MonthPicker';
import SelectInput from 'components/select-input/SelectInput';
import TextInput from 'components/text-input/TextInput';
import moment from 'moment';
import { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
  CreateEducationMutation,
  CreateEducationMutationVariables,
  DiplomaEnum,
  DiplomaKeyEnum,
  ProfileEducation,
  RemoveEducationMutation,
  RemoveEducationMutationVariables,
  UpdateEducationMutation,
  UpdateEducationMutationVariables,
  UserPartsFragment,
} from '../../../../@types/graphql';

import { useMutation } from '@apollo/client';
import XIcon from 'assets/component-icons/XIcon';
import ButtonsGroup from 'components/buttons-group/ButtonsGroup';
import formikError from 'components/formik-helper/formik.helper';
import useModal from 'components/modal/Modal.hook';
import PrimaryButton from 'components/primary-button/PrimaryButton';
import SecondaryButton from 'components/secondary-button/SecondaryButton';
import { SettingsContext } from 'contexts/settings/SettingsContext';
import { useFormik } from 'formik';
import { CREATE_EDUCATION_MUTATION, REMOVE_EDUCATION_MUTATION, UPDATE_EDUCATION_MUTATION } from 'gqls/Educations.gql';
import useFormikNetworkState from 'hooks/use-formik-network-state';
import { CommonTranslations } from 'i18n/common.translations';
import { PastEducationsPanelTranslations } from 'scenes/profile/panels/panels.translations';
import { educationModalValidationSchema } from './education-modal.validation';

import './EducationModal.scss';

type FormikValues = {
  diplomaType: DiplomaEnum;
  diplomaTitle: string;
  schoolName: string;
  startedAt?: Date;
  endedAt?: Date;
};

type EducationModalProps = {
  userId: string;
  education?: UserPartsFragment['educations'][0];
  onEducationCreation?: (education: ProfileEducation) => void;
  onEducationUpdate?: (education: ProfileEducation) => void;
  onEducationDeletion?: (educationId: string) => void;
};

export default function EducationModal({
  userId,
  onEducationCreation,
  onEducationUpdate,
  onEducationDeletion,
  education,
}: EducationModalProps) {
  const intl = useIntl();

  const [, closeModal] = useModal();

  const isEdition = useMemo(() => !!education, [education]);

  const networkState = useFormikNetworkState();
  const settingsContext = useContext(SettingsContext);

  const [createEducation] = useMutation<CreateEducationMutation, CreateEducationMutationVariables>(
    CREATE_EDUCATION_MUTATION,
  );
  const [updateEducation] = useMutation<UpdateEducationMutation, UpdateEducationMutationVariables>(
    UPDATE_EDUCATION_MUTATION,
  );
  const [removeEducation] = useMutation<RemoveEducationMutation, RemoveEducationMutationVariables>(
    REMOVE_EDUCATION_MUTATION,
  );

  const formik = useFormik<FormikValues>({
    initialValues: {
      diplomaType: education?.diplomaType || DiplomaEnum.None,
      diplomaTitle: education?.diplomaTitle || '',
      schoolName: education?.schoolName || '',
      startedAt: education?.startedAt && new Date(education?.startedAt),
      endedAt: education?.endedAt && new Date(education?.endedAt),
    },
    validationSchema: educationModalValidationSchema(intl),
    validateOnMount: true,
    onSubmit: async (values) => {
      const { startedAt, endedAt, ...rest } = values;
      networkState.loading();

      if (isEdition) {
        const response = await updateEducation({
          variables: {
            ...rest,
            startedAt: startedAt?.toISOString(),
            endedAt: endedAt?.toISOString(),
            educationId: education?.id || '',
            userId,
          },
        });
        response.errors ? networkState.error() : networkState.succeeded();
        if (response.data) onEducationUpdate?.(response.data.updateEducation as ProfileEducation);
      } else {
        const response = await createEducation({
          variables: {
            ...rest,
            startedAt: startedAt?.toISOString(),
            endedAt: endedAt?.toISOString(),
            userId,
          },
        });
        response.errors ? networkState.error() : networkState.succeeded();
        if (response.data) onEducationCreation?.(response.data.createEducation as ProfileEducation);
      }
    },
  });

  async function onRemoveEducation() {
    if (!education) return;

    await removeEducation({ variables: { userId, educationId: education.id } });
    onEducationDeletion?.(education.id);
  }

  const diplomaValues = useMemo(() => {
    return (settingsContext?.settings?.diplomas || [])
      .filter((diploma) => diploma.key !== DiplomaKeyEnum.None)
      .map(({ key, name }) => ({
        value: key,
        translation: name,
      }));
  }, [settingsContext?.settings?.diplomas]);

  return (
    <form
      className="education-modal"
      onSubmit={formik.handleSubmit}
    >
      <SelectInput
        name="diplomaType"
        label={intl.formatMessage(PastEducationsPanelTranslations.addModalDiplomaType)}
        initialValue={{
          value: formik.values.diplomaType as unknown as DiplomaKeyEnum,
          translation:
            diplomaValues.find(({ value }) => value === (formik.values.diplomaType as unknown as DiplomaKeyEnum))
              ?.translation || '',
        }}
        values={diplomaValues.map(({ value, translation }) => ({
          value,
          translation,
        }))}
        onChange={(diplomaType) => formik.setFieldValue('diplomaType', diplomaType)}
      />

      <TextInput
        label={intl.formatMessage(PastEducationsPanelTranslations.addModalDiplomaTitle)}
        name="diplomaTitle"
        value={formik.values.diplomaTitle}
        onChange={formik.handleChange}
        error={formikError(formik.touched, formik.errors, 'diplomaTitle')}
      />

      <SchoolNameAutocompleteSelectInput
        label={intl.formatMessage(PastEducationsPanelTranslations.addModalSchoolName)}
        name="schoolName"
        value={formik.values.schoolName}
        onChange={(schoolName) => formik.setFieldValue('schoolName', schoolName)}
        error={formikError(formik.touched, formik.errors, 'schoolName')}
      />

      <div className="education-modal__period">
        <MonthPicker
          label={intl.formatMessage(PastEducationsPanelTranslations.addModalPeriodStart)}
          popupPosition="top"
          initMonth={formik.initialValues.startedAt && moment(formik.initialValues.startedAt).month()}
          initYear={formik.initialValues.startedAt && moment(formik.initialValues.startedAt).year()}
          onMonthSelection={(startedAt) => formik.setFieldValue('startedAt', startedAt)}
          error={formikError(formik.touched, formik.errors, 'startedAt')}
        />

        <MonthPicker
          label={intl.formatMessage(PastEducationsPanelTranslations.addModalPeriodEnd)}
          popupPosition="top"
          initMonth={formik.initialValues.endedAt && moment(formik.initialValues.endedAt).month()}
          initYear={formik.initialValues.endedAt && moment(formik.initialValues.endedAt).year()}
          onMonthSelection={(endedAt) => formik.setFieldValue('endedAt', endedAt)}
          error={formikError(formik.touched, formik.errors, 'endedAt')}
        />
      </div>

      <ButtonsGroup className="education-modal__footer">
        {isEdition && (
          <SecondaryButton
            leftIcon={TrashIcon}
            label={intl.formatMessage(CommonTranslations.delete)}
            onClick={onRemoveEducation}
            iconButtonWithText
            danger
          />
        )}

        <SecondaryButton
          leftIcon={XIcon}
          label={intl.formatMessage(CommonTranslations.cancel)}
          onClick={closeModal}
        />

        <PrimaryButton
          icon={networkState.iconBasedOnNetworkState}
          leftIcon
          state={networkState.state}
          label={intl.formatMessage(isEdition ? CommonTranslations.save : CommonTranslations.confirm)}
          submit
        />
      </ButtonsGroup>
    </form>
  );
}
