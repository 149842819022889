import { useFormik } from 'formik';
import ArrowRightIcon from 'assets/component-icons/ArrowRightIcon';
import BriefcaseIcon from 'assets/component-icons/BriefcaseIcon';
import BuildingIcon from 'assets/component-icons/BuildingIcon';
import ClockRewindIcon from 'assets/component-icons/ClockRewindIcon';
import TextInput from '../../../components/text-input/TextInput';
import PrimaryButton from '../../../components/primary-button/PrimaryButton';
import useProfileCompletionUserUpdate from './ProfileCompletionUserUpdate.hook';
import { useIntl } from 'react-intl';
import { ProfessionalSituationPanelTranslations } from './panels.translations';
import { professionalSituationPanelValidationSchema } from './panels.validation';
import { CommonTranslations } from 'i18n/common.translations';
import formikError from 'components/formik-helper/formik.helper';
import useFormikNetworkState from 'hooks/use-formik-network-state';

type FormikValues = {
  position: string;
  companyName: string;
  yearsOfExperience: number;
};

export default function ProfessionalSituationPanel() {
  const intl = useIntl();

  const updateUser = useProfileCompletionUserUpdate();
  const networkState = useFormikNetworkState(ArrowRightIcon);

  const formik = useFormik<FormikValues>({
    initialValues: {
      position: '',
      companyName: '',
      yearsOfExperience: 0,
    },
    validationSchema: professionalSituationPanelValidationSchema(intl),
    validateOnMount: true,
    onSubmit: async (values) => {
      networkState.loading();
      await updateUser(values);
      networkState.succeeded();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextInput
        dataCy="position"
        name="position"
        label={intl.formatMessage(ProfessionalSituationPanelTranslations.job)}
        placeholder={intl.formatMessage(ProfessionalSituationPanelTranslations.jobPlaceholder)}
        icon={BriefcaseIcon}
        value={formik.values.position}
        onChange={formik.handleChange}
        error={formikError(formik.touched, formik.errors, 'position')}
      />

      <TextInput
        dataCy="company-name"
        name="companyName"
        label={intl.formatMessage(ProfessionalSituationPanelTranslations.companyName)}
        placeholder={intl.formatMessage(ProfessionalSituationPanelTranslations.companyNamePlaceholder)}
        icon={BuildingIcon}
        value={formik.values.companyName}
        onChange={formik.handleChange}
        error={formikError(formik.touched, formik.errors, 'companyName')}
      />

      <TextInput
        dataCy="years-of-experience"
        name="yearsOfExperience"
        label={intl.formatMessage(ProfessionalSituationPanelTranslations.previousYearsOfExperience)}
        placeholder={intl.formatMessage(ProfessionalSituationPanelTranslations.previousYearsOfExperiencePlaceholder)}
        icon={ClockRewindIcon}
        value={formik.values.yearsOfExperience}
        type="number"
        onChange={formik.handleChange}
        error={formikError(formik.touched, formik.errors, 'yearsOfExperience')}
      />

      <PrimaryButton
        label={intl.formatMessage(CommonTranslations.continue)}
        icon={networkState.iconBasedOnNetworkState}
        submit
        disabled={!formik.dirty}
      />
    </form>
  );
}
